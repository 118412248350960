import { db } from '../config/firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import ErrorHandler from '../utils/ErrorHandler';

class RoomSettingsService {
  constructor() {
    this.collection = 'roomSettings';
  }

  /**
   * Get room settings for a specific room
   * @param {string} roomId - The ID of the room
   * @returns {Promise<Object>} Room settings object
   */
  async getRoomSettings(roomId) {
    try {
      const docRef = doc(db, this.collection, roomId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data();
      }

      // Return default settings if none exist
      return this.getDefaultSettings();
    } catch (error) {
      throw ErrorHandler.handleError(error, 'Failed to get room settings');
    }
  }

  /**
   * Update room settings
   * @param {string} roomId - The ID of the room
   * @param {Object} settings - The settings to update
   * @returns {Promise<void>}
   */
  async updateRoomSettings(roomId, settings) {
    try {
      const docRef = doc(db, this.collection, roomId);
      await updateDoc(docRef, settings);
    } catch (error) {
      throw ErrorHandler.handleError(error, 'Failed to update room settings');
    }
  }

  /**
   * Initialize room settings for a new room
   * @param {string} roomId - The ID of the room
   * @returns {Promise<void>}
   */
  async initializeRoomSettings(roomId) {
    try {
      const docRef = doc(db, this.collection, roomId);
      const defaultSettings = this.getDefaultSettings();
      await setDoc(docRef, defaultSettings);
    } catch (error) {
      throw ErrorHandler.handleError(error, 'Failed to initialize room settings');
    }
  }

  /**
   * Get default room settings
   * @returns {Object} Default settings object
   */
  getDefaultSettings() {
    return {
      tv: {
        enabled: true,
        volume: 50,
        streamingEnabled: true,
        lastUpdated: new Date().toISOString(),
      },
      googleAssistant: {
        enabled: true,
        volume: 50,
        wakeWord: 'Hey Google',
        lastUpdated: new Date().toISOString(),
      },
      whiteNoise: {
        enabled: false,
        volume: 30,
        soundType: 'rain',
        lastUpdated: new Date().toISOString(),
      },
      lastUpdated: new Date().toISOString(),
    };
  }

  /**
   * Update specific amenity setting
   * @param {string} roomId - The ID of the room
   * @param {string} amenity - The amenity to update (tv, googleAssistant, whiteNoise)
   * @param {Object} settings - The settings to update
   * @returns {Promise<void>}
   */
  async updateAmenitySetting(roomId, amenity, settings) {
    try {
      const docRef = doc(db, this.collection, roomId);
      const updateData = {
        [amenity]: {
          ...settings,
          lastUpdated: new Date().toISOString(),
        },
        lastUpdated: new Date().toISOString(),
      };
      await updateDoc(docRef, updateData);
    } catch (error) {
      throw ErrorHandler.handleError(error, `Failed to update ${amenity} settings`);
    }
  }
}

export default new RoomSettingsService();
