import { useState, useEffect, useContext, createContext } from 'react';
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  OAuthProvider,
  signInWithPopup,
  linkWithPopup,
  updateProfile,
  signInWithCredential,
  linkWithCredential,
} from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc, serverTimestamp, increment } from 'firebase/firestore';
import serviceLocator from '../services/serviceLocator';

// Create context for authentication
const AuthContext = createContext();

// Track login attempts for rate limiting
const loginAttempts = {};
const MAX_LOGIN_ATTEMPTS = 5;
const LOCKOUT_DURATION = 15 * 60 * 1000; // 15 minutes in milliseconds

// Provider component that wraps your app and makes auth object available
export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

// Hook that enables components to access the auth object
export const useAuth = () => {
  // Try to get auth from context first
  try {
    const authContext = useContext(AuthContext);

    // If context exists and has user property, use it
    if (authContext !== undefined) {
      return authContext;
    }
  } catch (error) {
    console.warn('Error accessing AuthContext:', error);
  }

  // Fallback to service if context is unavailable
  try {
    const authService = serviceLocator.getService('authService');

    // Return a compatible interface
    return {
      user: authService?.currentUser || null,
      isAuthenticated: !!authService?.currentUser,
      login: authService?.login?.bind(authService),
      logout: authService?.logout?.bind(authService),
      register: authService?.register?.bind(authService),
      loading: false,
    };
  } catch (error) {
    console.error('Failed to get authService:', error);
    // Return a default object to prevent destructuring errors
    return { user: null, isAuthenticated: false, loading: false };
  }
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [accountLocked, setAccountLocked] = useState(false);
  const [lockoutTime, setLockoutTime] = useState(0);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(
      auth,
      async user => {
        if (user) {
          setUser(user);
          localStorage.setItem('isAuthenticated', 'true');

          // Fetch user role from Firestore
          try {
            const db = getFirestore();
            const userRef = doc(db, 'users', user.uid);
            const userSnap = await getDoc(userRef);

            if (userSnap.exists()) {
              const userData = userSnap.data();
              setUserRole(userData.role || 'guest');

              // Reset failed login attempts on successful login
              if (userData.email) {
                delete loginAttempts[userData.email];
              }

              // Update login timestamp and reset failedAttempts
              await setDoc(
                userRef,
                {
                  lastLogin: serverTimestamp(),
                  failedAttempts: 0,
                  lastFailedAttempt: null,
                },
                { merge: true }
              );
            } else {
              // Create user document if it doesn't exist
              const newUserData = {
                email: user.email,
                displayName: user.displayName || '',
                photoURL: user.photoURL || '',
                phoneNumber: user.phoneNumber || '',
                role: 'guest',
                createdAt: serverTimestamp(),
                lastLogin: serverTimestamp(),
                failedAttempts: 0,
                authProvider:
                  user.providerData.length > 0 ? user.providerData[0].providerId : 'email',
              };

              await setDoc(userRef, newUserData);
              setUserRole('guest');
            }
          } catch (err) {
            console.error('Error fetching user role:', err);
            setUserRole('guest');
          }
        } else {
          setUser(null);
          setUserRole(null);
          localStorage.setItem('isAuthenticated', 'false');
        }
        setLoading(false);
      },
      error => {
        console.error('Auth state change error:', error);
        setError(error);
        setLoading(false);
      }
    );

    // Cleanup subscription
    return () => unsubscribe();
  }, []);

  // Check if account is locked
  const checkAccountLockout = async email => {
    if (!email) return false;

    // Check in-memory rate limit tracker
    if (loginAttempts[email]) {
      const attempt = loginAttempts[email];
      if (attempt.count >= MAX_LOGIN_ATTEMPTS) {
        const timeSinceLast = Date.now() - attempt.timestamp;
        if (timeSinceLast < LOCKOUT_DURATION) {
          const remainingTime = Math.ceil((LOCKOUT_DURATION - timeSinceLast) / 1000 / 60);
          setLockoutTime(remainingTime);
          setAccountLocked(true);
          return true;
        } else {
          // Reset if lockout time has passed
          loginAttempts[email] = { count: 0, timestamp: Date.now() };
        }
      }
    } else {
      loginAttempts[email] = { count: 0, timestamp: Date.now() };
    }

    // Also check in Firestore for more persistent rate limiting across devices
    try {
      const db = getFirestore();
      const userQuery = await db.collection('users').where('email', '==', email).limit(1).get();

      if (!userQuery.empty) {
        const userData = userQuery.docs[0].data();
        if (userData.failedAttempts >= MAX_LOGIN_ATTEMPTS && userData.lastFailedAttempt) {
          const lastAttemptTime = userData.lastFailedAttempt.toDate();
          const timeSinceLast = Date.now() - lastAttemptTime.getTime();

          if (timeSinceLast < LOCKOUT_DURATION) {
            const remainingTime = Math.ceil((LOCKOUT_DURATION - timeSinceLast) / 1000 / 60);
            setLockoutTime(remainingTime);
            setAccountLocked(true);
            return true;
          }
        }
      }
    } catch (error) {
      console.error('Error checking account lockout:', error);
    }

    setAccountLocked(false);
    return false;
  };

  // Record failed login attempt
  const recordFailedAttempt = async email => {
    if (!email) return;

    // Record in memory
    if (!loginAttempts[email]) {
      loginAttempts[email] = { count: 0, timestamp: Date.now() };
    }

    loginAttempts[email].count++;
    loginAttempts[email].timestamp = Date.now();

    // Record in Firestore if possible
    try {
      const db = getFirestore();
      const userQuery = await db.collection('users').where('email', '==', email).limit(1).get();

      if (!userQuery.empty) {
        const userRef = userQuery.docs[0].ref;
        await setDoc(
          userRef,
          {
            failedAttempts: increment(1),
            lastFailedAttempt: serverTimestamp(),
          },
          { merge: true }
        );
      }
    } catch (error) {
      console.error('Error recording failed attempt:', error);
    }

    // Check if account should be locked
    if (loginAttempts[email].count >= MAX_LOGIN_ATTEMPTS) {
      setAccountLocked(true);
      setLockoutTime(15); // 15 minutes
    }
  };

  // Sign in with email and password
  const login = async (email, password, recaptchaToken = null) => {
    setError(null);

    // Check for rate limiting
    const isLocked = await checkAccountLockout(email);
    if (isLocked) {
      setError(`Account temporarily locked. Please try again in ${lockoutTime} minutes.`);
      throw new Error(`Account temporarily locked. Please try again in ${lockoutTime} minutes.`);
    }

    // Verify reCAPTCHA if token provided
    if (recaptchaToken) {
      try {
        const verifyResult = await verifyRecaptcha(recaptchaToken);
        if (!verifyResult.success) {
          setError('reCAPTCHA verification failed. Please try again.');
          throw new Error('reCAPTCHA verification failed');
        }
      } catch (err) {
        setError('reCAPTCHA verification failed. Please try again.');
        throw err;
      }
    }

    try {
      const auth = getAuth();
      const result = await signInWithEmailAndPassword(auth, email, password);

      // Update login timestamp
      const db = getFirestore();
      const userRef = doc(db, 'users', result.user.uid);
      await setDoc(
        userRef,
        {
          lastLogin: serverTimestamp(),
          failedAttempts: 0,
          lastFailedAttempt: null,
        },
        { merge: true }
      );

      return result.user;
    } catch (err) {
      // Record failed login attempt
      await recordFailedAttempt(email);

      setError(err.message);
      throw err;
    }
  };

  // Verify reCAPTCHA token
  const verifyRecaptcha = async token => {
    try {
      // Call your backend API to verify the token
      const response = await fetch('/api/verify-recaptcha', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (!response.ok) {
        throw new Error('reCAPTCHA verification failed');
      }

      return await response.json();
    } catch (error) {
      console.error('reCAPTCHA verification error:', error);
      throw error;
    }
  };

  // Sign up with email and password
  const signup = async (email, password, displayName, recaptchaToken = null) => {
    setError(null);

    // Verify reCAPTCHA if token provided
    if (recaptchaToken) {
      try {
        const verifyResult = await verifyRecaptcha(recaptchaToken);
        if (!verifyResult.success) {
          setError('reCAPTCHA verification failed. Please try again.');
          throw new Error('reCAPTCHA verification failed');
        }
      } catch (err) {
        setError('reCAPTCHA verification failed. Please try again.');
        throw err;
      }
    }

    try {
      const auth = getAuth();
      const result = await createUserWithEmailAndPassword(auth, email, password);

      // Update the user's profile if displayName provided
      if (displayName) {
        await updateProfile(result.user, { displayName });
      }

      // Create user document in Firestore
      const db = getFirestore();
      const userRef = doc(db, 'users', result.user.uid);
      await setDoc(userRef, {
        email: result.user.email,
        displayName: displayName || '',
        photoURL: result.user.photoURL || '',
        phoneNumber: result.user.phoneNumber || '',
        role: 'guest',
        createdAt: serverTimestamp(),
        lastLogin: serverTimestamp(),
        failedAttempts: 0,
        lastFailedAttempt: null,
        authProvider: 'email',
      });

      return result.user;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Sign out
  const logout = async () => {
    setError(null);
    try {
      const auth = getAuth();
      await signOut(auth);
      localStorage.setItem('isAuthenticated', 'false');
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Reset password
  const resetPassword = async (email, recaptchaToken = null) => {
    setError(null);

    // Verify reCAPTCHA if token provided
    if (recaptchaToken) {
      try {
        const verifyResult = await verifyRecaptcha(recaptchaToken);
        if (!verifyResult.success) {
          setError('reCAPTCHA verification failed. Please try again.');
          throw new Error('reCAPTCHA verification failed');
        }
      } catch (err) {
        setError('reCAPTCHA verification failed. Please try again.');
        throw err;
      }
    }

    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Google sign in
  const signInWithGoogle = async (recaptchaToken = null) => {
    setError(null);

    // Verify reCAPTCHA if token provided
    if (recaptchaToken) {
      try {
        const verifyResult = await verifyRecaptcha(recaptchaToken);
        if (!verifyResult.success) {
          setError('reCAPTCHA verification failed. Please try again.');
          throw new Error('reCAPTCHA verification failed');
        }
      } catch (err) {
        setError('reCAPTCHA verification failed. Please try again.');
        throw err;
      }
    }

    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');

      const result = await signInWithPopup(auth, provider);

      // Store user data in Firestore
      await storeUserData(result.user, 'google.com');

      return result.user;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Facebook sign in
  const signInWithFacebook = async (recaptchaToken = null) => {
    setError(null);

    // Verify reCAPTCHA if token provided
    if (recaptchaToken) {
      try {
        const verifyResult = await verifyRecaptcha(recaptchaToken);
        if (!verifyResult.success) {
          setError('reCAPTCHA verification failed. Please try again.');
          throw new Error('reCAPTCHA verification failed');
        }
      } catch (err) {
        setError('reCAPTCHA verification failed. Please try again.');
        throw err;
      }
    }

    try {
      const auth = getAuth();
      const provider = new FacebookAuthProvider();
      provider.addScope('email');
      provider.addScope('public_profile');

      const result = await signInWithPopup(auth, provider);

      // Store user data in Firestore
      await storeUserData(result.user, 'facebook.com');

      return result.user;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Twitter/X sign in
  const signInWithTwitter = async (recaptchaToken = null) => {
    setError(null);

    // Verify reCAPTCHA if token provided
    if (recaptchaToken) {
      try {
        const verifyResult = await verifyRecaptcha(recaptchaToken);
        if (!verifyResult.success) {
          setError('reCAPTCHA verification failed. Please try again.');
          throw new Error('reCAPTCHA verification failed');
        }
      } catch (err) {
        setError('reCAPTCHA verification failed. Please try again.');
        throw err;
      }
    }

    try {
      const auth = getAuth();
      const provider = new TwitterAuthProvider();

      const result = await signInWithPopup(auth, provider);

      // Store user data in Firestore
      await storeUserData(result.user, 'twitter.com');

      return result.user;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // LinkedIn sign in
  const signInWithLinkedIn = async (recaptchaToken = null) => {
    setError(null);

    // Verify reCAPTCHA if token provided
    if (recaptchaToken) {
      try {
        const verifyResult = await verifyRecaptcha(recaptchaToken);
        if (!verifyResult.success) {
          setError('reCAPTCHA verification failed. Please try again.');
          throw new Error('reCAPTCHA verification failed');
        }
      } catch (err) {
        setError('reCAPTCHA verification failed. Please try again.');
        throw err;
      }
    }

    try {
      const auth = getAuth();
      const provider = new OAuthProvider('linkedin.com');
      provider.addScope('r_liteprofile');
      provider.addScope('r_emailaddress');

      const result = await signInWithPopup(auth, provider);

      // Store user data in Firestore
      await storeUserData(result.user, 'linkedin.com');

      return result.user;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Instagram sign in (using Facebook OAuth provider)
  const signInWithInstagram = async (recaptchaToken = null) => {
    setError(null);

    // Verify reCAPTCHA if token provided
    if (recaptchaToken) {
      try {
        const verifyResult = await verifyRecaptcha(recaptchaToken);
        if (!verifyResult.success) {
          setError('reCAPTCHA verification failed. Please try again.');
          throw new Error('reCAPTCHA verification failed');
        }
      } catch (err) {
        setError('reCAPTCHA verification failed. Please try again.');
        throw err;
      }
    }

    try {
      const auth = getAuth();
      const provider = new OAuthProvider('facebook.com');
      provider.addScope('email');
      provider.addScope('instagram_basic');
      provider.setCustomParameters({
        // Instagram requires this parameter
        auth_type: 'reauthenticate',
      });

      const result = await signInWithPopup(auth, provider);

      // Store user data in Firestore
      await storeUserData(result.user, 'instagram.com');

      return result.user;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // WhatsApp sign in (using generic provider)
  const signInWithWhatsApp = async (recaptchaToken = null) => {
    setError(null);

    // Verify reCAPTCHA if token provided
    if (recaptchaToken) {
      try {
        const verifyResult = await verifyRecaptcha(recaptchaToken);
        if (!verifyResult.success) {
          setError('reCAPTCHA verification failed. Please try again.');
          throw new Error('reCAPTCHA verification failed');
        }
      } catch (err) {
        setError('reCAPTCHA verification failed. Please try again.');
        throw err;
      }
    }

    try {
      // WhatsApp authentication is not directly supported by Firebase
      // This is a placeholder - actual implementation would require a custom backend
      // that integrates with WhatsApp Business API
      throw new Error('WhatsApp authentication requires a custom backend integration');
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Apple sign in
  const signInWithApple = async (recaptchaToken = null) => {
    setError(null);

    // Verify reCAPTCHA if token provided
    if (recaptchaToken) {
      try {
        const verifyResult = await verifyRecaptcha(recaptchaToken);
        if (!verifyResult.success) {
          setError('reCAPTCHA verification failed. Please try again.');
          throw new Error('reCAPTCHA verification failed');
        }
      } catch (err) {
        setError('reCAPTCHA verification failed. Please try again.');
        throw err;
      }
    }

    try {
      const auth = getAuth();
      const provider = new OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');

      const result = await signInWithPopup(auth, provider);

      // Store user data in Firestore
      await storeUserData(result.user, 'apple.com');

      return result.user;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Microsoft sign in
  const signInWithMicrosoft = async (recaptchaToken = null) => {
    setError(null);

    // Verify reCAPTCHA if token provided
    if (recaptchaToken) {
      try {
        const verifyResult = await verifyRecaptcha(recaptchaToken);
        if (!verifyResult.success) {
          setError('reCAPTCHA verification failed. Please try again.');
          throw new Error('reCAPTCHA verification failed');
        }
      } catch (err) {
        setError('reCAPTCHA verification failed. Please try again.');
        throw err;
      }
    }

    try {
      const auth = getAuth();
      const provider = new OAuthProvider('microsoft.com');
      provider.addScope('user.read');

      const result = await signInWithPopup(auth, provider);

      // Store user data in Firestore
      await storeUserData(result.user, 'microsoft.com');

      return result.user;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Generic function to store user data in Firestore
  const storeUserData = async (user, providerId) => {
    try {
      const db = getFirestore();
      const userRef = doc(db, 'users', user.uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        // Update existing user
        await setDoc(
          userRef,
          {
            lastLogin: serverTimestamp(),
            authProvider: providerId,
            failedAttempts: 0,
            lastFailedAttempt: null,
            ...(user.displayName && { displayName: user.displayName }),
            ...(user.photoURL && { photoURL: user.photoURL }),
            ...(user.email && { email: user.email }),
            ...(user.phoneNumber && { phoneNumber: user.phoneNumber }),
          },
          { merge: true }
        );
      } else {
        // Create new user
        await setDoc(userRef, {
          email: user.email || '',
          displayName: user.displayName || '',
          photoURL: user.photoURL || '',
          phoneNumber: user.phoneNumber || '',
          role: 'guest',
          createdAt: serverTimestamp(),
          lastLogin: serverTimestamp(),
          failedAttempts: 0,
          lastFailedAttempt: null,
          authProvider: providerId,
        });
      }
    } catch (error) {
      console.error('Error storing user data:', error);
      // Continue authentication flow even if storing data fails
    }
  };

  // Link current account with another provider
  const linkWithProvider = async (providerId, recaptchaToken = null) => {
    setError(null);
    if (!user) {
      throw new Error('Must be signed in to link accounts');
    }

    // Verify reCAPTCHA if token provided
    if (recaptchaToken) {
      try {
        const verifyResult = await verifyRecaptcha(recaptchaToken);
        if (!verifyResult.success) {
          setError('reCAPTCHA verification failed. Please try again.');
          throw new Error('reCAPTCHA verification failed');
        }
      } catch (err) {
        setError('reCAPTCHA verification failed. Please try again.');
        throw err;
      }
    }

    try {
      const auth = getAuth();
      let provider;

      switch (providerId) {
        case 'google.com':
          provider = new GoogleAuthProvider();
          break;
        case 'facebook.com':
          provider = new FacebookAuthProvider();
          break;
        case 'twitter.com':
          provider = new TwitterAuthProvider();
          break;
        case 'linkedin.com':
          provider = new OAuthProvider('linkedin.com');
          provider.addScope('r_liteprofile');
          provider.addScope('r_emailaddress');
          break;
        case 'apple.com':
          provider = new OAuthProvider('apple.com');
          break;
        case 'microsoft.com':
          provider = new OAuthProvider('microsoft.com');
          break;
        default:
          throw new Error(`Unsupported provider: ${providerId}`);
      }

      const result = await linkWithPopup(user, provider);
      return result.user;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  return {
    user,
    userRole,
    loading,
    error,
    login,
    signup,
    logout,
    resetPassword,
    signInWithGoogle,
    signInWithFacebook,
    signInWithTwitter,
    signInWithInstagram,
    signInWithWhatsApp,
    signInWithApple,
    signInWithMicrosoft,
    signInWithLinkedIn,
    linkWithProvider,
    accountLocked,
    lockoutTime,
    isAdmin: userRole === 'admin',
    isStaff: userRole === 'staff',
    isAuthenticated: !!user,
    verifyRecaptcha,
  };
}

// Default export for direct usage
export default useAuth;
