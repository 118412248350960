import { collection, query, where, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '../../config';
import ErrorHandler from '../utils/ErrorHandler';
import { serviceRegistry } from '../serviceRegistry';
import DatabaseService from '../core/DatabaseService';
import LoggingService from '../core/LoggingService';

/**
 * RoomAvailabilityService
 * Service for checking room availability
 */
class RoomAvailabilityService {
  /**
   * Create a new RoomAvailabilityService with dependencies
   * @param {Object} loggingService - Logging service instance
   * @param {Object} databaseService - Database service instance
   * @param {Object} apiService - API service instance (optional)
   */
  constructor(loggingService, databaseService, apiService) {
    this.loggingService = loggingService;
    this.databaseService = databaseService;
    this.apiService = apiService;
    
    this.roomsCollection = collection(db, 'rooms');
    this.bookingsCollection = collection(db, 'bookings');
    this.availabilityCache = new Map();
    this.listeners = new Set();
    this.collectionName = 'bookings';
    this.initialized = false;
    this.initializationPromise = null;
  }

  async initialize() {
    if (this.initialized) {
      return this;
    }

    if (this.initializationPromise) {
      return this.initializationPromise;
    }

    this.initializationPromise = (async () => {
      try {
        if (this.databaseService) {
          await this.databaseService.initialize();
        }
        this.initialized = true;
        this.loggingService.logInfo('RoomAvailabilityService', 'RoomAvailabilityService initialized successfully');
        return this;
      } catch (error) {
        this.loggingService.logError('RoomAvailabilityService', 'Failed to initialize RoomAvailabilityService', error);
        this.initialized = false;
        throw new Error(`Failed to initialize room availability service: ${error.message}`);
      } finally {
        this.initializationPromise = null;
      }
    })();

    return this.initializationPromise;
  }

  /**
   * Get room availability for a specific date range
   * @param {Date} startDate - Start date
   * @param {Date} endDate - End date
   * @returns {Promise<Array>} Array of available rooms
   */
  async getRoomAvailability(startDate, endDate) {
    try {
      // Check cache first
      const cacheKey = `${startDate.toISOString()}-${endDate.toISOString()}`;
      if (this.availabilityCache.has(cacheKey)) {
        return this.availabilityCache.get(cacheKey);
      }

      // Query rooms
      const roomsQuery = query(this.roomsCollection);
      const roomsSnapshot = await getDocs(roomsQuery);
      const rooms = roomsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Query bookings for the date range
      const bookingsQuery = query(
        this.bookingsCollection,
        where('checkIn', '<=', endDate),
        where('checkOut', '>=', startDate)
      );
      const bookingsSnapshot = await getDocs(bookingsQuery);
      const bookedRoomIds = new Set(bookingsSnapshot.docs.map(doc => doc.data().roomId));

      // Filter available rooms
      const availableRooms = rooms.filter(room => !bookedRoomIds.has(room.id));

      // Cache the results
      this.availabilityCache.set(cacheKey, availableRooms);

      return availableRooms;
    } catch (error) {
      const handledError = ErrorHandler.handleFirebaseError(error);
      throw handledError;
    }
  }

  /**
   * Subscribe to real-time room availability updates
   * @param {Date} startDate - Start date
   * @param {Date} endDate - End date
   * @param {Function} callback - Callback function to handle updates
   * @returns {Function} Unsubscribe function
   */
  subscribeToAvailability(startDate, endDate, callback) {
    try {
      // Create a unique listener ID
      const listenerId = `${startDate.toISOString()}-${endDate.toISOString()}`;

      // Set up real-time listener for bookings
      const bookingsQuery = query(
        this.bookingsCollection,
        where('checkIn', '<=', endDate),
        where('checkOut', '>=', startDate)
      );

      const unsubscribe = onSnapshot(
        bookingsQuery,
        async snapshot => {
          try {
            // Get updated room availability
            const availableRooms = await this.getRoomAvailability(startDate, endDate);
            callback(availableRooms);
          } catch (error) {
            const handledError = ErrorHandler.handleFirebaseError(error);
            callback(null, handledError);
          }
        },
        error => {
          const handledError = ErrorHandler.handleFirebaseError(error);
          callback(null, handledError);
        }
      );

      // Store the unsubscribe function
      this.listeners.add(listenerId);

      // Return cleanup function
      return () => {
        unsubscribe();
        this.listeners.delete(listenerId);
      };
    } catch (error) {
      const handledError = ErrorHandler.handleFirebaseError(error);
      throw handledError;
    }
  }

  /**
   * Clear the availability cache
   */
  clearCache() {
    this.availabilityCache.clear();
  }

  /**
   * Clean up all listeners
   */
  cleanup() {
    this.listeners.clear();
    this.clearCache();
  }

  /**
   * Check if a specific room is available for the given date range
   * @param {string} roomId - Room ID to check
   * @param {Date|string} checkInDate - Check-in date
   * @param {Date|string} checkOutDate - Check-out date
   * @param {string} excludeBookingId - Optional booking ID to exclude from check
   * @returns {Promise<boolean>} Whether the room is available
   */
  async checkAvailability(roomId, checkInDate, checkOutDate, excludeBookingId = null) {
    if (!this.initialized) {
      await this.initialize();
    }

    try {
      // Convert dates to Date objects if they're strings
      const startDate = checkInDate instanceof Date ? checkInDate : new Date(checkInDate);
      const endDate = checkOutDate instanceof Date ? checkOutDate : new Date(checkOutDate);

      const db = this.databaseService ? this.databaseService.getDb() : null;
      if (!db) {
        throw new Error('Database service not available');
      }

      // Query for bookings that overlap with the requested dates
      let bookingsQuery = query(
        collection(db, this.collectionName),
        where('roomId', '==', roomId),
        where('status', '!=', 'cancelled')
      );

      const snapshot = await getDocs(bookingsQuery);

      // Filter out bookings that don't overlap with the requested date range
      // and exclude the specified booking if provided
      const conflictingBookings = snapshot.docs.filter(doc => {
        const booking = doc.data();
        const bookingStart = new Date(booking.checkInDate);
        const bookingEnd = new Date(booking.checkOutDate);

        const isExcluded = excludeBookingId && doc.id === excludeBookingId;
        const hasOverlap =
          (startDate < bookingEnd && endDate > bookingStart) ||
          startDate.getTime() === bookingStart.getTime();

        return !isExcluded && hasOverlap;
      });

      return conflictingBookings.length === 0;
    } catch (error) {
      this.loggingService.logError('RoomAvailabilityService', `Error checking availability for room ${roomId}`, error);
      throw error;
    }
  }

  async getAvailableRooms(checkInDate, checkOutDate) {
    if (!this.initialized) {
      throw new Error('RoomAvailabilityService not initialized');
    }

    try {
      const db = this.databaseService ? this.databaseService.getDb() : null;
      if (!db) {
        throw new Error('Database service not available');
      }
      
      const roomsRef = collection(db, 'rooms');
      const snapshot = await getDocs(roomsRef);
      const rooms = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Check availability for each room
      const availableRooms = await Promise.all(
        rooms.map(async room => {
          const isAvailable = await this.checkAvailability(room.id, checkInDate, checkOutDate);
          return isAvailable ? room : null;
        })
      );

      return availableRooms.filter(room => room !== null);
    } catch (error) {
      this.loggingService.logError('RoomAvailabilityService', 'Error getting available rooms', error);
      throw error;
    }
  }
}

// Export the class directly for the service factory to instantiate
export default RoomAvailabilityService;
