import { getAuth } from 'firebase/auth';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';

/**
 * LoggingService
 * Service for application logging
 */
class LoggingService {
  constructor() {
    console.log('LoggingService initialized');

    // Define logLevels first before using it
    this.logLevels = {
      DEBUG: 0,
      INFO: 1,
      WARN: 2,
      ERROR: 3,
    };

    // Now we can safely call getLogLevel()
    this.logLevel = this.getLogLevel();
    this.db = null;
    this.auth = null;
    this.initialized = false;
    this.environment = process.env.NODE_ENV || 'development';
    this.appVersion = process.env.REACT_APP_VERSION || '1.0.0';
    this.minimumLevel = this.environment === 'production' ? 'info' : 'debug';

    // Log levels by severity (higher number = more severe)
    this.logLevels = {
      trace: 0,
      debug: 1,
      info: 2,
      warn: 3,
      error: 4,
      fatal: 5,
    };

    // Buffer for logs that couldn't be sent to Firestore
    this.logBuffer = [];
    this.maxBufferSize = 100;
  }

  /**
   * Initialize logging service
   */
  async initialize() {
    console.log('Initializing logging service');
    // Set up any additional configurations or connections
    try {
      // Initialize Firebase services
      this.db = getFirestore();
      this.auth = getAuth();

      this.initialized = true;
      console.log('LoggingService initialized successfully');

      // Log initialization
      this.logInfo('LoggingService', 'Logging service initialized', {
        environment: this.environment,
        appVersion: this.appVersion,
      });
    } catch (error) {
      console.error('Failed to initialize LoggingService:', error);
      throw error;
    }
    return true;
  }

  /**
   * Get current log level from environment or defaults to INFO
   * @returns {number} Log level
   */
  getLogLevel() {
    const envLogLevel = process.env.REACT_APP_LOG_LEVEL || 'INFO';
    // Safely access the logLevels object
    return this.logLevels ? this.logLevels[envLogLevel] || this.logLevels.INFO : 1;
  }

  /**
   * Get current user info for logs
   * @private
   * @returns {Object} User info object
   */
  _getUserInfo() {
    if (!this.auth || !this.auth.currentUser) {
      return { user_id: 'anonymous' };
    }

    const user = this.auth.currentUser;
    return {
      user_id: user.uid,
      email: user.email || '',
      display_name: user.displayName || '',
    };
  }

  /**
   * Check if we should log at this level
   * @private
   * @param {string} level - Log level
   * @returns {boolean} True if should log
   */
  _shouldLog(level) {
    return this.logLevels[level] >= this.logLevels[this.minimumLevel];
  }

  /**
   * Add common metadata to log entry
   * @private
   * @param {Object} data - Log data
   * @returns {Object} Enhanced log data
   */
  _enhanceLogData(data) {
    return {
      ...data,
      timestamp: new Date().toISOString(),
      environment: this.environment,
      app_version: this.appVersion,
      url: window.location.href,
      user_agent: navigator.userAgent,
    };
  }

  /**
   * Send log to console
   * @private
   * @param {string} level - Log level
   * @param {string} source - Log source
   * @param {string} message - Log message
   * @param {Object} data - Additional log data
   */
  _logToConsole(level, source, message, data) {
    const consoleMethod =
      {
        trace: 'debug',
        debug: 'debug',
        info: 'info',
        warn: 'warn',
        error: 'error',
        fatal: 'error',
      }[level] || 'log';

    console[consoleMethod](`[${level.toUpperCase()}] [${source}] ${message}`, data);
  }

  /**
   * Send log to Firestore
   * @private
   * @param {string} level - Log level
   * @param {string} source - Log source
   * @param {string} message - Log message
   * @param {Object} data - Additional log data
   */
  async _logToFirestore(level, source, message, data) {
    if (!this.initialized || !this.db) return;

    try {
      // Only log warning and above to Firestore
      if (this.logLevels[level] < this.logLevels.warn) return;

      const logEntry = {
        level,
        source,
        message,
        data: this._enhanceLogData(data),
        user: this._getUserInfo(),
        created_at: serverTimestamp(),
      };

      await addDoc(collection(this.db, 'logs'), logEntry);
    } catch (error) {
      console.error('Error sending log to Firestore:', error);

      // Add to buffer if it's not too large
      if (this.logBuffer.length < this.maxBufferSize) {
        this.logBuffer.push({ level, source, message, data, timestamp: new Date() });
      }

      // Disable Firestore logging after repeated errors
      if (this.logBuffer.length >= 10) {
        this.enableFirestore = false;
      }
    }
  }

  /**
   * Log a message at a specific level
   * @param {string} level - Log level
   * @param {string} source - Log source
   * @param {string} message - Log message
   * @param {Object} data - Additional log data
   */
  log(level, source, message, data = {}) {
    if (!this._shouldLog(level)) return;

    this._logToConsole(level, source, message, data);
    this._logToFirestore(level, source, message, data);
  }

  /**
   * Log trace message
   * @param {string} source - Log source
   * @param {string} message - Log message
   * @param {Object} data - Additional log data
   */
  logTrace(source, message, data = {}) {
    this.log('trace', source, message, data);
  }

  /**
   * Log debug message
   * @param {string} source - Log source
   * @param {string} message - Log message
   * @param {Object} data - Additional log data
   */
  logDebug(source, message, data = {}) {
    this.log('debug', source, message, data);
  }

  /**
   * Log info message
   * @param {string} source - Log source
   * @param {string} message - Log message
   * @param {Object} data - Additional log data
   */
  logInfo(source, message, data = {}) {
    this.log('info', source, message, data);
  }

  /**
   * Log warning message
   * @param {string} source - Log source
   * @param {string} message - Log message
   * @param {Object} data - Additional log data
   */
  logWarning(source, message, data = {}) {
    this.log('warn', source, message, data);
  }

  /**
   * Log error message
   * @param {string} source - Log source
   * @param {string|Error} error - Error object or message
   * @param {Object} data - Additional error data
   */
  logError(source, error, data = {}) {
    let errorMessage = '';
    let errorData = { ...data };

    if (error instanceof Error) {
      errorMessage = error.message;
      errorData.stack = error.stack;
      errorData.name = error.name;
    } else {
      errorMessage = String(error);
    }

    this.log('error', source, errorMessage, errorData);
  }

  /**
   * Log fatal message
   * @param {string} source - Log source
   * @param {string|Error} error - Error object or message
   * @param {Object} data - Additional error data
   */
  logFatal(source, error, data = {}) {
    let errorMessage = '';
    let errorData = { ...data };

    if (error instanceof Error) {
      errorMessage = error.message;
      errorData.stack = error.stack;
      errorData.name = error.name;
    } else {
      errorMessage = String(error);
    }

    this.log('fatal', source, errorMessage, errorData);
  }

  /**
   * Attempt to send buffered logs to Firestore
   * @returns {Promise<number>} Number of successfully sent logs
   */
  async flushLogBuffer() {
    if (!this.initialized || !this.db || this.logBuffer.length === 0) {
      return 0;
    }

    let successCount = 0;

    for (let i = 0; i < this.logBuffer.length; i++) {
      const { level, source, message, data, timestamp } = this.logBuffer[i];

      try {
        const logEntry = {
          level,
          source,
          message,
          data: this._enhanceLogData(data),
          user: this._getUserInfo(),
          created_at: serverTimestamp(),
          original_timestamp: timestamp,
        };

        await addDoc(collection(this.db, 'logs'), logEntry);
        successCount++;

        // Remove from buffer
        this.logBuffer.splice(i, 1);
        i--; // Adjust index after removal
      } catch (error) {
        console.error('Error flushing log to Firestore:', error);
        // Stop trying if we encounter an error
        break;
      }
    }

    return successCount;
  }
}

// Export the class directly
export default LoggingService;
