import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  Divider,
  Chip,
  Badge,
  Paper,
  Tab,
  Tabs,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import SpaIcon from '@mui/icons-material/Spa';
import WineBarIcon from '@mui/icons-material/WineBar';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import serviceLocator from '../../../services/serviceLocator.js';

const Store = () => {
  const [tabValue, setTabValue] = useState(0);
  const [cart, setCart] = useState([]);
  const [cartOpen, setCartOpen] = useState(false);
  const [checkoutComplete, setCheckoutComplete] = useState(false);
  const [roomNumber, setRoomNumber] = useState('');

  const categories = [
    { label: 'All Items', icon: <ShoppingCartIcon /> },
    { label: 'Snacks', icon: <FoodBankIcon /> },
    { label: 'Drinks', icon: <WineBarIcon /> },
    { label: 'Coffee & Tea', icon: <LocalCafeIcon /> },
    { label: 'Toiletries', icon: <SpaIcon /> },
  ];

  const products = [
    {
      id: 1,
      name: 'Bottled Water',
      category: 'Drinks',
      price: 2.5,
      image:
        'https://images.unsplash.com/photo-1603356033288-acfcb54801e6?auto=format&fit=crop&w=300&h=200',
      description: 'Refreshing purified water, 16 oz bottle.',
    },
    {
      id: 2,
      name: 'Potato Chips',
      category: 'Snacks',
      price: 3.0,
      image:
        'https://images.unsplash.com/photo-1566478989037-eec170784d0b?auto=format&fit=crop&w=300&h=200',
      description: 'Classic salted potato chips, 2.5 oz bag.',
    },
    {
      id: 3,
      name: 'Chocolate Bar',
      category: 'Snacks',
      price: 2.75,
      image:
        'https://images.unsplash.com/photo-1614088685112-0e7dfde648c1?auto=format&fit=crop&w=300&h=200',
      description: 'Premium milk chocolate, 1.5 oz bar.',
    },
    {
      id: 4,
      name: 'Coffee Pod',
      category: 'Coffee & Tea',
      price: 1.5,
      image:
        'https://images.unsplash.com/photo-1610632380989-680fe40816c6?auto=format&fit=crop&w=300&h=200',
      description: 'Single-serve coffee pod for in-room coffee maker.',
    },
    {
      id: 5,
      name: 'Toothbrush Kit',
      category: 'Toiletries',
      price: 4.0,
      image:
        'https://images.unsplash.com/photo-1559589332-48a5b9bf7d01?auto=format&fit=crop&w=300&h=200',
      description: 'Travel toothbrush with mini toothpaste.',
    },
    {
      id: 6,
      name: 'Soda',
      category: 'Drinks',
      price: 2.75,
      image:
        'https://images.unsplash.com/photo-1622483767028-3f66f32aef97?auto=format&fit=crop&w=300&h=200',
      description: 'Various soda options, 12 oz can.',
    },
  ];

  const handleAddToCart = product => {
    // Check if product is already in cart
    const existingItem = cart.find(item => item.id === product.id);

    if (existingItem) {
      setCart(
        cart.map(item => (item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item))
      );
    } else {
      setCart([...cart, { ...product, quantity: 1 }]);
    }
  };

  const handleRemoveFromCart = productId => {
    const existingItem = cart.find(item => item.id === productId);

    if (existingItem.quantity === 1) {
      setCart(cart.filter(item => item.id !== productId));
    } else {
      setCart(
        cart.map(item => (item.id === productId ? { ...item, quantity: item.quantity - 1 } : item))
      );
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCheckout = () => {
    // In a real app, this would process the payment or room charge
    setCheckoutComplete(true);
  };

  const handleDialogClose = () => {
    if (checkoutComplete) {
      setCart([]);
      setCheckoutComplete(false);
    }
    setCartOpen(false);
  };

  const filteredProducts =
    tabValue === 0
      ? products
      : products.filter(product => product.category === categories[tabValue].label);

  const getTotalItems = () => {
    return cart.reduce((total, item) => total + item.quantity, 0);
  };

  const getTotalPrice = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h2" gutterBottom>
          Motel Store
        </Typography>
        <Badge badgeContent={getTotalItems()} color="primary" sx={{ mr: 2 }}>
          <Button
            variant="contained"
            startIcon={<ShoppingCartIcon />}
            onClick={() => setCartOpen(true)}
            disabled={cart.length === 0}
          >
            View Cart (${getTotalPrice()})
          </Button>
        </Badge>
      </Box>

      <Typography variant="body1" paragraph>
        Browse our selection of snacks, beverages, and travel essentials. All purchases can be
        conveniently charged to your room.
      </Typography>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
        <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
          {categories.map((category, index) => (
            <Tab key={index} label={category.label} icon={category.icon} iconPosition="start" />
          ))}
        </Tabs>
      </Box>

      <Grid container spacing={3}>
        {filteredProducts.map(product => (
          <Grid item xs={12} sm={6} md={4} key={product.id}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia component="img" height="160" image={product.image} alt={product.name} />
              <CardContent sx={{ flex: 1 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    {product.name}
                  </Typography>
                  <Typography variant="h6" color="primary">
                    ${serviceLocator.getService('price').toFixed(2)}
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  {product.description}
                </Typography>
                <Chip label={product.category} size="small" sx={{ mb: 2 }} />
                <Button
                  variant="contained"
                  startIcon={<AddShoppingCartIcon />}
                  fullWidth
                  onClick={() => handleAddToCart(product)}
                >
                  Add to Cart
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={cartOpen} onClose={handleDialogClose} maxWidth="sm" fullWidth>
        {checkoutComplete ? (
          <>
            <DialogTitle sx={{ textAlign: 'center' }}>
              <CheckCircleOutlineIcon color="success" sx={{ fontSize: 60, mb: 1 }} />
              <Typography variant="h5">Purchase Complete!</Typography>
            </DialogTitle>
            <DialogContent>
              <Typography variant="body1" align="center" paragraph>
                Your purchase has been successfully charged to Room {roomNumber}.
              </Typography>
              <Typography variant="body1" align="center">
                Items will be delivered to your room shortly.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} variant="contained">
                Continue Shopping
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle>Your Cart</DialogTitle>
            <DialogContent>
              {cart.length === 0 ? (
                <Typography>Your cart is empty.</Typography>
              ) : (
                <>
                  <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
                    {cart.map(item => (
                      <Box
                        key={item.id}
                        sx={{ display: 'flex', py: 1, borderBottom: '1px solid #eee' }}
                      >
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography variant="body1">{item.name}</Typography>
                          <Typography variant="body2" color="text.secondary">
                            ${serviceLocator.getService('price').toFixed(2)} x {item.quantity}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="body1" sx={{ mr: 2 }}>
                            ${(item.price * item.quantity).toFixed(2)}
                          </Typography>
                          <Box>
                            <IconButton size="small" onClick={() => handleRemoveFromCart(item.id)}>
                              <RemoveIcon fontSize="small" />
                            </IconButton>
                            <IconButton size="small" onClick={() => handleAddToCart(item)}>
                              <AddIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 2 }}>
                      <Typography variant="h6">Total:</Typography>
                      <Typography variant="h6">${getTotalPrice()}</Typography>
                    </Box>
                  </Paper>

                  <Typography variant="h6" gutterBottom>
                    Charge to Room
                  </Typography>
                  <TextField
                    fullWidth
                    label="Room Number"
                    value={roomNumber}
                    onChange={e => setRoomNumber(e.target.value)}
                    margin="normal"
                    required
                  />
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose}>Cancel</Button>
              <Button
                onClick={handleCheckout}
                variant="contained"
                disabled={cart.length === 0 || !roomNumber}
              >
                Complete Purchase
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default Store;
