import React, { useState } from 'react';
import { Box, Typography, Paper, TextField, Button, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import BookingCalendar from '../components/BookingCalendar.jsx';
import ErrorNotification from '../../common/ErrorNotification.jsx';
import serviceLocator from '../../../services/serviceLocator.js';

const Bookings = () => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [searchResults, setSearchResults] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [error, setError] = useState(false);

  const handleSearch = () => {
    if (!checkInDate || !checkOutDate) {
      setError(true);
      return;
    }

    // This would typically fetch from an API
    setSearchResults([
      { id: 1, type: 'Standard Queen', price: 99, available: true },
      { id: 2, type: 'Deluxe King', price: 129, available: true },
      { id: 3, type: 'Family Suite', price: 159, available: true },
      { id: 4, type: 'Mountain View Suite', price: 179, available: false },
    ]);
    setHasSearched(true);
    setError(false);
  };

  return (
    <Box>
      {error && (
        <ErrorNotification
          message="Please select both check-in and check-out dates."
          severity="error"
          onClose={() => setError(false)}
        />
      )}

      <Typography variant="h2" gutterBottom>
        Find Your Perfect Room
      </Typography>
      <Typography variant="body1" paragraph>
        Search for room availability and make a reservation.
      </Typography>

      <Paper elevation={3} sx={{ p: 4, mb: 6 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <DatePicker
                label="Check-in Date"
                value={checkInDate}
                onChange={newValue => {
                  setCheckInDate(newValue);
                  // If check-out date is before new check-in date, adjust it
                  if (checkOutDate && newValue > checkOutDate) {
                    const nextDay = new Date(newValue);
                    nextDay.setDate(nextDay.getDate() + 1);
                    setCheckOutDate(nextDay);
                  }
                }}
                renderInput={params => <TextField {...params} fullWidth />}
              />

              <DatePicker
                label="Check-out Date"
                value={checkOutDate}
                minDate={checkInDate || new Date()}
                onChange={newValue => setCheckOutDate(newValue)}
                renderInput={params => <TextField {...params} fullWidth />}
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <TextField
              label="Adults"
              type="number"
              value={adults}
              onChange={e => setAdults(Math.max(1, parseInt(e.target.value) || 0))}
              InputProps={{ inputProps: { min: 1, max: 10 } }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <TextField
              label="Children"
              type="number"
              value={children}
              onChange={e => setChildren(Math.max(0, parseInt(e.target.value) || 0))}
              InputProps={{ inputProps: { min: 0, max: 10 } }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              sx={{ height: '100%' }}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Box mb={6}>
        <Typography variant="h4" gutterBottom>
          Room Availability Calendar
        </Typography>
        <BookingCalendar />
      </Box>

      {hasSearched && (
        <Box>
          <Typography variant="h4" gutterBottom>
            Available Rooms
          </Typography>
          <Grid container spacing={3}>
            {searchResults.map(room => (
              <Grid item xs={12} md={6} key={room.id}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    opacity: room.available ? 1 : 0.6,
                  }}
                >
                  <Typography variant="h5" gutterBottom>
                    {room.type}
                  </Typography>
                  <Typography variant="h6" color="primary" gutterBottom>
                    ${room.price} per night
                  </Typography>
                  <Typography variant="body1" paragraph>
                    A comfortable {room.type.toLowerCase()} room with all amenities.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!room.available}
                    sx={{ alignSelf: 'flex-start', mt: 'auto' }}
                  >
                    {room.available ? 'Book Now' : 'Not Available'}
                  </Button>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default Bookings;
