/**
 * PreferencesService
 * Service for managing user preferences
 */
class PreferencesService {
  /**
   * Create a new PreferencesService
   * @param {Object} loggingService - Logging service
   * @param {Object} storageService - Storage service
   */
  constructor(loggingService, storageService) {
    this.loggingService = loggingService;
    this.storageService = storageService;
    this.preferences = null;
    this.initialized = false;
    this.preferencesKey = 'user_preferences';

    console.log('PreferencesService constructed');
  }

  /**
   * Initialize the preferences service
   * @returns {Promise<boolean>} Success status
   */
  async initialize() {
    try {
      // Load preferences from storage
      this.preferences = this.storageService.getLocal(this.preferencesKey, {});

      this.initialized = true;
      this.loggingService.logInfo('PreferencesService', 'Preferences service initialized');
      return true;
    } catch (error) {
      this.loggingService.logError('PreferencesService', 'Failed to initialize preferences', error);
      throw error;
    }
  }

  /**
   * Get all preferences
   * @returns {Object} User preferences
   */
  getAllPreferences() {
    return { ...this.preferences };
  }

  /**
   * Get a specific preference
   * @param {string} key - Preference key
   * @param {any} defaultValue - Default value if preference doesn't exist
   * @returns {any} Preference value
   */
  getPreference(key, defaultValue = null) {
    return this.preferences?.[key] ?? defaultValue;
  }

  /**
   * Set a preference
   * @param {string} key - Preference key
   * @param {any} value - Preference value
   * @returns {boolean} Success status
   */
  setPreference(key, value) {
    try {
      this.preferences = {
        ...this.preferences,
        [key]: value,
      };

      // Save to storage
      const success = this.storageService.saveLocal(this.preferencesKey, this.preferences);

      if (success) {
        this.loggingService.logInfo('PreferencesService', `Preference updated: ${key}`);
      }

      return success;
    } catch (error) {
      this.loggingService.logError('PreferencesService', `Failed to set preference: ${key}`, error);
      return false;
    }
  }

  /**
   * Set multiple preferences at once
   * @param {Object} preferences - Object with key-value preferences
   * @returns {boolean} Success status
   */
  setPreferences(preferences) {
    try {
      this.preferences = {
        ...this.preferences,
        ...preferences,
      };

      // Save to storage
      const success = this.storageService.saveLocal(this.preferencesKey, this.preferences);

      if (success) {
        this.loggingService.logInfo('PreferencesService', 'Multiple preferences updated');
      }

      return success;
    } catch (error) {
      this.loggingService.logError(
        'PreferencesService',
        'Failed to set multiple preferences',
        error
      );
      return false;
    }
  }

  /**
   * Remove a preference
   * @param {string} key - Preference key to remove
   * @returns {boolean} Success status
   */
  removePreference(key) {
    try {
      if (!this.preferences || !(key in this.preferences)) {
        return true; // Already doesn't exist
      }

      // Create a new object without the key
      const { [key]: removed, ...newPreferences } = this.preferences;
      this.preferences = newPreferences;

      // Save to storage
      const success = this.storageService.saveLocal(this.preferencesKey, this.preferences);

      if (success) {
        this.loggingService.logInfo('PreferencesService', `Preference removed: ${key}`);
      }

      return success;
    } catch (error) {
      this.loggingService.logError(
        'PreferencesService',
        `Failed to remove preference: ${key}`,
        error
      );
      return false;
    }
  }

  /**
   * Clear all preferences
   * @returns {boolean} Success status
   */
  clearPreferences() {
    try {
      this.preferences = {};

      // Save empty object to storage
      const success = this.storageService.saveLocal(this.preferencesKey, this.preferences);

      if (success) {
        this.loggingService.logInfo('PreferencesService', 'All preferences cleared');
      }

      return success;
    } catch (error) {
      this.loggingService.logError('PreferencesService', 'Failed to clear preferences', error);
      return false;
    }
  }
}

// Export the class directly
export default PreferencesService;
