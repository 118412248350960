import { serviceRegistry } from './serviceRegistry.js';
import { auth, db } from '../config';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { loggingService } from './core/LoggingService';

/**
 * @class AuthService
 * @description Service for user authentication, registration, and profile management.
 * This service integrates with Firebase Authentication and Firestore for user data.
 * Provides methods for account creation, login, user profile management, and community features.
 *
 * IMPORTANT: Do not remove or modify methods in this service without updating dependent components.
 *
 * @version 1.0.0
 * @author WebEmo Team
 * @since 2024-06-01
 */
class AuthService {
  /**
   * Creates a new AuthService instance
   * @constructor
   */
  constructor() {
    console.log('AuthService initialized');
    this.currentUser = null;
    this.listeners = [];
    this.initialized = false;
    this.authStateListeners = [];
  }

  /**
   * Initializes the authentication service and checks for existing sessions
   * @async
   * @returns {Promise<boolean>} Whether initialization was successful
   */
  async initialize() {
    try {
      // Check for existing session
      const session = await this.checkSession();
      if (session) {
        this.currentUser = session.user;
      }
      this.initialized = true;

      // Set up auth state listener
      this.unsubscribe = onAuthStateChanged(auth, user => {
        this.currentUser = user;
        // Notify listeners of auth state change
        this.listeners.forEach(listener => listener(user));
      });

      return true;
    } catch (error) {
      console.error('Failed to initialize auth service', error);
      return false;
    }
  }

  /**
   * Checks for an existing authenticated session
   * @async
   * @returns {Promise<Object|null>} Session object if authenticated, null otherwise
   */
  async checkSession() {
    // Implementation will depend on backend authentication system
    // For Firebase, this would check firebase.auth().currentUser
    return null;
  }

  /**
   * Creates a new user account and profile
   * @async
   * @param {Object} userData - User registration data
   * @param {string} userData.email - User's email address
   * @param {string} userData.password - User's password
   * @param {string} userData.name - User's full name
   * @param {string} [userData.phone] - User's phone number
   * @param {boolean} [userData.subscribeNewsletter=false] - Whether user opts into newsletter
   * @param {Object} [bookingData=null] - Optional booking data to associate with new account
   * @returns {Promise<Object>} Created user object
   * @throws {Error} If account creation fails
   */
  async createAccount(userData, bookingData = null) {
    if (!this.initialized) {
      await this.initialize();
    }

    try {
      // 1. Create authentication credentials
      // 2. Create user profile in database
      // 3. Associate with booking if provided
      // 4. Handle newsletter subscription if selected
      // 5. Return created user

      const result = await createUserWithEmailAndPassword(auth, userData.email, userData.password);

      // Create user document in Firestore
      await setDoc(doc(db, 'users', result.user.uid), {
        email: result.user.email,
        createdAt: new Date(),
        role: 'user',
      });

      // Set as current user
      this.currentUser = result.user;

      // Notify listeners
      this._notifyAuthStateChange(this.currentUser);

      return { success: true, user: this.currentUser };
    } catch (error) {
      console.error('Account creation failed:', error);
      throw new Error(error.message || 'Failed to create account');
    }
  }

  /**
   * Logs in a user with email and password
   * @async
   * @param {string} email - User's email address
   * @param {string} password - User's password
   * @returns {Promise<Object>} Logged in user object
   * @throws {Error} If login fails
   */
  async login(email, password) {
    if (!this.initialized) {
      await this.initialize();
    }

    try {
      // Authenticate user with backend
      const result = await signInWithEmailAndPassword(auth, email, password);

      // Set as current user
      this.currentUser = result.user;

      // Notify listeners
      this._notifyAuthStateChange(this.currentUser);

      return { success: true, user: this.currentUser };
    } catch (error) {
      console.error('Login failed:', error);
      throw new Error(error.message || 'Failed to login');
    }
  }

  /**
   * Logs in a user with Google OAuth
   * @async
   * @returns {Promise<Object>} Logged in user object
   * @throws {Error} If login fails
   */
  async loginWithGoogle() {
    if (!this.initialized) {
      await this.initialize();
    }

    try {
      // Implement Google OAuth login
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);

      // Set as current user
      this.currentUser = result.user;

      // Notify listeners
      this._notifyAuthStateChange(this.currentUser);

      return { success: true, user: this.currentUser };
    } catch (error) {
      console.error('Google login failed:', error);
      throw new Error(error.message || 'Failed to login with Google');
    }
  }

  /**
   * Logs the current user out
   * @async
   * @returns {Promise<void>}
   */
  async logout() {
    try {
      // Implementation depends on backend

      // Clear current user
      this.currentUser = null;

      // Notify listeners
      this._notifyAuthStateChange(null);
    } catch (error) {
      console.error('Logout failed:', error);
      throw error;
    }
  }

  /**
   * Checks if a user is currently authenticated
   * @returns {boolean} Whether a user is authenticated
   */
  isAuthenticated() {
    return !!this.currentUser;
  }

  /**
   * Gets the current user object
   * @returns {Object|null} Current user or null if not authenticated
   */
  getCurrentUser() {
    return this.currentUser;
  }

  /**
   * Updates a user's profile information
   * @async
   * @param {string} userId - User ID to update
   * @param {Object} profileData - Updated profile data
   * @returns {Promise<Object>} Updated user profile
   * @throws {Error} If update fails
   */
  async updateUserProfile(userId, profileData) {
    if (!this.initialized) {
      await this.initialize();
    }

    try {
      // Update profile in database

      // If updating current user, update local data
      if (this.currentUser && this.currentUser.uid === userId) {
        this.currentUser.profile = {
          ...this.currentUser.profile,
          ...profileData,
        };

        // Notify listeners of profile change
        this._notifyAuthStateChange(this.currentUser);
      }

      return profileData;
    } catch (error) {
      console.error('Profile update failed:', error);
      throw new Error(error.message || 'Failed to update profile');
    }
  }

  /**
   * Retrieves a user's community profile information
   * @async
   * @param {string} userId - User ID to retrieve
   * @returns {Promise<Object>} User community profile
   * @throws {Error} If retrieval fails
   */
  async getCommunityProfile(userId) {
    try {
      // Fetch community profile from database
      return {
        displayName: 'John Doe',
        bio: 'Travel enthusiast',
        interests: ['Beach', 'Mountains', 'City Exploring'],
        joinDate: '2023-01-15',
        profileImage: 'https://example.com/profile.jpg',
        activityCount: 12,
        membershipTier: 'gold',
        loyaltyPoints: 450,
      };
    } catch (error) {
      console.error('Failed to retrieve community profile:', error);
      throw new Error(error.message || 'Failed to retrieve community profile');
    }
  }

  /**
   * Adds an authentication state change listener
   * @param {Function} listener - Callback function to handle auth state changes
   * @returns {Function} Function to remove the listener
   */
  onAuthStateChanged(listener) {
    this.authStateListeners.push(listener);

    // Return unsubscribe function
    return () => {
      this.authStateListeners = this.authStateListeners.filter(l => l !== listener);
    };
  }

  /**
   * Notifies all listeners of authentication state changes
   * @private
   * @param {Object|null} user - Current user or null if signed out
   */
  _notifyAuthStateChange(user) {
    this.authStateListeners.forEach(listener => {
      try {
        listener(user);
      } catch (error) {
        console.error('Error in auth state listener:', error);
      }
    });
  }

  /**
   * Resets a user's password
   * @async
   * @param {string} email - Email address for password reset
   * @returns {Promise<void>}
   * @throws {Error} If password reset fails
   */
  async resetPassword(email) {
    try {
      // Implementation depends on backend
      await sendPasswordResetEmail(auth, email);
      console.log(`Password reset email sent to ${email}`);
    } catch (error) {
      console.error('Password reset failed:', error);
      throw new Error(error.message || 'Failed to reset password');
    }
  }

  /**
   * Links a user with an existing booking
   * @async
   * @param {string} userId - User ID to link
   * @param {string} bookingId - Booking ID to link
   * @returns {Promise<Object>} Updated user profile
   * @throws {Error} If linking fails
   */
  async linkUserToBooking(userId, bookingId) {
    try {
      // Link booking to user in database
      console.log(`Linked booking ${bookingId} to user ${userId}`);
      return { success: true };
    } catch (error) {
      console.error('Failed to link booking:', error);
      throw new Error(error.message || 'Failed to link booking to user');
    }
  }

  /**
   * Gets a user's loyalty points and membership tier
   * @async
   * @param {string} userId - User ID to retrieve loyalty info for
   * @returns {Promise<Object>} Loyalty information
   * @throws {Error} If retrieval fails
   */
  async getLoyaltyInfo(userId) {
    try {
      // Fetch loyalty information from database
      return {
        points: 450,
        tier: 'gold',
        nextTier: 'platinum',
        pointsToNextTier: 550,
        history: [
          { date: '2024-05-15', points: 100, reason: 'Stay completion' },
          { date: '2024-04-22', points: 50, reason: 'Review submission' },
        ],
      };
    } catch (error) {
      console.error('Failed to retrieve loyalty info:', error);
      throw new Error(error.message || 'Failed to retrieve loyalty information');
    }
  }

  /**
   * Sign in with Facebook
   * @returns {Promise<Object>} Auth result
   */
  async signInWithFacebook() {
    try {
      const provider = new FacebookAuthProvider();
      const result = await signInWithPopup(auth, provider);
      return { success: true, user: result.user };
    } catch (error) {
      console.error('Facebook sign in error:', error);
      return { success: false, error };
    }
  }
}

// Create and export the service instance
const authService = new AuthService();

// Register with service registry
serviceRegistry.register('authService', authService, ['loggingService']);

// Export named and default
export { authService };
export default AuthService;
