import React from 'react';
import { Box, Typography, Container, Paper, Button, Grid } from '@mui/material';
import { Construction, Notifications } from '@mui/icons-material';
import { Link } from 'react-router-dom';

/**
 * ComingSoon component to display when a feature is not yet available
 */
const ComingSoon = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ py: 8 }}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            textAlign: 'center',
            borderRadius: 4,
            backgroundColor: 'background.paper',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '8px',
              bgcolor: 'primary.main',
            }}
          />

          <Construction
            sx={{
              fontSize: 80,
              color: 'primary.main',
              mb: 2,
            }}
          />

          <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
            Big Things Coming Soon!
          </Typography>

          <Typography variant="h5" color="textSecondary" paragraph>
            We're working hard to enhance your booking experience. Our new and improved booking
            system will be available shortly.
          </Typography>

          <Box sx={{ my: 4 }}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} md={6}>
                <Paper
                  elevation={2}
                  sx={{
                    p: 3,
                    height: '100%',
                    backgroundColor: 'background.default',
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Stay Updated
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Want to be notified when our new booking system is live?
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<Notifications />}
                    component={Link}
                    to="/contact"
                    sx={{ mt: 2 }}
                  >
                    Contact Us
                  </Button>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper
                  elevation={2}
                  sx={{
                    p: 3,
                    height: '100%',
                    backgroundColor: 'background.default',
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Other Ways to Book
                  </Typography>
                  <Typography variant="body1" paragraph>
                    In the meantime, you can still book by phone or email.
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    Phone: (207) 835-3575
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    Email: booking@westbethelmotel.com
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Box>

          <Button variant="contained" color="primary" component={Link} to="/" sx={{ mt: 2 }}>
            Return to Home
          </Button>
        </Paper>
      </Box>
    </Container>
  );
};

export default ComingSoon;
