import React from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import { useAuth } from '../hooks/useAuth.js';
import { auth } from '../config/firebase.js';

const AdminTest = () => {
  const { user } = useAuth();
  const [adminStatus, setAdminStatus] = React.useState(null);
  const [error, setError] = React.useState(null);

  const checkAdminStatus = async () => {
    try {
      const idTokenResult = await auth.currentUser?.getIdTokenResult();
      setAdminStatus({
        isAdmin: idTokenResult?.claims?.admin || false,
        email: user?.email,
        provider: user?.providerData[0]?.providerId,
      });
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Box p={3}>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h5" gutterBottom>
          Admin Access Test
        </Typography>

        <Box mb={2}>
          <Typography>Current User: {user?.email}</Typography>
          <Typography>Auth Provider: {user?.providerData[0]?.providerId}</Typography>
        </Box>

        <Button variant="contained" onClick={checkAdminStatus} sx={{ mb: 2 }}>
          Check Admin Status
        </Button>

        {adminStatus && (
          <Box mt={2}>
            <Typography>Admin Status: {adminStatus.isAdmin ? 'Yes' : 'No'}</Typography>
            <Typography>Email: {adminStatus.email}</Typography>
            <Typography>Provider: {adminStatus.provider}</Typography>
          </Box>
        )}

        {error && (
          <Typography color="error" mt={2}>
            Error: {error}
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default AdminTest;
