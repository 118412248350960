/**
 * Service Factory
 * Centralized factory for creating service instances with proper dependency injection
 */

import { serviceRegistry } from './serviceRegistry.js';

// Core services factories
import LoggingService from './LoggingService.js';
import DatabaseService from './core/DatabaseService.js';
import ApiService from './ApiService.js';
import StorageService from './StorageService.js';
import AuthService, { authService } from './AuthService.js';
import FunctionsService from './FunctionsService.js';
import PreferencesService from './PreferencesService.js';

// Domain services factories
import BookingService from './bookingService.js';
import RoomAvailabilityService from './booking/RoomAvailabilityService.js';
import RoomTypeService from './booking/RoomTypeService.js';
import RoomRatesService from './RoomRatesService.js';
import reservationService from './ReservationService.js'; // Import singleton
import guestService from './GuestService.js'; // Import singleton
import PaymentService from './paymentService.js';
import NotificationService from './NotificationService.js';
import pmsService from './PMSService.js'; // Import singleton
import roomSettingsService from './RoomSettingsService.js'; // Import singleton
import smartLockService from './SmartLockService.js'; // Import singleton
import doorService from './DoorService.js'; // Import singleton
import touchscreenService from './TouchscreenService.js'; // Import singleton

// Singleton instance storage
let instance = null;

class ServiceFactory {
  constructor() {
    // Singleton pattern
    if (instance) {
      return instance;
    }

    this.factories = new Map();
    this._registerCoreFactories();
    this._registerDomainFactories();
    instance = this;
  }

  /**
   * Register core service factory functions
   * @private
   */
  _registerCoreFactories() {
    // LoggingService - has no dependencies
    this.factories.set('loggingService', () => {
      return new LoggingService();
    });

    // DatabaseService - depends on loggingService
    this.factories.set('databaseService', () => {
      const loggingService = serviceRegistry.getService('loggingService');
      return new DatabaseService(loggingService);
    });

    // ApiService - depends on loggingService and databaseService
    this.factories.set('apiService', () => {
      const loggingService = serviceRegistry.getService('loggingService');
      const databaseService = serviceRegistry.getService('databaseService');
      return new ApiService(loggingService, databaseService);
    });

    // StorageService - depends on loggingService and databaseService
    this.factories.set('storageService', () => {
      const loggingService = serviceRegistry.getService('loggingService');
      const databaseService = serviceRegistry.getService('databaseService');
      return new StorageService(loggingService, databaseService);
    });

    // AuthService - depends on loggingService, databaseService, and apiService
    this.factories.set('authService', () => {
      // If we already have an instance, use it
      if (authService) {
        return authService;
      }

      // Otherwise create a new instance
      const loggingService = serviceRegistry.getService('loggingService');
      const databaseService = serviceRegistry.getService('databaseService');
      const apiService = serviceRegistry.getService('apiService');
      return new AuthService(loggingService, databaseService, apiService);
    });

    // FunctionsService - depends on loggingService
    this.factories.set('functionsService', () => {
      const loggingService = serviceRegistry.getService('loggingService');
      return new FunctionsService(loggingService);
    });

    // PreferencesService - depends on loggingService and storageService
    this.factories.set('preferencesService', () => {
      const loggingService = serviceRegistry.getService('loggingService');
      const storageService = serviceRegistry.getService('storageService');
      return new PreferencesService(loggingService, storageService);
    });
  }

  /**
   * Register domain service factory functions
   * @private
   */
  _registerDomainFactories() {
    // RoomTypeService
    this.factories.set('roomTypeService', () => {
      const loggingService = serviceRegistry.getService('loggingService');
      const databaseService = serviceRegistry.getService('databaseService');
      return new RoomTypeService(loggingService, databaseService);
    });

    // RoomRatesService
    this.factories.set('roomRatesService', () => {
      const loggingService = serviceRegistry.getService('loggingService');
      const databaseService = serviceRegistry.getService('databaseService');
      const apiService = serviceRegistry.getService('apiService');
      return new RoomRatesService(loggingService, databaseService, apiService);
    });

    // RoomAvailabilityService
    this.factories.set('roomAvailabilityService', () => {
      const loggingService = serviceRegistry.getService('loggingService');
      const databaseService = serviceRegistry.getService('databaseService');
      const apiService = serviceRegistry.getService('apiService');
      return new RoomAvailabilityService(loggingService, databaseService, apiService);
    });

    // BookingService - depends on other room services
    this.factories.set('bookingService', () => {
      const loggingService = serviceRegistry.getService('loggingService');
      const databaseService = serviceRegistry.getService('databaseService');
      const apiService = serviceRegistry.getService('apiService');
      return new BookingService(loggingService, databaseService, apiService);
    });

    // ReservationService - return singleton instance
    this.factories.set('reservationService', () => {
      return reservationService;
    });

    // GuestService - return singleton instance
    this.factories.set('guestService', () => {
      return guestService;
    });

    // PaymentService
    this.factories.set('paymentService', () => {
      const loggingService = serviceRegistry.getService('loggingService');
      const databaseService = serviceRegistry.getService('databaseService');
      const apiService = serviceRegistry.getService('apiService');
      return new PaymentService(loggingService, databaseService, apiService);
    });

    // NotificationService
    this.factories.set('notificationService', () => {
      const loggingService = serviceRegistry.getService('loggingService');
      const databaseService = serviceRegistry.getService('databaseService');
      const apiService = serviceRegistry.getService('apiService');
      return new NotificationService(loggingService, databaseService, apiService);
    });

    // PMSService - return singleton instance
    this.factories.set('pmsService', () => {
      return pmsService;
    });

    // RoomSettingsService - return singleton instance
    this.factories.set('roomSettingsService', () => {
      return roomSettingsService;
    });

    // SmartLockService - return singleton instance
    this.factories.set('smartLockService', () => {
      return smartLockService;
    });

    // DoorService - return singleton instance
    this.factories.set('doorService', () => {
      return doorService;
    });

    // TouchscreenService - return singleton instance
    this.factories.set('touchscreenService', () => {
      return touchscreenService;
    });
  }

  /**
   * Create a service instance using the appropriate factory
   * @param {string} serviceName - Name of the service to create
   * @returns {Object} - Service instance
   */
  createService(serviceName) {
    if (!this.factories.has(serviceName)) {
      throw new Error(`No factory registered for service: ${serviceName}`);
    }

    // Get the factory function and create the service
    const factory = this.factories.get(serviceName);
    return factory();
  }

  /**
   * Create and register a service all at once
   * @param {string} serviceName - Name of the service to create and register
   * @returns {Object} - Service instance
   */
  createAndRegisterService(serviceName) {
    const service = this.createService(serviceName);

    // Determine dependencies based on the service's dependencies
    const dependencies = this._getDependenciesForService(serviceName);

    // Register the service with its dependencies
    serviceRegistry.register(serviceName, service, dependencies);

    return service;
  }

  /**
   * Get dependencies for a specific service
   * @param {string} serviceName - Name of the service
   * @returns {Array<string>} - List of dependency service names
   */
  _getDependenciesForService(serviceName) {
    // Core services dependencies
    const dependencyMap = {
      loggingService: [],
      databaseService: ['loggingService'],
      apiService: ['loggingService', 'databaseService'],
      storageService: ['loggingService', 'databaseService'],
      authService: ['loggingService', 'databaseService', 'apiService'],
      functionsService: ['loggingService'],
      preferencesService: ['loggingService', 'storageService'],

      // Domain services dependencies
      roomTypeService: ['loggingService', 'databaseService'],
      roomRatesService: ['loggingService', 'databaseService', 'apiService'],
      roomAvailabilityService: ['loggingService', 'databaseService', 'apiService'],
      bookingService: [
        'loggingService',
        'databaseService',
        'apiService',
        'roomTypeService',
        'roomRatesService',
        'roomAvailabilityService',
      ],
      reservationService: ['loggingService', 'databaseService', 'apiService'],
      guestService: ['loggingService', 'databaseService', 'apiService'],
      paymentService: ['loggingService', 'databaseService', 'apiService'],
      notificationService: ['loggingService', 'databaseService', 'apiService'],
      pmsService: ['loggingService', 'databaseService', 'apiService'],
      roomSettingsService: ['loggingService', 'databaseService'],
      smartLockService: ['loggingService', 'apiService'],
      doorService: ['loggingService', 'apiService', 'smartLockService'],
      touchscreenService: ['loggingService', 'apiService'],
    };

    return dependencyMap[serviceName] || [];
  }
}

// Export singleton instance
const serviceFactory = new ServiceFactory();
export default serviceFactory;
