import { serviceRegistry } from './serviceRegistry.js';
import { db } from '../config/firebase.js';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  orderBy,
  limit,
} from 'firebase/firestore';

/**
 * GuestService
 * Service for guest management functionality
 */
class GuestService {
  constructor() {
    console.log('GuestService initialized');
    this.collectionName = 'guests';
    this.initialized = false;

    // Try to get services we depend on
    try {
      this.notificationService = serviceRegistry.getService('notificationService');
    } catch (error) {
      console.log('NotificationService not available yet, will lazy load if needed');
    }
  }

  /**
   * Initialize the service
   */
  initialize() {
    this.initialized = true;
    return this;
  }

  /**
   * Get notification service with lazy loading
   * @private
   */
  _getNotificationService() {
    if (!this.notificationService) {
      this.notificationService = serviceRegistry.getService('notificationService');
    }
    return this.notificationService;
  }

  /**
   * Create a new guest
   * @param {Object} guestData - Guest data
   * @returns {Promise<Object>} - Created guest object with ID
   */
  async createGuest(guestData) {
    try {
      if (!guestData) {
        throw new Error('Guest data is required');
      }

      // Validate required fields
      const requiredFields = ['firstName', 'lastName', 'email'];
      for (const field of requiredFields) {
        if (!guestData[field]) {
          throw new Error(`Missing required field: ${field}`);
        }
      }

      // Add timestamps
      const newGuestData = {
        ...guestData,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        status: guestData.status || 'active',
      };

      // Add to database
      const guestRef = await addDoc(collection(db, this.collectionName), newGuestData);

      // Send welcome notification if enabled
      if (guestData.sendWelcome !== false && this._getNotificationService()) {
        try {
          await this._getNotificationService().sendWelcomeNotification({
            id: guestRef.id,
            ...newGuestData,
          });
        } catch (error) {
          console.error('Failed to send welcome notification:', error);
          // Continue anyway, this is not critical
        }
      }

      return {
        id: guestRef.id,
        ...newGuestData,
      };
    } catch (error) {
      console.error('Error creating guest:', error);
      throw new Error(`Failed to create guest: ${error.message}`);
    }
  }

  /**
   * Get a guest by ID
   * @param {string} guestId - The guest ID
   * @returns {Promise<Object>} - Guest object
   */
  async getGuest(guestId) {
    if (!guestId) {
      throw new Error('Guest ID is required');
    }

    try {
      const guestDoc = await getDoc(doc(db, this.collectionName, guestId));

      if (!guestDoc.exists()) {
        throw new Error(`Guest with ID ${guestId} not found`);
      }

      return {
        id: guestDoc.id,
        ...guestDoc.data(),
      };
    } catch (error) {
      console.error(`Error fetching guest ${guestId}:`, error);
      throw new Error(`Failed to fetch guest: ${error.message}`);
    }
  }

  /**
   * Update guest information
   * @param {string} guestId - The guest ID
   * @param {Object} guestData - Updated guest data
   * @returns {Promise<Object>} - Updated guest object
   */
  async updateGuest(guestId, guestData) {
    if (!guestId) {
      throw new Error('Guest ID is required');
    }

    if (!guestData || Object.keys(guestData).length === 0) {
      throw new Error('Update data is required');
    }

    try {
      const guestRef = doc(db, this.collectionName, guestId);
      const guestDoc = await getDoc(guestRef);

      if (!guestDoc.exists()) {
        throw new Error(`Guest with ID ${guestId} not found`);
      }

      // Add updated timestamp
      const updateData = {
        ...guestData,
        updatedAt: serverTimestamp(),
      };

      await updateDoc(guestRef, updateData);

      // Get the updated document
      const updatedDoc = await getDoc(guestRef);

      return {
        id: updatedDoc.id,
        ...updatedDoc.data(),
      };
    } catch (error) {
      console.error(`Error updating guest ${guestId}:`, error);
      throw new Error(`Failed to update guest: ${error.message}`);
    }
  }

  /**
   * Delete a guest
   * @param {string} guestId - The guest ID to delete
   * @returns {Promise<boolean>} - Success status
   */
  async deleteGuest(guestId) {
    if (!guestId) {
      throw new Error('Guest ID is required');
    }

    try {
      const guestRef = doc(db, this.collectionName, guestId);
      const guestDoc = await getDoc(guestRef);

      if (!guestDoc.exists()) {
        throw new Error(`Guest with ID ${guestId} not found`);
      }

      await deleteDoc(guestRef);
      return true;
    } catch (error) {
      console.error(`Error deleting guest ${guestId}:`, error);
      throw new Error(`Failed to delete guest: ${error.message}`);
    }
  }

  /**
   * Search for guests
   * @param {Object} filters - Search filters
   * @returns {Promise<Array>} - Array of matching guests
   */
  async searchGuests(filters = {}) {
    try {
      let guestsQuery = collection(db, this.collectionName);

      // Apply filters
      if (filters.status) {
        guestsQuery = query(guestsQuery, where('status', '==', filters.status));
      }

      if (filters.email) {
        guestsQuery = query(guestsQuery, where('email', '==', filters.email.toLowerCase()));
      }

      if (filters.lastName) {
        // Case insensitive search isn't directly supported, so this is a workaround
        // This only works for exact matches
        guestsQuery = query(guestsQuery, where('lastName', '==', filters.lastName));
      }

      // Add sorting
      if (filters.orderBy) {
        guestsQuery = query(guestsQuery, orderBy(filters.orderBy, filters.orderDir || 'asc'));
      } else {
        // Default sort by created date
        guestsQuery = query(guestsQuery, orderBy('createdAt', 'desc'));
      }

      // Add limit if specified
      if (filters.limit) {
        guestsQuery = query(guestsQuery, limit(parseInt(filters.limit)));
      }

      const snapshot = await getDocs(guestsQuery);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error('Error searching guests:', error);
      throw new Error(`Failed to search guests: ${error.message}`);
    }
  }

  /**
   * Get guest by email
   * @param {string} email - Guest email
   * @returns {Promise<Object|null>} - Guest object or null if not found
   */
  async getGuestByEmail(email) {
    if (!email) {
      throw new Error('Email is required');
    }

    try {
      const guestsQuery = query(
        collection(db, this.collectionName),
        where('email', '==', email.toLowerCase()),
        limit(1)
      );

      const snapshot = await getDocs(guestsQuery);

      if (snapshot.empty) {
        return null;
      }

      const doc = snapshot.docs[0];
      return {
        id: doc.id,
        ...doc.data(),
      };
    } catch (error) {
      console.error(`Error getting guest by email ${email}:`, error);
      throw new Error(`Failed to get guest by email: ${error.message}`);
    }
  }
}

// Create and export a singleton instance
const guestService = new GuestService();
serviceRegistry.register('guestService', guestService);

export default guestService;
