/**
 * Main App Component
 * Top-level component for the application
 */
import React, { useEffect, useState } from 'react';
import './App.css';
import { Box, Typography, CircularProgress } from '@mui/material';
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { HelmetProvider } from 'react-helmet-async';
import AppRoutes from './routes/AppRoutes';

// Import components
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home.jsx';
import Rooms from './components/rooms/Rooms';
import Booking from './components/booking/Booking';
import Contact from './components/Contact';
import Dashboard from './components/admin/Dashboard';

// Import services
import { serviceRegistry } from './services/serviceRegistry';
import serviceLocator from './services/serviceLocator';

// Error boundary to catch render errors
class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('App error caught:', error, errorInfo);

    // Try to log error to service if available
    try {
      const loggingService = serviceRegistry.getService('loggingService');
      if (loggingService && loggingService.logError) {
        loggingService.logError('AppCrash', error, errorInfo);
      } else {
        console.error('Logging service not available or missing logError method');
      }
    } catch (e) {
      // Fallback if logging service not available
      console.error('Failed to log error to service:', e);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            p: 3,
          }}
        >
          <Typography variant="h4" color="error" gutterBottom>
            Something went wrong
          </Typography>
          <Typography variant="body1" paragraph>
            The application encountered an unexpected error.
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            {this.state.error?.message || 'Unknown error'}
          </Typography>
          <button
            onClick={() => window.location.reload()}
            style={{
              padding: '8px 16px',
              background: '#1976d2',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Reload Application
          </button>
        </Box>
      );
    }

    return this.props.children;
  }
}

// Main App component
const App = () => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    // Initialize services if needed
    const initializeServices = async () => {
      try {
        // Get the authentication service
        if (serviceLocator.hasService('authService')) {
          const authService = serviceLocator.getService('authService');

          // Check if user is already authenticated
          await authService.checkSession();
        }

        // Get the analytics service if available
        if (serviceLocator.hasService('analytics')) {
          const analyticsService = serviceLocator.getService('analytics');

          // Initialize analytics
          if (analyticsService && !analyticsService.initialized) {
            await analyticsService.initialize();
          }
        }

        setInitialized(true);
      } catch (err) {
        console.error('Error initializing services:', err);
        setInitialized(true); // Still set to initialized so app can render
      }
    };

    initializeServices();
  }, []);

  if (!initialized) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <AppRoutes />
        </Box>
      </ThemeProvider>
    </HelmetProvider>
  );
};

// Export with error boundary wrapper
export default function AppWithErrorBoundary() {
  return (
    <AppErrorBoundary>
      <App />
    </AppErrorBoundary>
  );
}
