import React, { useEffect, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { addDays, startOfDay } from 'date-fns';
import appConfig from '../../config/appConfig';

const BookDirectLink = ({
  size = 'medium',
  variant = 'contained',
  color = 'primary',
  label = 'Book Now',
}) => {
  const [loading, setLoading] = useState(true);
  const [bookingUrl, setBookingUrl] = useState('');

  useEffect(() => {
    // Skip if external booking is disabled
    if (appConfig.externalBooking.enabled === false) {
      setLoading(false);
      return;
    }

    const today = startOfDay(new Date());
    const tomorrow = addDays(today, 1);

    // Format dates for RMS URL
    const checkIn = today.toISOString().split('T')[0];
    const checkOut = tomorrow.toISOString().split('T')[0];

    // Use centralized configuration
    const baseUrl = appConfig.externalBooking.baseUrl;
    // Format timestamp as yyyyMMddHHmmss
    const formattedTimestamp = today.toISOString().replace(/[-:T.]/g, '').substring(0, 14);
    const queryParams = new URLSearchParams({
      A: checkIn,
      D: checkOut,
      Dn: formattedTimestamp,
      Rt: 0,
      Ad: 2, // 2 adults
      Ch: 0, // 0 children
    });

    const url = `${baseUrl}?${queryParams.toString()}#!/rate`;
    console.log('Direct booking URL generated:', url);
    setBookingUrl(url);
    setLoading(false);
  }, []);

  if (loading) {
    return <CircularProgress size={24} />;
  }

  // If external booking is disabled, don't render the button
  if (appConfig.externalBooking.enabled === false) {
    return null;
  }

  return (
    <Button
      variant={variant}
      color={color}
      size={size}
      href={bookingUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      {label}
    </Button>
  );
};

export default BookDirectLink;
