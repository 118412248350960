import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  addDoc,
  updateDoc,
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { ErrorHandler } from '../utils/ErrorHandler';
import { serviceRegistry } from './serviceRegistry';

/**
 * Notification Service
 * Handles in-app notifications and alerts
 */

/**
 * Service for handling notifications
 */
class NotificationService {
  constructor() {
    this.notificationsCollection = collection(db, 'notifications');
    // Defer getting bookingService to an initialization method
    this.bookingService = null;
    this.initialized = false;
    this.notifications = [];
    this.listeners = new Set();
    this.maxNotifications = 100;
  }

  /**
   * Initialize the notification service
   */
  initialize() {
    try {
      // Get services from service registry
      if (serviceRegistry.hasService('loggingService')) {
        this.loggingService = serviceRegistry.getService('loggingService');
      } else {
        console.warn(
          'LoggingService not found in registry, notifications may not be properly logged'
        );
        // Create a minimal fallback logging service
        this.loggingService = {
          logInfo: message => console.info(message),
          logError: (message, error) => console.error(message, error),
          logWarning: message => console.warn(message),
        };
      }

      // Get bookingService if available
      if (serviceRegistry.hasService('bookingService')) {
        this.bookingService = serviceRegistry.getService('bookingService');
      } else {
        console.warn('BookingService not found in registry, booking notifications will not work');
      }
      
      // Register event listeners
      this.registerEventListeners();

      this.initialized = true;
      console.log('NotificationService initialized');
    } catch (error) {
      console.error('Failed to initialize NotificationService:', error);
      // Continue initialization with limited functionality
      this.initialized = true;
    }

    return this;
  }

  /**
   * Create a new notification
   * @param {Object} notificationData - Notification details
   * @returns {Promise<Object>} Created notification
   */
  async createNotification(notificationData) {
    try {
      const notificationRef = await addDoc(this.notificationsCollection, {
        ...notificationData,
        status: 'unread',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      });

      return {
        id: notificationRef.id,
        ...notificationData,
      };
    } catch (error) {
      throw ErrorHandler.handleFirebaseError(error);
    }
  }

  /**
   * Get notifications for a user
   * @param {string} userId - The ID of the user
   * @param {Object} options - Query options (limit, status)
   * @returns {Promise<Array>} Array of notifications
   */
  async getUserNotifications(userId, options = {}) {
    try {
      let notificationsQuery = query(this.notificationsCollection, where('userId', '==', userId));

      if (options.status) {
        notificationsQuery = query(notificationsQuery, where('status', '==', options.status));
      }

      const snapshot = await getDocs(notificationsQuery);
      let notifications = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (options.limit) {
        notifications = notifications.slice(0, options.limit);
      }

      return notifications;
    } catch (error) {
      throw ErrorHandler.handleFirebaseError(error);
    }
  }

  /**
   * Mark notification as read
   * @param {string} notificationId - The ID of the notification
   * @returns {Promise<void>}
   */
  async markAsRead(notificationId) {
    try {
      await updateDoc(doc(this.notificationsCollection, notificationId), {
        status: 'read',
        readAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      });
    } catch (error) {
      throw ErrorHandler.handleFirebaseError(error);
    }
  }

  /**
   * Create booking notification
   * @param {string} bookingId - The ID of the booking
   * @param {string} type - Notification type (confirmation, reminder, etc.)
   * @returns {Promise<void>}
   */
  async createBookingNotification(bookingId, type) {
    try {
      // Check if bookingService is available
      if (!this.bookingService) {
        console.warn('BookingService not available, cannot create booking notification');
        return;
      }

      const booking = await this.bookingService.getBooking(bookingId);

      let notificationData = {
        userId: booking.guestId,
        bookingId,
        type,
        title: '',
        message: '',
        status: 'unread',
      };

      switch (type) {
        case 'confirmation':
          notificationData.title = 'Booking Confirmed';
          notificationData.message = `Your booking for ${booking.roomName} has been confirmed.`;
          break;
        case 'reminder':
          notificationData.title = 'Upcoming Stay Reminder';
          notificationData.message = `Your stay at ${booking.roomName} is coming up soon.`;
          break;
        case 'checkin':
          notificationData.title = 'Check-in Reminder';
          notificationData.message = `It's time to check in to ${booking.roomName}.`;
          break;
        case 'checkout':
          notificationData.title = 'Check-out Reminder';
          notificationData.message = `Your check-out time for ${booking.roomName} is approaching.`;
          break;
        default:
          throw new Error('Invalid notification type');
      }

      await this.createNotification(notificationData);
    } catch (error) {
      console.error('Error creating booking notification:', error);
      // Don't throw, allow application to continue
    }
  }

  /**
   * Get unread notification count
   * @param {string} userId - The ID of the user
   * @returns {Promise<number>} Number of unread notifications
   */
  async getUnreadCount(userId) {
    try {
      const notifications = await this.getUserNotifications(userId, { status: 'unread' });
      return notifications.length;
    } catch (error) {
      throw ErrorHandler.handleFirebaseError(error);
    }
  }

  /**
   * Add a notification
   * @param {Object} notification - Notification data
   * @param {string} notification.title - Notification title
   * @param {string} notification.message - Notification message
   * @param {string} notification.type - Notification type (info, success, warning, error)
   * @param {number} notification.duration - Auto-dismiss duration in ms (0 for no auto-dismiss)
   * @returns {string} Notification ID
   */
  addNotification({ title, message, type = 'info', duration = 5000 }) {
    try {
      if (!this.initialized) {
        this.initialize();
      }

      const notification = {
        id: `notification-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
        title,
        message,
        type,
        duration,
        timestamp: new Date(),
        read: false,
        dismissed: false,
      };

      // Add to front of array
      this.notifications.unshift(notification);

      // Trim notifications list if needed
      if (this.notifications.length > this.maxNotifications) {
        this.notifications = this.notifications.slice(0, this.maxNotifications);
      }

      // Notify listeners
      this._notifyListeners();

      // Show browser notification if supported and allowed
      this._showBrowserNotification(notification);

      // Auto-dismiss if duration is set
      if (duration > 0) {
        setTimeout(() => this.dismissNotification(notification.id), duration);
      }

      return notification.id;
    } catch (error) {
      console.error('Error adding notification:', error);
      return null;
    }
  }

  /**
   * Get all notifications
   * @param {Object} options - Filter options
   * @param {boolean} options.unreadOnly - Only return unread notifications
   * @returns {Array} Array of notifications
   */
  getNotifications({ unreadOnly = false } = {}) {
    if (unreadOnly) {
      return this.notifications.filter(notification => !notification.read);
    }
    return [...this.notifications];
  }

  /**
   * Mark all notifications as read
   */
  markAllAsRead() {
    let changed = false;
    this.notifications.forEach(notification => {
      if (!notification.read) {
        notification.read = true;
        changed = true;
      }
    });

    if (changed) {
      this._notifyListeners();
    }
  }

  /**
   * Dismiss a notification
   * @param {string} id - Notification ID
   * @returns {boolean} True if successful
   */
  dismissNotification(id) {
    const notification = this.notifications.find(n => n.id === id);
    if (notification) {
      notification.dismissed = true;
      this._notifyListeners();
      return true;
    }
    return false;
  }

  /**
   * Clear all notifications
   */
  clearAll() {
    if (this.notifications.length > 0) {
      this.notifications = [];
      this._notifyListeners();
    }
  }

  /**
   * Add a listener for notification changes
   * @param {Function} listener - Callback function
   * @returns {Function} Function to remove the listener
   */
  addListener(listener) {
    if (typeof listener !== 'function') {
      throw new Error('Listener must be a function');
    }

    this.listeners.add(listener);

    // Return unsubscribe function
    return () => this.listeners.delete(listener);
  }

  /**
   * Request browser notification permission
   * @returns {Promise<string>} Permission status
   */
  async requestPermission() {
    if (!this.notificationsSupported) {
      return 'unsupported';
    }

    try {
      const permission = await Notification.requestPermission();
      this.notificationPermission = permission;
      return permission;
    } catch (error) {
      this.logger.logError(
        'NotificationService',
        'Error requesting notification permission',
        error
      );
      return 'denied';
    }
  }

  /**
   * Check if browser notifications are supported
   * @returns {boolean} True if supported
   */
  isBrowserNotificationSupported() {
    return this.notificationsSupported;
  }

  /**
   * Get current browser notification permission
   * @returns {string} Permission status
   */
  getBrowserNotificationPermission() {
    return this.notificationPermission;
  }

  /**
   * Notify all listeners of changes
   * @private
   */
  _notifyListeners() {
    const data = {
      notifications: this.getNotifications(),
      unreadCount: this.getNotifications({ unreadOnly: true }).length,
    };

    this.listeners.forEach(listener => {
      try {
        listener(data);
      } catch (error) {
        console.error('Error in notification listener:', error);
      }
    });
  }

  /**
   * Show browser notification
   * @private
   * @param {Object} notification - Notification data
   */
  _showBrowserNotification(notification) {
    if (!this.notificationsSupported || this.notificationPermission !== 'granted') {
      return;
    }

    try {
      const browserNotification = new Notification(notification.title, {
        body: notification.message,
        icon: '/logo192.png',
        tag: notification.id,
      });

      browserNotification.onclick = () => {
        window.focus();
        this.markAsRead(notification.id);
      };
    } catch (error) {
      this.logger.logError('NotificationService', 'Error showing browser notification', error);
    }
  }

  /**
   * Show an info notification
   * @param {string} title - Notification title
   * @param {string} message - Notification message
   * @param {number} duration - Auto-dismiss duration in ms
   * @returns {string} Notification ID
   */
  info(title, message, duration = 5000) {
    return this.addNotification({
      title,
      message,
      type: 'info',
      duration,
    });
  }

  /**
   * Show a success notification
   * @param {string} title - Notification title
   * @param {string} message - Notification message
   * @param {number} duration - Auto-dismiss duration in ms
   * @returns {string} Notification ID
   */
  success(title, message, duration = 5000) {
    return this.addNotification({
      title,
      message,
      type: 'success',
      duration,
    });
  }

  /**
   * Show a warning notification
   * @param {string} title - Notification title
   * @param {string} message - Notification message
   * @param {number} duration - Auto-dismiss duration in ms
   * @returns {string} Notification ID
   */
  warning(title, message, duration = 7000) {
    return this.addNotification({
      title,
      message,
      type: 'warning',
      duration,
    });
  }

  /**
   * Show an error notification
   * @param {string} title - Notification title
   * @param {string} message - Notification message
   * @param {number} duration - Auto-dismiss duration in ms
   * @returns {string} Notification ID
   */
  error(title, message, duration = 10000) {
    return this.addNotification({
      title,
      message,
      type: 'error',
      duration,
    });
  }

  /**
   * Register for events from other services
   */
  registerEventListeners() {
    try {
      // Listen for booking events if the service is available
      if (this.bookingService && typeof this.bookingService.on === 'function') {
        this.bookingService.on('bookingCreated', this.handleBookingCreated.bind(this));
        this.bookingService.on('bookingUpdated', this.handleBookingUpdated.bind(this));
        this.bookingService.on('bookingCancelled', this.handleBookingCancelled.bind(this));
      }
    } catch (error) {
      console.error('Error registering notification event listeners:', error);
    }
  }
}

// Create and export a singleton instance
const notificationService = new NotificationService();
serviceRegistry.register('notificationService', notificationService);
export default NotificationService;
