import { serviceRegistry } from './serviceRegistry.js';
import { db } from '../config/firebase.js';

/**
 * TouchscreenService
 * Handles touchscreen and kiosk specific functionality
 */
class TouchscreenService {
  constructor() {
    this.initialized = false;
    console.log('TouchscreenService initialized');
  }

  /**
   * Initialize the service
   */
  initialize() {
    this.initialized = true;
    return this;
  }

  /**
   * Validate touchscreen PIN code
   * @param {string} pin - The PIN to validate
   * @returns {Promise<boolean>} - Whether the PIN is valid
   */
  async validatePin(pin) {
    try {
      // In production, this would validate against stored PINs or backend
      // For development, we'll accept a simple default
      const defaultPin = '1234';

      // If we have API service available, use it
      if (serviceRegistry.hasService('apiService')) {
        const apiService = serviceRegistry.getService('apiService');
        try {
          const response = await apiService.post('/kiosk/validate-pin', { pin });
          return response.success;
        } catch (error) {
          console.warn('API PIN validation failed, falling back to default', error);
          return pin === defaultPin;
        }
      }

      // Default fallback
      return pin === defaultPin;
    } catch (error) {
      console.error('PIN validation error:', error);
      return false;
    }
  }

  /**
   * Get the idle timeout for the touchscreen
   * @returns {number} - Timeout in milliseconds
   */
  getIdleTimeout() {
    return 180000; // 3 minutes by default
  }

  /**
   * Get touchscreen display settings
   * @returns {Object} - Display settings
   */
  getDisplaySettings() {
    return {
      brightness: 80,
      fontSize: 'medium',
      highContrast: false,
      enableAnimations: true,
    };
  }

  /**
   * Get available kiosk features
   * @returns {Array} - List of enabled features
   */
  getEnabledFeatures() {
    return ['check-in', 'check-out', 'room-access', 'hotel-info', 'store'];
  }

  /**
   * Log kiosk usage for analytics
   * @param {string} action - The action performed
   * @param {Object} details - Additional details
   */
  logUsage(action, details = {}) {
    try {
      const timestamp = new Date().toISOString();
      const logData = {
        timestamp,
        action,
        ...details,
      };

      console.log('Kiosk usage:', logData);

      // If we have a logging service, use it
      if (serviceRegistry.hasService('loggingService')) {
        const loggingService = serviceRegistry.getService('loggingService');
        loggingService.logEvent('kiosk_action', logData);
      }

      // Could also save to Firestore directly if needed
      return true;
    } catch (error) {
      console.error('Failed to log kiosk usage:', error);
      return false;
    }
  }
}

// Create and export singleton instance
const touchscreenService = new TouchscreenService();

// Register with service registry
serviceRegistry.register('touchscreenService', touchscreenService);

export default touchscreenService;
