/**
 * Feature flags configuration for the application
 * Controls which features are enabled or disabled
 */

// Default feature flags
const defaultFlags = {
  // Touchscreen features
  touchscreenEnabled: true,
  touchscreenLocking: true,
  touchscreenVideoConferencing: true,

  // Office desk capabilities
  touchscreenCheckIn: true,
  touchscreenCheckOut: true,
  touchscreenBookings: true,
  touchscreenRoomManagement: true,
  touchscreenStoreItems: true,
  touchscreenPayments: true,
  touchscreenDoorAccess: true,

  // Booking features
  bookingEnabled: true, // Controls whether the booking page is accessible

  // Rate Intelligence features
  rateIntelligenceEnabled: true,
  rateManagement: true,
  rateAutomation: true,
  customerSegments: true,
  marketingCampaigns: true, // Ensure this is set to true
  rateAnalytics: true,
  revenueManagement: true,
  rateReporting: true,
  rateSimulation: true, // Ensure this flag is set to true

  // Feature groups
  basicRateManagement: true, // Controls basic rate management features
  advancedRateManagement: true, // Controls advanced features like automation and analytics
};

// Try to load flags from localStorage
let storedFlags;
try {
  const stored = localStorage.getItem('featureFlags');
  storedFlags = stored ? JSON.parse(stored) : null;
} catch (e) {
  console.warn('Failed to load feature flags from localStorage:', e);
  storedFlags = null;
}

const featureFlags = storedFlags || defaultFlags;

// Helper to check if a feature is enabled
export const isFeatureEnabled = featureName => {
  if (featureName in featureFlags) {
    return featureFlags[featureName];
  }
  console.warn(`Feature flag "${featureName}" not found`);
  return false;
};

// Helper to check if a feature group is enabled
export const isFeatureGroupEnabled = groupName => {
  switch (groupName) {
    case 'basicRateManagement':
      return featureFlags.basicRateManagement && featureFlags.rateIntelligenceEnabled;
    case 'advancedRateManagement':
      return featureFlags.advancedRateManagement && featureFlags.rateIntelligenceEnabled;
    case 'allRateManagement':
      return (
        featureFlags.rateIntelligenceEnabled &&
        featureFlags.basicRateManagement &&
        featureFlags.advancedRateManagement
      );
    default:
      console.warn(`Feature group "${groupName}" not found`);
      return false;
  }
};

export default featureFlags;
