import axios from 'axios';

/**
 * Payment service for processing payments via Stripe
 */
class PaymentService {
  constructor() {
    this.apiBaseUrl = process.env.REACT_APP_API_BASE_URL || '';
    this.endpoints = {
      processPayment: '/api/payments/process',
      getPaymentIntent: '/api/payments/create-intent',
      getSavedPaymentMethods: '/api/payments/methods',
    };
  }

  /**
   * Processes a payment via Stripe
   * @param {Object} params - Payment parameters
   * @param {number} params.amount - Amount to charge (in dollars)
   * @param {string} params.currency - Currency code (default: 'usd')
   * @param {Object} params.paymentMethod - Payment method object from Stripe
   * @param {string} params.description - Payment description
   * @returns {Promise} - Payment result
   */
  async processPayment({ amount, currency = 'usd', paymentMethod, description }) {
    try {
      // Convert amount to cents for Stripe
      const amountInCents = Math.round(amount * 100);

      const response = await axios.post(`${this.apiBaseUrl}${this.endpoints.processPayment}`, {
        amount: amountInCents,
        currency,
        payment_method_id: paymentMethod.id,
        description,
        save_payment_method: paymentMethod.saveForFuture,
      });

      return {
        success: true,
        id: response.data.payment_intent_id,
        amount: amount,
        status: response.data.status,
      };
    } catch (error) {
      console.error('Payment processing error:', error);

      // Extract error message from Stripe
      let errorMessage = 'Payment processing failed';
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error.message;
      }

      return {
        success: false,
        error: errorMessage,
      };
    }
  }

  /**
   * Creates a payment intent for later confirmation
   * @param {Object} params - Parameters for creating payment intent
   * @param {number} params.amount - Amount (in dollars)
   * @param {string} params.currency - Currency code
   * @returns {Promise} - Payment intent client secret
   */
  async createPaymentIntent({ amount, currency = 'usd' }) {
    try {
      // Convert amount to cents for Stripe
      const amountInCents = Math.round(amount * 100);

      const response = await axios.post(`${this.apiBaseUrl}${this.endpoints.getPaymentIntent}`, {
        amount: amountInCents,
        currency,
      });

      return {
        clientSecret: response.data.client_secret,
        amount: amount,
      };
    } catch (error) {
      console.error('Error creating payment intent:', error);
      throw new Error('Failed to initialize payment');
    }
  }

  /**
   * Retrieves saved payment methods for a customer
   * @param {string} customerId - Customer ID
   * @returns {Promise} - List of payment methods
   */
  async getSavedPaymentMethods(customerId) {
    try {
      const response = await axios.get(
        `${this.apiBaseUrl}${this.endpoints.getSavedPaymentMethods}`,
        {
          params: { customer_id: customerId },
        }
      );

      return response.data.payment_methods;
    } catch (error) {
      console.error('Error retrieving saved payment methods:', error);
      return [];
    }
  }
}

export default PaymentService;
