import { serviceRegistry } from './serviceRegistry.js';
import { db } from '../config/firebase.js';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  Timestamp,
} from 'firebase/firestore';

/**
 * Property Management System Service
 * Handles interactions with the property management system
 */
class PMSService {
  constructor() {
    this.initialized = false;
    console.log('PMSService initialized');
  }

  /**
   * Initialize the service
   */
  initialize() {
    this.initialized = true;
    return this;
  }

  /**
   * Find a reservation by confirmation number or email
   * @param {string} searchTerm - Confirmation number or email
   * @returns {Promise<Object>} - Reservation data or null if not found
   */
  async findReservation(searchTerm) {
    try {
      let reservationsRef = collection(db, 'reservations');
      let q;

      // Check if searchTerm is an email address
      if (searchTerm.includes('@')) {
        q = query(reservationsRef, where('guestEmail', '==', searchTerm));
      } else {
        // Assume it's a confirmation code
        q = query(reservationsRef, where('confirmationCode', '==', searchTerm));
      }

      const snapshot = await getDocs(q);

      if (snapshot.empty) {
        return null;
      }

      // Get the first matching reservation
      const reservation = snapshot.docs[0];
      return {
        id: reservation.id,
        ...reservation.data(),
      };
    } catch (error) {
      console.error('Error finding reservation:', error);
      throw new Error(`Failed to find reservation: ${error.message}`);
    }
  }

  /**
   * Find a reservation by room number and guest name
   * @param {string} roomNumber - Room number
   * @param {string} lastName - Guest last name
   * @returns {Promise<Object>} - Reservation data or null if not found
   */
  async findReservationByRoomAndName(roomNumber, lastName) {
    try {
      let reservationsRef = collection(db, 'reservations');
      let q = query(
        reservationsRef,
        where('roomNumber', '==', roomNumber),
        where('lastName', '==', lastName.trim().toLowerCase()),
        where('status', 'in', ['confirmed', 'checked-in'])
      );

      const snapshot = await getDocs(q);

      if (snapshot.empty) {
        return null;
      }

      // Get the first matching reservation
      const reservation = snapshot.docs[0];
      return {
        id: reservation.id,
        ...reservation.data(),
      };
    } catch (error) {
      console.error('Error finding reservation by room and name:', error);
      throw new Error(`Failed to find reservation: ${error.message}`);
    }
  }

  /**
   * Process guest check-in
   * @param {string} reservationId - Reservation ID
   * @returns {Promise<Object>} - Updated reservation data
   */
  async checkInGuest(reservationId) {
    try {
      // Try API first if available
      if (serviceRegistry.hasService('apiService')) {
        const apiService = serviceRegistry.getService('apiService');
        try {
          const response = await apiService.post(`/reservations/${reservationId}/check-in`, {});
          if (response && response.success) {
            return response;
          }
        } catch (apiError) {
          console.warn('API check-in failed, falling back to direct update', apiError);
        }
      }

      // Fall back to direct Firestore update
      const reservationRef = doc(db, 'reservations', reservationId);
      const reservationDoc = await getDoc(reservationRef);

      if (!reservationDoc.exists()) {
        throw new Error('Reservation not found');
      }

      // Update reservation status
      await updateDoc(reservationRef, {
        status: 'checked-in',
        checkInTime: Timestamp.now(),
        updatedAt: Timestamp.now(),
      });

      // Generate access code if we have doorService
      let accessCode = null;
      if (serviceRegistry.hasService('doorService')) {
        const doorService = serviceRegistry.getService('doorService');
        const reservation = reservationDoc.data();
        accessCode = await doorService.generateCode(reservation.roomNumber);
      } else {
        // Generate a fallback random code
        accessCode = Math.floor(100000 + Math.random() * 900000).toString();
      }

      // Update with access code
      await updateDoc(reservationRef, {
        accessCode: accessCode,
      });

      // Return updated reservation with access code
      return {
        id: reservationId,
        accessCode,
        status: 'checked-in',
      };
    } catch (error) {
      console.error('Error checking in guest:', error);
      throw new Error(`Failed to check in guest: ${error.message}`);
    }
  }

  /**
   * Process guest check-out
   * @param {string} reservationId - Reservation ID
   * @returns {Promise<Object>} - Check-out result
   */
  async checkOutGuest(reservationId) {
    try {
      // Try API first if available
      if (serviceRegistry.hasService('apiService')) {
        const apiService = serviceRegistry.getService('apiService');
        try {
          const response = await apiService.post(`/reservations/${reservationId}/check-out`, {});
          if (response && response.success) {
            return response;
          }
        } catch (apiError) {
          console.warn('API check-out failed, falling back to direct update', apiError);
        }
      }

      // Fall back to direct Firestore update
      const reservationRef = doc(db, 'reservations', reservationId);
      const reservationDoc = await getDoc(reservationRef);

      if (!reservationDoc.exists()) {
        throw new Error('Reservation not found');
      }

      // Update reservation status
      await updateDoc(reservationRef, {
        status: 'checked-out',
        checkOutTime: Timestamp.now(),
        updatedAt: Timestamp.now(),
      });

      // Return success
      return {
        id: reservationId,
        status: 'checked-out',
        success: true,
      };
    } catch (error) {
      console.error('Error checking out guest:', error);
      throw new Error(`Failed to check out guest: ${error.message}`);
    }
  }

  /**
   * Get guest charges for check-out
   * @param {string} reservationId - Reservation ID
   * @returns {Promise<Array>} - List of charges
   */
  async getCharges(reservationId) {
    try {
      // First try to get charges from the API if available
      if (serviceRegistry.hasService('apiService')) {
        const apiService = serviceRegistry.getService('apiService');
        try {
          const response = await apiService.get(`/reservations/${reservationId}/charges`);
          if (response && Array.isArray(response.charges)) {
            return response.charges;
          }
        } catch (apiError) {
          console.warn('API charges fetch failed, falling back to direct query', apiError);
        }
      }

      // Fall back to direct Firestore query
      const chargesRef = collection(db, 'charges');
      const q = query(chargesRef, where('reservationId', '==', reservationId));

      const snapshot = await getDocs(q);
      const charges = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      return charges;
    } catch (error) {
      console.error('Error retrieving charges:', error);
      throw new Error(`Failed to retrieve charges: ${error.message}`);
    }
  }
}

// Create and export singleton instance
const pmsService = new PMSService();

// Register with service registry
serviceRegistry.register('pmsService', pmsService);

export default pmsService;
