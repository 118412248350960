/**
 * Application bootstrap setup
 * Handles initialization of core services before the app starts
 */
import serviceRegistry from './services/serviceRegistry';
import bootstrapServices, { areServicesInitialized } from './services/initializeServices';
import serviceLocator from './services/serviceLocator';

// Track initialization status
let initPromise = null;

/**
 * Initialize the application
 * This is called before the React app renders
 * @returns {Promise<boolean>} Whether initialization was successful
 */
export async function initializeApp() {
  // If initialization already started, return the existing promise
  if (initPromise) {
    return initPromise;
  }

  // Create a new initialization promise
  initPromise = (async () => {
    console.log('Bootstrapping application...');
    try {
      // Initialize all services
      const success = await bootstrapServices();

      // Ensure that critical services exist in both systems
      syncServiceRegistries();

      if (success && areServicesInitialized()) {
        console.log('Services initialized successfully');
      } else {
        console.error('Service initialization incomplete');
      }

      // Any additional bootstrap logic here

      return true;
    } catch (error) {
      console.error('Error during application bootstrap:', error);
      return false;
    }
  })();

  return initPromise;
}

/**
 * Sync services between service registry and service locator
 * This ensures that all services are accessible through both systems
 */
function syncServiceRegistries() {
  try {
    // Check for critical services in serviceRegistry and ensure they're also in serviceLocator
    if (serviceRegistry.hasService('authService') && !serviceLocator.hasService('authService')) {
      // Register with the locator
      const authService = serviceRegistry.getService('authService');
      serviceLocator.register('auth', () => authService);
      serviceLocator.registerAlias('authService', 'auth');
      console.log('Synced authService from registry to locator');
    }

    // Check for critical services in serviceLocator and ensure they're also in serviceRegistry
    if (serviceLocator.hasService('auth') && !serviceRegistry.hasService('authService')) {
      // Register with the registry
      const authService = serviceLocator.getService('auth');
      serviceRegistry.register('authService', authService);
      console.log('Synced auth from locator to registry');
    }

    // Sync RoomAvailabilityService
    if (serviceRegistry.hasService('roomAvailabilityService')) {
      const roomAvailabilityService = serviceRegistry.getService('roomAvailabilityService');
      console.log('RoomAvailabilityService is properly registered in serviceRegistry');
    } else {
      console.warn('RoomAvailabilityService is not registered in serviceRegistry');
      // Try to import and register it
      try {
        const RoomAvailabilityService = require('./services/RoomAvailabilityService').default;
        const service = new RoomAvailabilityService();
        serviceRegistry.register('roomAvailabilityService', service);
        console.log('RoomAvailabilityService registered retroactively');
      } catch (err) {
        console.error('Failed to register RoomAvailabilityService:', err);
      }
    }
  } catch (error) {
    console.error('Error during service registry synchronization:', error);
  }
}

// Export registry for direct access when needed
export { serviceRegistry };

// Auto-initialize when module is imported
initializeApp().catch(error => {
  console.error('Failed to bootstrap application:', error);
});
