import React from 'react';
import { Box, Grid, Card, CardContent, CardMedia, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import HotelIcon from '@mui/icons-material/Hotel';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import StoreIcon from '@mui/icons-material/Store';

const Dashboard = () => {
  const currentTime = new Date().toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  const currentDate = new Date().toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const quickActions = [
    {
      title: 'View Available Rooms',
      icon: <HotelIcon sx={{ fontSize: 60 }} />,
      path: '/kiosk/rooms',
      color: '#1a73e8',
      description: 'Browse available rooms and amenities',
    },
    {
      title: 'Check In',
      icon: <LoginIcon sx={{ fontSize: 60 }} />,
      path: '/kiosk/check-in',
      color: '#34a853',
      description: 'Complete the check-in process for your stay',
    },
    {
      title: 'Check Out',
      icon: <LogoutIcon sx={{ fontSize: 60 }} />,
      path: '/kiosk/check-out',
      color: '#ea4335',
      description: 'Complete the check-out process',
    },
    {
      title: 'Visit Motel Store',
      icon: <StoreIcon sx={{ fontSize: 60 }} />,
      path: '/kiosk/store',
      color: '#fbbc04',
      description: 'Purchase snacks, toiletries, and more',
    },
  ];

  return (
    <Box>
      <Box textAlign="center" mb={6}>
        <Typography variant="h2" gutterBottom>
          Welcome to West Bethel Motel
        </Typography>
        <Typography variant="h5" color="text.secondary">
          {currentDate} | {currentTime}
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {quickActions.map(action => (
          <Grid item xs={12} sm={6} md={3} key={action.title}>
            <Card
              className="touchscreen-card"
              component={Link}
              to={action.path}
              sx={{
                textDecoration: 'none',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 2,
                borderTop: `5px solid ${action.color}`,
              }}
            >
              <Box sx={{ color: action.color, m: 2 }}>{action.icon}</Box>
              <CardContent>
                <Typography variant="h5" component="h2" align="center" gutterBottom>
                  {action.title}
                </Typography>
                <Typography variant="body1" color="text.secondary" align="center">
                  {action.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box mt={8} textAlign="center">
        <Typography variant="h4" gutterBottom>
          Need Assistance?
        </Typography>
        <Typography variant="body1" paragraph>
          If you need help with any of our services, please contact the front desk by dialing 0 on
          the motel phone.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ mt: 2, py: 1.5, px: 4, fontSize: '1.2rem' }}
        >
          Call Front Desk
        </Button>
      </Box>
    </Box>
  );
};

export default Dashboard;
