/**
 * Storage Service
 * Handles local and remote storage operations
 */
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';

/**
 * Service for handling client-side storage operations
 */
class StorageService {
  /**
   * Create a new StorageService
   * @param {Object} loggingService - Logging service instance
   * @param {Object} databaseService - Database service instance
   */
  constructor(loggingService, databaseService) {
    this.loggingService = loggingService;
    this.databaseService = databaseService;
    this.storage = null;
    this.initialized = false;

    console.log('StorageService constructed');
  }

  /**
   * Initialize the storage service
   * @returns {Promise<void>}
   */
  async initialize() {
    try {
      // Initialize Firebase Storage
      this.storage = getStorage();

      // Set up localStorage with version check
      this.setupLocalStorage();

      this.initialized = true;
      this.loggingService.logInfo('StorageService', 'Storage service initialized successfully');
      return true;
    } catch (error) {
      this.loggingService.logError('StorageService', 'Failed to initialize storage service', error);
      throw error;
    }
  }

  /**
   * Setup local storage with version checking
   * @private
   */
  setupLocalStorage() {
    const APP_VERSION = process.env.REACT_APP_VERSION || '1.0.0';
    const storedVersion = localStorage.getItem('app_version');

    // Clear storage if version changed (for migration purposes)
    if (storedVersion && storedVersion !== APP_VERSION) {
      this.loggingService.logInfo(
        'StorageService',
        `App version changed from ${storedVersion} to ${APP_VERSION}, clearing local storage`
      );
      this.clearLocalStorage();
    }

    // Store current version
    localStorage.setItem('app_version', APP_VERSION);
  }

  /**
   * Save data to local storage
   * @param {string} key - Storage key
   * @param {any} data - Data to store (will be JSON serialized)
   */
  saveLocal(key, data) {
    try {
      const serialized = JSON.stringify(data);
      localStorage.setItem(key, serialized);
      return true;
    } catch (error) {
      this.loggingService.logError(
        'StorageService',
        `Error saving to local storage for key "${key}"`,
        error
      );
      return false;
    }
  }

  /**
   * Get data from local storage
   * @param {string} key - Storage key
   * @param {any} defaultValue - Default value if key not found or error occurs
   * @returns {any} Parsed data or default value
   */
  getLocal(key, defaultValue = null) {
    try {
      const serialized = localStorage.getItem(key);
      if (serialized === null) return defaultValue;
      return JSON.parse(serialized);
    } catch (error) {
      this.loggingService.logError(
        'StorageService',
        `Error getting from local storage for key "${key}"`,
        error
      );
      return defaultValue;
    }
  }

  /**
   * Remove item from local storage
   * @param {string} key - Storage key to remove
   */
  removeLocal(key) {
    try {
      localStorage.removeItem(key);
      return true;
    } catch (error) {
      this.loggingService.logError(
        'StorageService',
        `Error removing from local storage for key "${key}"`,
        error
      );
      return false;
    }
  }

  /**
   * Clear all application local storage
   */
  clearLocalStorage() {
    try {
      // Get app_version first to restore it later
      const appVersion = localStorage.getItem('app_version');

      // Clear everything
      localStorage.clear();

      // Restore version
      if (appVersion) {
        localStorage.setItem('app_version', appVersion);
      }

      return true;
    } catch (error) {
      this.loggingService.logError('StorageService', 'Error clearing local storage', error);
      return false;
    }
  }

  /**
   * Upload file to Firebase Storage
   * @param {File} file - File to upload
   * @param {string} path - Storage path (default: based on user ID and file name)
   * @returns {Promise<string>} Download URL of the uploaded file
   */
  async uploadFile(file, path = null) {
    if (!this.initialized || !this.storage) {
      throw new Error('StorageService not initialized');
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!path) {
        // Default path: users/{userId}/files/{timestamp}_{filename}
        const timestamp = new Date().getTime();
        const userId = user ? user.uid : 'anonymous';
        path = `users/${userId}/files/${timestamp}_${file.name}`;
      }

      const storageRef = ref(this.storage, path);
      const snapshot = await uploadBytes(storageRef, file);

      // Get download URL
      const downloadUrl = await getDownloadURL(snapshot.ref);

      return {
        url: downloadUrl,
        path: path,
        fileName: file.name,
        contentType: file.type,
        size: file.size,
      };
    } catch (error) {
      this.loggingService.logError('StorageService', 'Error uploading file', error);
      throw error;
    }
  }

  /**
   * Delete file from Firebase Storage
   * @param {string} path - Storage path of the file to delete
   * @returns {Promise<boolean>} True if deletion was successful
   */
  async deleteFile(path) {
    if (!this.initialized || !this.storage) {
      throw new Error('StorageService not initialized');
    }

    try {
      const storageRef = ref(this.storage, path);
      await deleteObject(storageRef);
      return true;
    } catch (error) {
      this.loggingService.logError('StorageService', 'Error deleting file', error);
      throw error;
    }
  }

  /**
   * Get download URL for a file
   * @param {string} path - Storage path
   * @returns {Promise<string>} Download URL
   */
  async getFileUrl(path) {
    if (!this.initialized || !this.storage) {
      throw new Error('StorageService not initialized');
    }

    try {
      const storageRef = ref(this.storage, path);
      return await getDownloadURL(storageRef);
    } catch (error) {
      this.loggingService.logError(
        'StorageService',
        `Error getting download URL for path ${path}`,
        error
      );
      throw error;
    }
  }
}

// Export the class directly
export default StorageService;
