// src/routes/TouchscreenRoutes.jsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import TouchscreenLayout from '../components/touchscreen/layout/TouchscreenLayout.jsx';
import NotFound from '../components/NotFound.jsx';
import Dashboard from '../components/touchscreen/pages/Dashboard.jsx';
import Bookings from '../components/touchscreen/pages/Bookings.jsx';
import Rooms from '../components/touchscreen/pages/Rooms.jsx';
import CheckIn from '../components/touchscreen/pages/CheckIn.jsx';
import CheckOut from '../components/touchscreen/pages/CheckOut.jsx';
import Payments from '../components/touchscreen/pages/Payments.jsx';
import Store from '../components/touchscreen/pages/Store.jsx';

const TouchscreenRoutes = () => {
  return (
    <Routes>
      <Route element={<TouchscreenLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="bookings" element={<Bookings />} />
        <Route path="rooms" element={<Rooms />} />
        <Route path="check-in" element={<CheckIn />} />
        <Route path="check-out" element={<CheckOut />} />
        <Route path="payments" element={<Payments />} />
        <Route path="store" element={<Store />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default TouchscreenRoutes;
