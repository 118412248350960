/**
 * Application Error Handler
 * Centralized error handling with specific handlers for different error types
 */

// Map of error codes to user-friendly messages
const ERROR_MESSAGES = {
  // Authentication errors
  'auth/user-not-found': 'User account not found. Please check your email or create an account.',
  'auth/wrong-password': 'Incorrect password. Please try again or reset your password.',
  'auth/email-already-in-use':
    'This email is already registered. Please sign in or use a different email.',
  'auth/weak-password':
    'Password is too weak. Please use at least 8 characters with letters and numbers.',
  'auth/invalid-email': 'Invalid email address format.',
  'auth/requires-recent-login':
    'This action requires you to re-authenticate. Please sign in again.',

  // Payment errors
  'payment/card-declined': 'Your card was declined. Please try a different payment method.',
  'payment/expired-card': 'Your card has expired. Please update your payment information.',
  'payment/insufficient-funds': 'Insufficient funds. Please try a different payment method.',
  'payment/processing-error': 'Error processing payment. Please try again later.',

  // Booking errors
  'booking/room-unavailable': 'This room is no longer available for the selected dates.',
  'booking/invalid-dates': 'Please select valid check-in and check-out dates.',
  'booking/max-guests-exceeded': 'The number of guests exceeds the room capacity.',
  'booking/duplicate-booking': 'You already have a booking for these dates.',

  // API errors
  'api/network-error': 'Network connection error. Please check your internet connection.',
  'api/timeout': 'Request timed out. Please try again.',
  'api/server-error': 'Server error. Our team has been notified and is working on a fix.',

  // Generic errors
  unknown: 'An unexpected error occurred. Please try again later.',
  'permission-denied': 'You do not have permission to perform this action.',
  'not-found': 'The requested resource was not found.',
};

export class ErrorHandler {
  /**
   * Handle authentication errors
   * @param {Error} error - The error object
   * @returns {string} User-friendly error message
   */
  static handleAuthError(error) {
    console.error('Authentication error:', error);

    // Extract error code from Firebase auth errors
    const errorCode = error?.code || 'unknown';
    const errorMessage =
      ERROR_MESSAGES[errorCode] || error?.message || 'Authentication error occurred.';

    // Log additional data for analytics
    this._logError('auth', errorCode, error);

    return {
      message: errorMessage,
      code: errorCode,
      original: error,
    };
  }

  /**
   * Handle payment errors
   * @param {Error} error - The error object
   * @returns {string} User-friendly error message
   */
  static handlePaymentError(error) {
    console.error('Payment error:', error);

    // Extract error code from payment provider errors
    const errorCode = error?.code || error?.type || 'unknown';
    const errorMessage =
      ERROR_MESSAGES[`payment/${errorCode}`] ||
      ERROR_MESSAGES[errorCode] ||
      error?.message ||
      'Error processing payment.';

    // Log additional data for analytics
    this._logError('payment', errorCode, error);

    return {
      message: errorMessage,
      code: errorCode,
      original: error,
    };
  }

  /**
   * Handle booking errors
   * @param {Error} error - The error object
   * @returns {string} User-friendly error message
   */
  static handleBookingError(error) {
    console.error('Booking error:', error);

    // Extract error code
    const errorCode = error?.code || 'unknown';
    const errorMessage =
      ERROR_MESSAGES[`booking/${errorCode}`] ||
      ERROR_MESSAGES[errorCode] ||
      error?.message ||
      'Error processing your booking request.';

    // Log additional data for analytics
    this._logError('booking', errorCode, error);

    return {
      message: errorMessage,
      code: errorCode,
      original: error,
    };
  }

  /**
   * Handle API errors
   * @param {Error|Response} error - The error object or response
   * @returns {Object} Error details with user-friendly message
   */
  static handleApiError(error) {
    console.error('API error:', error);

    // Handle fetch Response objects
    if (error && typeof error.json === 'function') {
      const statusCode = error.status;
      let errorMessage;

      switch (statusCode) {
        case 400:
          errorMessage = 'Invalid request. Please check your data and try again.';
          break;
        case 401:
          errorMessage = 'Authentication required. Please sign in again.';
          break;
        case 403:
          errorMessage = 'You do not have permission to perform this action.';
          break;
        case 404:
          errorMessage = 'The requested resource was not found.';
          break;
        case 429:
          errorMessage = 'Too many requests. Please try again later.';
          break;
        case 500:
        case 502:
        case 503:
          errorMessage = 'Server error. Our team has been notified and is working on a fix.';
          break;
        default:
          errorMessage = `API error occurred (${statusCode}). Please try again later.`;
      }

      // Log additional data for analytics
      this._logError('api', `http-${statusCode}`, error);

      return {
        message: errorMessage,
        code: `http-${statusCode}`,
        statusCode,
        original: error,
      };
    }

    // Handle standard Error objects
    const errorCode = error?.code || 'unknown';
    const errorMessage =
      ERROR_MESSAGES[`api/${errorCode}`] ||
      ERROR_MESSAGES[errorCode] ||
      error?.message ||
      'An error occurred while communicating with the server.';

    // Log additional data for analytics
    this._logError('api', errorCode, error);

    return {
      message: errorMessage,
      code: errorCode,
      original: error,
    };
  }

  /**
   * Handle Firebase errors
   * @param {Error} error - The error object
   * @returns {Error} Processed error with consistent format
   */
  static handleFirebaseError(error) {
    // If it's already a custom error, return it
    if (error instanceof CustomError) {
      return error;
    }

    // Handle Firebase specific errors
    if (error.code) {
      switch (error.code) {
        case 'permission-denied':
          return new CustomError(
            'Permission denied',
            'You do not have permission to perform this action',
            'AUTH_ERROR'
          );
        case 'not-found':
          return new CustomError(
            'Resource not found',
            'The requested resource could not be found',
            'NOT_FOUND_ERROR'
          );
        case 'already-exists':
          return new CustomError(
            'Resource exists',
            'A resource with this identifier already exists',
            'DUPLICATE_ERROR'
          );
        case 'invalid-argument':
          return new CustomError(
            'Invalid argument',
            'One or more arguments are invalid',
            'VALIDATION_ERROR'
          );
        case 'unavailable':
          return new CustomError(
            'Service unavailable',
            'The service is currently unavailable',
            'SERVICE_ERROR'
          );
        default:
          return new CustomError(
            'Firebase error',
            error.message || 'An unexpected error occurred',
            'FIREBASE_ERROR'
          );
      }
    }

    // Handle network errors
    if (error.message?.includes('network')) {
      return new CustomError(
        'Network error',
        'Unable to connect to the server. Please check your internet connection.',
        'NETWORK_ERROR'
      );
    }

    // Handle unknown errors
    return new CustomError(
      'Unknown error',
      error.message || 'An unexpected error occurred',
      'UNKNOWN_ERROR'
    );
  }

  /**
   * Handle generic errors
   * @param {Error} error - Any error object
   * @returns {Object} Error details with user-friendly message
   */
  static handleGenericError(error) {
    console.error('Error:', error);

    const errorMessage = error?.message || 'An unexpected error occurred.';

    // Log additional data for analytics
    this._logError('generic', 'unknown', error);

    return {
      message: errorMessage,
      code: 'unknown',
      original: error,
    };
  }

  /**
   * Log error for analytics
   * @private
   * @param {string} category - Error category
   * @param {string} code - Error code
   * @param {Error} error - Original error
   */
  static _logError(category, code, error) {
    // In a real app, this would send to an analytics service
    if (process.env.NODE_ENV !== 'production') {
      console.debug('Error logged:', { category, code, error });
    }

    try {
      // Add analytics or error logging service here
      // Example: Analytics.logError(category, code, error.message);
    } catch (loggingError) {
      console.error('Error while logging error:', loggingError);
    }
  }
}

/**
 * Custom Error Class
 * Provides consistent error format across the application
 */
class CustomError extends Error {
  constructor(title, message, code, details = null) {
    super(message);
    this.name = 'CustomError';
    this.title = title;
    this.code = code;
    this.details = details;
    this.timestamp = new Date().toISOString();
  }

  /**
   * Convert error to JSON format
   * @returns {Object} JSON representation of the error
   */
  toJSON() {
    return {
      name: this.name,
      title: this.title,
      message: this.message,
      code: this.code,
      details: this.details,
      timestamp: this.timestamp,
    };
  }
}

export default ErrorHandler;
