/**
 * DatabaseService
 * Core service for database operations
 */

import { getFirestore, enableMultiTabIndexedDbPersistence } from 'firebase/firestore';
import { IDatabaseService } from '../interfaces';

/**
 * Core database service implementation
 */
class DatabaseService {
  /**
   * Create a new DatabaseService
   * @param {Object} loggingService - Logging service instance
   */
  constructor(loggingService) {
    this.loggingService = loggingService;
    this.db = null;
    this.initialized = false;
    
    console.log('DatabaseService constructed');
  }

  /**
   * Initialize the database service
   * @returns {Promise<boolean>} Success status
   */
  async initialize() {
    try {
      this.loggingService.logInfo('DatabaseService', 'Initializing database service');
      
      // Initialize Firestore
      this.db = getFirestore();
      
      // Enable offline persistence if in browser environment
      if (typeof window !== 'undefined') {
        try {
          await enableMultiTabIndexedDbPersistence(this.db);
          this.loggingService.logInfo('DatabaseService', 'Multi-tab persistence enabled');
        } catch (error) {
          // This can fail if already initialized in another tab
          this.loggingService.logWarning('DatabaseService', 'Failed to enable persistence', {
            error: error.message,
            code: error.code
          });
        }
      }
      
      this.initialized = true;
      this.loggingService.logInfo('DatabaseService', 'Database service initialized successfully');
      return true;
    } catch (error) {
      this.loggingService.logError('DatabaseService', 'Failed to initialize database service', error);
      throw error;
    }
  }

  /**
   * Check if service is initialized
   * @returns {boolean} Initialization status
   */
  isInitialized() {
    return this.initialized;
  }

  /**
   * Get the Firestore database instance
   * @returns {Object} Firestore database instance
   */
  getDb() {
    if (!this.initialized) {
      this.loggingService.logWarning('DatabaseService', 'Getting database before initialization');
    }
    return this.db;
  }

  /**
   * Execute a database operation with error handling
   * @param {string} operation - Operation name for logging
   * @param {Function} action - Async function to execute
   * @returns {Promise<any>} Operation result
   */
  async executeOperation(operation, action) {
    try {
      if (!this.initialized) {
        await this.initialize();
      }
      
      this.loggingService.logDebug('DatabaseService', `Executing operation: ${operation}`);
      const result = await action(this.db);
      return result;
    } catch (error) {
      this.loggingService.logError('DatabaseService', `Error in operation ${operation}`, error);
      throw error;
    }
  }
}

// Mark this class as implementing IDatabaseService
DatabaseService.prototype[IDatabaseService] = true;

// Export the class directly
export default DatabaseService;
