/**
 * Application configuration
 * Centralizes all configuration values and environment variables
 */

// Default configuration values
const appConfig = {
  // API endpoints
  apiUrl: process.env.REACT_APP_API_URL || '/api',
  
  // Firebase configuration
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  },
  
  // Service URLs
  services: {
    room: process.env.REACT_APP_ROOM_SERVICE_URL || '/rooms',
    maintenance: process.env.REACT_APP_MAINTENANCE_SERVICE_URL || '/maintenance',
    booking: process.env.REACT_APP_BOOKING_SERVICE_URL || '/bookings',
  },
  
  // External booking configuration
  externalBooking: {
    enabled: true, // Force enabled for development
    baseUrl: process.env.REACT_APP_EXTERNAL_BOOKING_BASE_URL || 'https://bookings13.rmscloud.com/Rates/Index/DF3B9D9C7A1469D9/90',
    provider: process.env.REACT_APP_EXTERNAL_BOOKING_PROVIDER || 'RMS Cloud'
  },
  
  // Feature flags
  features: {
    analytics: process.env.REACT_APP_ENABLE_ANALYTICS === 'true',
    mobileApp: process.env.REACT_APP_ENABLE_MOBILE_APP === 'true',
    touchscreen: process.env.REACT_APP_ENABLE_TOUCHSCREEN === 'true'
  }
};

export default appConfig;