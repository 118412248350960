/**
 * RoomRatesService
 * Service for managing room rates and price calculation
 *
 * This is an adapter file that follows the new service architecture
 * It imports the legacy RoomRatesService from the rates subdirectory
 * and adapts it to work with the new dependency injection pattern
 */

import LegacyRoomRatesService from './rates/RoomRatesService.js';

/**
 * Enhanced RoomRatesService with proper dependency injection
 */
class RoomRatesService {
  /**
   * Create a new RoomRatesService with dependencies
   * @param {Object} loggingService - Logging service instance
   * @param {Object} databaseService - Database service instance
   * @param {Object} apiService - API service instance
   */
  constructor(loggingService, databaseService, apiService) {
    this.loggingService = loggingService;
    this.databaseService = databaseService;
    this.apiService = apiService;
    // Create an instance of the legacy service with the necessary dependencies
    this.legacyService = new LegacyRoomRatesService(loggingService, databaseService, apiService);
    this.initialized = false;

    console.log('RoomRatesService adapter constructed');
  }

  /**
   * Initialize the room rates service
   * @returns {Promise<boolean>} Success status
   */
  async initialize() {
    try {
      this.loggingService.logInfo('RoomRatesService', 'Initializing room rates service');

      // Initialize the legacy service
      await this.legacyService.initialize();

      this.initialized = true;
      this.loggingService.logInfo(
        'RoomRatesService',
        'Room rates service initialized successfully'
      );
      return true;
    } catch (error) {
      this.loggingService.logError(
        'RoomRatesService',
        'Failed to initialize room rates service',
        error
      );
      throw error;
    }
  }

  /**
   * Calculate room rates for the specified period
   * @param {string} roomId - Room ID
   * @param {Date} checkInDate - Check-in date
   * @param {Date} checkOutDate - Check-out date
   * @param {number} guestCount - Number of guests
   * @returns {Promise<Object>} Rate calculation details
   */
  async calculateRates(roomId, checkInDate, checkOutDate, guestCount) {
    return this.legacyService.calculateRates(roomId, checkInDate, checkOutDate, guestCount);
  }

  /**
   * Get base rate for a room
   * @param {string} roomId - Room ID
   * @returns {Promise<number>} Base rate
   */
  async getBaseRate(roomId) {
    return this.legacyService.getBaseRate(roomId);
  }

  /**
   * Get seasonal rates
   * @returns {Promise<Array>} Seasonal rates
   */
  async getSeasonalRates() {
    return this.legacyService.getSeasonalRates();
  }

  /**
   * Get special rates (promotions)
   * @returns {Promise<Array>} Special rates
   */
  async getSpecialRates() {
    return this.legacyService.getSpecialRates();
  }

  /**
   * Check if a promotion code is valid
   * @param {string} code - Promotion code
   * @returns {Promise<Object>} Promotion details if valid
   */
  async validatePromotionCode(code) {
    return this.legacyService.validatePromotionCode(code);
  }
}

// Export the class directly for the new architecture
export default RoomRatesService;
