import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Typography, Paper, Link } from '@mui/material';
import { addDays, startOfDay } from 'date-fns';

const BookingTestLink = () => {
  const [loading, setLoading] = useState(true);
  const [bookingUrl, setBookingUrl] = useState('');

  useEffect(() => {
    generateURL();
  }, []);

  const generateURL = () => {
    try {
      // Set today as check-in date and tomorrow as check-out date
      const today = startOfDay(new Date());
      const tomorrow = addDays(today, 1);

      // Format dates for RMS URL
      const checkIn = today.toISOString().split('T')[0];
      const checkOut = tomorrow.toISOString().split('T')[0];

      // Create RMS booking URL with 2 adults
      const baseUrl = 'https://bookings13.rmscloud.com/Rates/Index/DF3B9D9C7A1469D9/90';
      // Format timestamp as yyyyMMddHHmmss
      const formattedTimestamp = today.toISOString().replace(/[-:T.]/g, '').substring(0, 14);
      const queryParams = new URLSearchParams({
        A: checkIn,
        D: checkOut,
        Dn: formattedTimestamp,
        Rt: 0,
        Ad: 2, // 2 adults
        Ch: 0, // 0 children
      });

      const url = `${baseUrl}?${queryParams.toString()}#!/rate`;
      console.log('Test booking URL:', url);
      setBookingUrl(url);
      setLoading(false);
    } catch (err) {
      console.error('Error generating URL:', err);
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Booking URL Test
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <Paper sx={{ p: 3, mt: 2 }}>
          <Typography variant="h6" gutterBottom>
            Generated Booking URL:
          </Typography>

          <Box
            sx={{
              p: 2,
              bgcolor: '#f5f5f5',
              borderRadius: 1,
              overflowX: 'auto',
              wordBreak: 'break-all',
            }}
          >
            <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
              {bookingUrl}
            </Typography>
          </Box>

          <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              href={bookingUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Open RMS Booking Link
            </Button>

            <Typography>
              Direct link:{' '}
              <Link href={bookingUrl} target="_blank" rel="noopener noreferrer">
                Click here
              </Link>
            </Typography>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default BookingTestLink;
