import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Button,
  Tabs,
  Tab,
  Chip,
} from '@mui/material';
import WifiIcon from '@mui/icons-material/Wifi';
import TvIcon from '@mui/icons-material/Tv';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import AccessibleIcon from '@mui/icons-material/Accessible';
import serviceLocator from '../../../services/serviceLocator.js';

const Rooms = () => {
  const [tabValue, setTabValue] = useState(0);

  const roomCategories = [
    { value: 0, label: 'All Rooms' },
    { value: 1, label: 'Standard' },
    { value: 2, label: 'Deluxe' },
    { value: 3, label: 'Suites' },
  ];

  const rooms = [
    {
      id: 1,
      name: 'Standard Queen',
      category: 'Standard',
      description:
        'Comfortable room with a queen-sized bed, perfect for solo travelers or couples.',
      image:
        'https://images.unsplash.com/photo-1618773928121-c32242e63f39?auto=format&fit=crop&w=600&q=80',
      price: 99,
      capacity: 2,
      size: '250 sq ft',
      amenities: ['Free WiFi', 'Cable TV', 'Air Conditioning', 'Coffee Maker'],
    },
    {
      id: 2,
      name: 'Standard Twin',
      category: 'Standard',
      description: 'Room with two twin beds, ideal for friends or colleagues traveling together.',
      image:
        'https://images.unsplash.com/photo-1590490360182-c33d57733427?auto=format&fit=crop&w=600&q=80',
      price: 109,
      capacity: 2,
      size: '260 sq ft',
      amenities: ['Free WiFi', 'Cable TV', 'Air Conditioning', 'Coffee Maker'],
    },
    {
      id: 3,
      name: 'Deluxe King',
      category: 'Deluxe',
      description:
        'Spacious room featuring a king-sized bed and enhanced amenities for added comfort.',
      image:
        'https://images.unsplash.com/photo-1566665797739-1674de7a421a?auto=format&fit=crop&w=600&q=80',
      price: 129,
      capacity: 2,
      size: '300 sq ft',
      amenities: ['Free WiFi', 'Cable TV', 'Air Conditioning', 'Coffee Maker', 'Mini Fridge'],
    },
    {
      id: 4,
      name: 'Family Suite',
      category: 'Suites',
      description:
        'Spacious suite with a separate living area and two queen beds, perfect for families.',
      image:
        'https://images.unsplash.com/photo-1578683010236-d716f9a3f461?auto=format&fit=crop&w=600&q=80',
      price: 159,
      capacity: 4,
      size: '450 sq ft',
      amenities: [
        'Free WiFi',
        'Cable TV',
        'Air Conditioning',
        'Coffee Maker',
        'Mini Fridge',
        'Accessible',
      ],
    },
    {
      id: 5,
      name: 'Mountain View Suite',
      category: 'Suites',
      description:
        'Luxurious suite with stunning mountain views, king-sized bed, and separate living area.',
      image:
        'https://images.unsplash.com/photo-1602002418082-dd4a8f7a3453?auto=format&fit=crop&w=600&q=80',
      price: 179,
      capacity: 2,
      size: '500 sq ft',
      amenities: ['Free WiFi', 'Cable TV', 'Air Conditioning', 'Coffee Maker', 'Mini Fridge'],
    },
  ];

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getAmenityIcon = amenity => {
    switch (amenity) {
      case 'Free WiFi':
        return <WifiIcon fontSize="small" />;
      case 'Cable TV':
        return <TvIcon fontSize="small" />;
      case 'Air Conditioning':
        return <AcUnitIcon fontSize="small" />;
      case 'Coffee Maker':
        return <LocalCafeIcon fontSize="small" />;
      case 'Accessible':
        return <AccessibleIcon fontSize="small" />;
      default:
        return null;
    }
  };

  const filteredRooms =
    tabValue === 0 ? rooms : rooms.filter(room => room.category === roomCategories[tabValue].label);

  return (
    <Box>
      <Typography variant="h2" gutterBottom>
        Our Rooms
      </Typography>
      <Typography variant="body1" paragraph sx={{ mb: 4 }}>
        Experience comfort and relaxation in our well-appointed rooms and suites.
      </Typography>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
        <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
          {roomCategories.map(category => (
            <Tab key={category.value} label={category.label} />
          ))}
        </Tabs>
      </Box>

      <Grid container spacing={4}>
        {filteredRooms.map(room => (
          <Grid item xs={12} md={6} key={room.id}>
            <Card sx={{ display: { xs: 'block', sm: 'flex' }, height: '100%' }}>
              <CardMedia
                component="img"
                sx={{
                  width: { xs: '100%', sm: 200 },
                  height: { xs: 200, sm: 'auto' },
                }}
                image={room.image}
                alt={room.name}
              />
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                  }}
                >
                  <Typography component="div" variant="h5">
                    {room.name}
                  </Typography>
                  <Typography variant="h6" color="primary" component="div">
                    ${room.price}
                    <Typography variant="caption" display="block" color="text.secondary">
                      per night
                    </Typography>
                  </Typography>
                </Box>

                <Typography variant="subtitle1" color="text.secondary" component="div" gutterBottom>
                  {room.category} • {room.capacity} Guests • {room.size}
                </Typography>

                <Typography variant="body2" paragraph>
                  {room.description}
                </Typography>

                <Box sx={{ mb: 2 }}>
                  {room.amenities.map(amenity => (
                    <Chip
                      key={amenity}
                      icon={getAmenityIcon(amenity)}
                      label={amenity}
                      size="small"
                      sx={{ mr: 0.5, mb: 0.5 }}
                    />
                  ))}
                </Box>

                <Button variant="contained" color="primary">
                  View Details
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Rooms;
