import React from 'react';
import { Box, Container, Grid, Typography, Link, Divider } from '@mui/material';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: 'primary.dark',
        color: 'white',
        py: 6,
        mt: 'auto',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom component="h2">
              West Bethel Motel
            </Typography>
            <Typography variant="body2">
              764 W. Bethel Rd
              <br />
              Bethel, ME 04217
              <br />
              Phone: (207) 835-3575
              <br />
              Email: booking@westbethelmotel.com
              <br />
              Booking: booking@westbethelmotel.com
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom component="h2">
              Quick Links
            </Typography>
            <Link href="/rooms" color="inherit" display="block" sx={{ mb: 1 }}>
              Rooms & Suites
            </Link>
            <Link href="/services" color="inherit" display="block" sx={{ mb: 1 }}>
              Services
            </Link>
            <Link href="/amenities" color="inherit" display="block" sx={{ mb: 1 }}>
              Amenities
            </Link>
            <Link href="/gallery" color="inherit" display="block" sx={{ mb: 1 }}>
              Gallery
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom component="h2">
              About
            </Typography>
            <Link href="/about" color="inherit" display="block" sx={{ mb: 1 }}>
              About Us
            </Link>
            <Link href="/contact" color="inherit" display="block" sx={{ mb: 1 }}>
              Contact Us
            </Link>
            <Link href="/careers" color="inherit" display="block" sx={{ mb: 1 }}>
              Careers
            </Link>
            <Link href="/faq" color="inherit" display="block" sx={{ mb: 1 }}>
              FAQ
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom component="h2">
              Legal
            </Typography>
            <Link href="/terms" color="inherit" display="block" sx={{ mb: 1 }}>
              Terms & Conditions
            </Link>
            <Link href="/privacy" color="inherit" display="block" sx={{ mb: 1 }}>
              Privacy Policy
            </Link>
            <Link href="/cookies" color="inherit" display="block" sx={{ mb: 1 }}>
              Cookie Policy
            </Link>
            <Link href="/accessibility" color="inherit" display="block" sx={{ mb: 1 }}>
              Accessibility
            </Link>
          </Grid>
        </Grid>

        <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.2)', my: 4 }} />

        <Typography variant="body2" align="center">
          © {new Date().getFullYear()} West Bethel Motel. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
