/**
 * Room Rates Service
 * Service for fetching hotel room rates with Google Hotel Advertising compliance
 */

import DatabaseService from '../core/DatabaseService';
import LoggingService from '../core/LoggingService';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { serviceRegistry } from '../serviceRegistry';

/**
 * Room Rates Service implementation
 */
class RoomRatesService {
  /**
   * Create a new RoomRatesService with dependencies
   * @param {Object} loggingService - Logging service
   * @param {Object} databaseService - Database service
   * @param {Object} apiService - API service
   */
  constructor(loggingService, databaseService, apiService) {
    this.loggingService = loggingService;
    this.databaseService = databaseService;
    this.apiService = apiService;
    this.initialized = false;
    this.collectionName = 'roomRates';
  }

  async initialize() {
    if (this.initialized) {
      return this;
    }

    try {
      if (this.databaseService) {
        await this.databaseService.initialize();
      }
      this.initialized = true;
      this.loggingService.logInfo('RoomRatesService', 'RoomRatesService initialized');
      return this;
    } catch (error) {
      this.loggingService.logError('RoomRatesService', 'Failed to initialize RoomRatesService', error);
      throw error;
    }
  }

  /**
   * Get rates for a specific room type
   * @param {string} roomTypeId - Room type ID
   * @returns {Promise<Array>} Array of rates for the room type
   */
  async getRatesForRoomType(roomTypeId) {
    if (!this.initialized) {
      throw new Error('RoomRatesService not initialized');
    }

    try {
      const db = this.databaseService ? this.databaseService.getDb() : null;
      if (!db) {
        throw new Error('Database service not available');
      }
      
      const ratesRef = collection(db, this.collectionName);
      const q = query(ratesRef, where('roomTypeId', '==', roomTypeId));
      const querySnapshot = await getDocs(q);

      const rates = [];
      querySnapshot.forEach(doc => {
        rates.push({ id: doc.id, ...doc.data() });
      });

      this.loggingService.logDebug('RoomRatesService', `Retrieved ${rates.length} rates for room type ${roomTypeId}`);
      return rates;
    } catch (error) {
      this.loggingService.logError('RoomRatesService', `Error getting rates for room type ${roomTypeId}`, error);
      throw error;
    }
  }

  /**
   * Get rate for a specific room type and season
   * @param {string} roomTypeId - Room type ID
   * @param {string} season - Season (e.g., 'peak', 'off-peak')
   * @returns {Promise<Object>} Rate data
   */
  async getRateForSeason(roomTypeId, season) {
    if (!this.initialized) {
      throw new Error('RoomRatesService not initialized');
    }

    try {
      const db = this.databaseService ? this.databaseService.getDb() : null;
      if (!db) {
        throw new Error('Database service not available');
      }
      
      const ratesRef = collection(db, this.collectionName);
      const q = query(
        ratesRef,
        where('roomTypeId', '==', roomTypeId),
        where('season', '==', season)
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        throw new Error(`No rate found for room type ${roomTypeId} in season ${season}`);
      }

      const rateDoc = querySnapshot.docs[0];
      return { id: rateDoc.id, ...rateDoc.data() };
    } catch (error) {
      this.loggingService.logError(
        'RoomRatesService',
        `Error getting rate for room type ${roomTypeId} in season ${season}`,
        error
      );
      throw error;
    }
  }

  /**
   * Calculate total rate for a stay
   * @param {string} roomTypeId - Room type ID
   * @param {Date} checkInDate - Check-in date
   * @param {Date} checkOutDate - Check-out date
   * @returns {Promise<Object>} Total rate calculation
   */
  async calculateTotalRate(roomTypeId, checkInDate, checkOutDate) {
    if (!this.initialized) {
      throw new Error('RoomRatesService not initialized');
    }

    try {
      const rates = await this.getRatesForRoomType(roomTypeId);
      const nights = Math.ceil((checkOutDate - checkInDate) / (1000 * 60 * 60 * 24));

      // Calculate total based on rates and number of nights
      const total = rates.reduce((sum, rate) => {
        return sum + rate.baseRate * nights;
      }, 0);

      this.loggingService.logDebug('RoomRatesService', `Calculated total rate of ${total} for ${nights} nights`);
      return {
        total,
        nights,
        rates,
      };
    } catch (error) {
      this.loggingService.logError('RoomRatesService', 'Error calculating total rate', error);
      throw error;
    }
  }
}

// Export the class directly for the service factory to instantiate
export default RoomRatesService;
