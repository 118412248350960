import React, { lazy, Suspense } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation, Outlet } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout.jsx';
import AdminLayout from '../layouts/AdminLayout.jsx';
import AuthGuard from '../guards/AuthGuard.jsx';
import AdminGuard from '../guards/AdminGuard.js';
import LoadingSpinner from '../components/common/LoadingSpinner.jsx';
import AdminTest from '../pages/AdminTest.jsx';
import NotFound from '../components/NotFound.jsx';
import TouchscreenRoutes from './TouchscreenRoutes.jsx';
import { isFeatureEnabled } from '../config/featureFlags.js';
import ComingSoon from '../components/ComingSoon.jsx';
import BookingExternalLink from '../components/booking/BookingExternalLink';
import BookingTestLink from '../components/booking/BookingTestLink';

// Lazy loaded pages
const Home = lazy(() => import('../pages/Home'));
const About = lazy(() => import('../pages/About'));
const Contact = lazy(() => import('../pages/Contact'));
const Reservation = lazy(() => import('../pages/Reservation'));
const AreaGuide = lazy(() => import('../pages/AreaGuide'));
const Rooms = lazy(() => import('../pages/Rooms'));
const Location = lazy(() => import('../pages/Location'));
const LoginPage = lazy(() => import('../pages/auth/LoginPage'));
const ProfilePage = lazy(() => import('../pages/ProfilePage'));
// const Booking = lazy(() => import('../pages/Booking'));
const Dashboard = lazy(() => import('../pages/admin/Dashboard'));
const RoomManagement = lazy(() => import('../pages/admin/RoomManagement'));
const GuestManagement = lazy(() => import('../pages/admin/GuestManagement'));

// This wrapps the suspense loading and becomes the root element
// that RouterProvider renders
const AppRoutes = () => {
  return (
    <Suspense fallback={<LoadingSpinner message="Loading page..." />}>
      <Routes>
        <Route element={<MainLayout />}>
          {/* Public Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/rooms" element={<Rooms />} />
          <Route path="/booking" element={<BookingExternalLink />} />
          <Route path="/booking-test" element={<BookingTestLink />} />
          <Route
            path="/reservation"
            element={isFeatureEnabled('bookingEnabled') ? <Reservation /> : <ComingSoon />}
          />
          <Route path="/location" element={<Location />} />
          <Route path="/area-guide" element={<AreaGuide />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/auth/login" element={<LoginPage />} />

          {/* Protected Routes - Require user authentication */}
          <Route element={<AuthGuard />}>
            <Route path="/profile" element={<ProfilePage />} />
          </Route>
        </Route>

        {/* Admin Routes - Restricted to staff with admin privileges */}
        <Route
          path="/admin"
          element={
            <AdminGuard>
              <AdminLayout />
            </AdminGuard>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="test" element={<AdminTest />} />
          <Route path="rooms" element={<RoomManagement />} />
          <Route path="guests" element={<GuestManagement />} />
        </Route>

        {/* Touchscreen Kiosk Routes - Only enabled when feature flag is on */}
        {isFeatureEnabled('touchscreenEnabled') && (
          <Route path="/kiosk/*" element={<TouchscreenRoutes />} />
        )}

        {/* Fallback for 404 Not Found */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
