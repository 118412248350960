import axios from 'axios';
import { collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { db } from '../config/firebase';

/**
 * Service for handling booking operations
 */
class BookingService {
  constructor() {
    this.apiBaseUrl = process.env.REACT_APP_API_BASE_URL || '';
    this.endpoints = {
      createBooking: '/api/bookings/create',
      getBooking: '/api/bookings/get',
      updateBooking: '/api/bookings/update',
      checkAvailability: '/api/bookings/check-availability',
      requestModification: '/api/bookings/request-modification',
    };
    this.initialized = false;
  }

  /**
   * Initialize the booking service
   */
  async initialize() {
    if (this.initialized) {
      return;
    }

    try {
      // Any initialization tasks go here
      this.initialized = true;
    } catch (error) {
      console.error('Failed to initialize booking service:', error);
      throw new Error('Booking service initialization failed');
    }
  }

  /**
   * Create a new booking
   * @param {Object} bookingData - Booking data
   * @returns {Promise} - Created booking
   */
  async createBooking(bookingData) {
    try {
      if (!this.initialized) {
        await this.initialize();
      }

      const response = await axios.post(
        `${this.apiBaseUrl}${this.endpoints.createBooking}`,
        bookingData
      );
      return response.data;
    } catch (error) {
      console.error('Error creating booking:', error);

      let errorMessage = 'Failed to create booking';
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      throw new Error(errorMessage);
    }
  }

  /**
   * Get a booking by ID
   * @param {string} bookingId - Booking ID
   * @returns {Promise} - Booking data
   */
  async getBookingById(bookingId) {
    try {
      if (!this.initialized) {
        await this.initialize();
      }

      const response = await axios.get(
        `${this.apiBaseUrl}${this.endpoints.getBooking}/${bookingId}`
      );
      return response.data;
    } catch (error) {
      console.error('Error retrieving booking:', error);

      if (error.response && error.response.status === 404) {
        return null;
      }

      throw new Error('Failed to retrieve booking');
    }
  }

  /**
   * Update an existing booking
   * @param {string} bookingId - Booking ID
   * @param {Object} bookingData - Updated booking data
   * @returns {Promise} - Updated booking
   */
  async updateBooking(bookingId, bookingData) {
    try {
      if (!this.initialized) {
        await this.initialize();
      }

      const response = await axios.put(
        `${this.apiBaseUrl}${this.endpoints.updateBooking}/${bookingId}`,
        bookingData
      );
      return response.data;
    } catch (error) {
      console.error('Error updating booking:', error);

      let errorMessage = 'Failed to update booking';
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      throw new Error(errorMessage);
    }
  }

  /**
   * Check availability for a service/room on specific dates
   * @param {Object} params - Search parameters
   * @param {string} params.serviceId - Service/Room ID
   * @param {Date} params.startDate - Check-in date
   * @param {Date} params.endDate - Check-out date
   * @param {number} params.guests - Number of guests
   * @param {string} [params.bookingId] - Current booking ID (for modifications)
   * @returns {Promise<boolean>} - Whether the service is available
   */
  async checkAvailability({ serviceId, startDate, endDate, guests, bookingId = null }) {
    try {
      if (!this.initialized) {
        await this.initialize();
      }

      const response = await axios.post(`${this.apiBaseUrl}${this.endpoints.checkAvailability}`, {
        serviceId,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        guests,
        bookingId,
      });

      return response.data.available;
    } catch (error) {
      console.error('Error checking availability:', error);
      throw new Error('Failed to check availability');
    }
  }

  /**
   * Request a modification to an existing booking
   * @param {string} bookingId - Booking ID
   * @param {Object} requestData - Modification request data
   * @returns {Promise} - Modification request result
   */
  async requestModification(bookingId, requestData) {
    try {
      if (!this.initialized) {
        await this.initialize();
      }

      const response = await axios.post(
        `${this.apiBaseUrl}${this.endpoints.requestModification}/${bookingId}`,
        requestData
      );
      return response.data;
    } catch (error) {
      console.error('Error requesting modification:', error);
      throw new Error('Failed to submit modification request');
    }
  }
}

/**
 * @method getUserBookings
 * @description Retrieves a user's bookings with filtering options
 * @preserveFeature
 *
 * @param {string} userId - The user ID to retrieve bookings for
 * @param {Object} options - Query options
 * @param {number} [options.limit=10] - Maximum number of bookings to return
 * @param {string} [options.status] - Filter by booking status (upcoming, past, cancelled)
 * @param {string} [options.sortBy='date'] - Field to sort by
 * @param {string} [options.sortOrder='asc'] - Sort order (asc or desc)
 * @returns {Promise<Array>} - List of booking objects
 */
async function getUserBookings(userId, options = {}) {
  try {
    const { limit: queryLimit = 10, status, sortBy = 'date', sortOrder = 'asc' } = options;

    // Base query - get bookings for this user
    let bookingsQuery = query(collection(db, 'bookings'), where('userId', '==', userId));

    // Apply status filter if specified
    if (status) {
      // For 'upcoming' status, get bookings with date in the future
      if (status === 'upcoming') {
        bookingsQuery = query(
          bookingsQuery,
          where('date', '>=', new Date().toISOString()),
          where('status', '!=', 'cancelled')
        );
      }
      // For 'past' status, get bookings with date in the past
      else if (status === 'past') {
        bookingsQuery = query(bookingsQuery, where('date', '<', new Date().toISOString()));
      }
      // Otherwise filter by the specified status
      else {
        bookingsQuery = query(bookingsQuery, where('status', '==', status));
      }
    }

    // Apply sorting
    bookingsQuery = query(bookingsQuery, orderBy(sortBy, sortOrder));

    // Apply limit if specified
    if (queryLimit) {
      bookingsQuery = query(bookingsQuery, limit(queryLimit));
    }

    // Execute query
    const querySnapshot = await getDocs(bookingsQuery);
    const bookings = [];

    // Process results
    querySnapshot.forEach(doc => {
      bookings.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    return bookings;
  } catch (error) {
    console.error('Error fetching user bookings:', error);
    throw new Error('Failed to retrieve bookings');
  }
}

export default BookingService;
