import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Paper, Button, CircularProgress, Alert } from '@mui/material';
import { addDays, startOfDay } from 'date-fns';
import appConfig from '../../config/appConfig';

const BookingExternalLink = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [externalBookingUrl, setExternalBookingUrl] = useState('');

  useEffect(() => {
    generateBookingUrl();
  }, []);

  const generateBookingUrl = () => {
    try {
      setLoading(true);

      // Set today as check-in date and tomorrow as check-out date
      const today = startOfDay(new Date());
      const tomorrow = addDays(today, 1);

      // Format dates for RMS URL
      const checkIn = today.toISOString().split('T')[0];
      const checkOut = tomorrow.toISOString().split('T')[0];

      // Use the centralized configuration
      const baseUrl = appConfig.externalBooking.baseUrl;
      // Format timestamp as yyyyMMddHHmmss
      const formattedTimestamp = today.toISOString().replace(/[-:T.]/g, '').substring(0, 14);
      const queryParams = new URLSearchParams({
        A: checkIn,
        D: checkOut,
        Dn: formattedTimestamp,
        Rt: 0,
        Ad: 2, // 2 adults
        Ch: 0, // 0 children
      });

      const url = `${baseUrl}?${queryParams.toString()}#!/rate`;
      setExternalBookingUrl(url);
      console.log('Generated booking URL:', url);
    } catch (err) {
      setError('Error generating booking link. Please try again.');
      console.error('Booking error:', err);
    } finally {
      setLoading(false);
    }
  };

  // If external booking is disabled, show an appropriate message
  if (appConfig.externalBooking.enabled === false) {
    return (
      <Container maxWidth="md">
        <Box sx={{ py: 8 }}>
          <Typography variant="h2" align="center" gutterBottom>
            Book Your Stay
          </Typography>
          <Paper sx={{ p: 5, mt: 3, textAlign: 'center' }}>
            <Typography variant="h5" gutterBottom>
              Online booking is currently unavailable
            </Typography>
            <Typography variant="body1">
              Please call us directly to make a reservation.
            </Typography>
          </Paper>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ py: 8 }}>
        <Typography variant="h2" align="center" gutterBottom>
          Book Your Stay
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}

        <Paper sx={{ p: 5, mt: 3, textAlign: 'center' }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <Typography variant="h5" gutterBottom>
                Ready to book your room?
              </Typography>
              <Typography variant="body1" paragraph>
                Click below to book your stay for tonight through tomorrow for 2 guests.
              </Typography>

              <Button
                variant="contained"
                color="primary"
                href={externalBookingUrl}
                target="_blank"
                rel="noopener noreferrer"
                size="large"
                sx={{ mt: 3, py: 1.5, px: 4, fontSize: '1.1rem' }}
              >
                Book Now on {appConfig.externalBooking.provider}
              </Button>

              <Typography variant="body2" sx={{ mt: 4, color: 'text.secondary' }}>
                You will be redirected to our secure booking partner's website to complete your
                reservation.
              </Typography>
            </>
          )}
        </Paper>
      </Box>
    </Container>
  );
};

export default BookingExternalLink;
