import { serviceRegistry } from './serviceRegistry.js';
import { db } from '../config/firebase.js';
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  Timestamp,
} from 'firebase/firestore';

/**
 * DoorService
 * Handles smart lock functionality for room access
 */
class DoorService {
  constructor() {
    this.initialized = false;
    console.log('DoorService initialized');
  }

  /**
   * Initialize the service
   */
  initialize() {
    this.initialized = true;
    return this;
  }

  /**
   * Generate an access code for a room
   * @param {string} roomNumber - The room number
   * @returns {Promise<string>} - The generated access code
   */
  async generateCode(roomNumber) {
    try {
      // Generate a 6-digit code
      const code = Math.floor(100000 + Math.random() * 900000).toString();

      // Store the code in Firestore
      const roomsRef = collection(db, 'rooms');
      const q = query(roomsRef, where('roomNumber', '==', roomNumber));
      const snapshot = await getDocs(q);

      if (snapshot.empty) {
        throw new Error(`Room ${roomNumber} not found`);
      }

      const roomDoc = snapshot.docs[0];
      await updateDoc(doc(db, 'rooms', roomDoc.id), {
        accessCode: code,
        codeUpdatedAt: Timestamp.now(),
      });

      // Log access code generation
      await addDoc(collection(db, 'accessLogs'), {
        roomNumber,
        accessCode: code,
        action: 'generate',
        timestamp: Timestamp.now(),
      });

      return code;
    } catch (error) {
      console.error('Error generating access code:', error);
      throw new Error(`Failed to generate access code: ${error.message}`);
    }
  }

  /**
   * Verify an access code for a room
   * @param {string} roomNumber - The room number
   * @param {string} code - The access code to verify
   * @returns {Promise<boolean>} - Whether the code is valid
   */
  async verifyCode(roomNumber, code) {
    try {
      const roomsRef = collection(db, 'rooms');
      const q = query(roomsRef, where('roomNumber', '==', roomNumber));
      const snapshot = await getDocs(q);

      if (snapshot.empty) {
        return false;
      }

      const roomDoc = snapshot.docs[0];
      const roomData = roomDoc.data();

      // Log verification attempt
      await addDoc(collection(db, 'accessLogs'), {
        roomNumber,
        accessCode: code,
        action: 'verify',
        success: roomData.accessCode === code,
        timestamp: Timestamp.now(),
      });

      return roomData.accessCode === code;
    } catch (error) {
      console.error('Error verifying access code:', error);
      return false;
    }
  }

  /**
   * Invalidate an access code for a room
   * @param {string} roomNumber - The room number
   * @returns {Promise<boolean>} - Whether the code was invalidated
   */
  async invalidateCode(roomNumber) {
    try {
      const roomsRef = collection(db, 'rooms');
      const q = query(roomsRef, where('roomNumber', '==', roomNumber));
      const snapshot = await getDocs(q);

      if (snapshot.empty) {
        throw new Error(`Room ${roomNumber} not found`);
      }

      const roomDoc = snapshot.docs[0];
      await updateDoc(doc(db, 'rooms', roomDoc.id), {
        accessCode: null,
        codeUpdatedAt: Timestamp.now(),
      });

      // Log invalidation
      await addDoc(collection(db, 'accessLogs'), {
        roomNumber,
        action: 'invalidate',
        timestamp: Timestamp.now(),
      });

      return true;
    } catch (error) {
      console.error('Error invalidating access code:', error);
      return false;
    }
  }
}

// Create and export singleton instance
const doorService = new DoorService();

// Register with service registry
serviceRegistry.register('doorService', doorService);

export default doorService;
