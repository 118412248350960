import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, Grid, TextField, CircularProgress, Alert } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { isSameDay, addDays } from 'date-fns';
import serviceLocator from '../../../services/serviceLocator';
import { ErrorHandler } from '../../../utils/ErrorHandler';

const BookingCalendar = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), 1));
  const [availableRooms, setAvailableRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [unsubscribe, setUnsubscribe] = useState(null);

  useEffect(() => {
    const loadAvailability = async () => {
      try {
        setLoading(true);
        setError(null);

        const roomAvailabilityService = serviceLocator.getService('roomAvailability');
        if (!roomAvailabilityService) {
          throw new Error('Room availability service not found');
        }

        // Subscribe to real-time updates
        const unsubscribeFn = roomAvailabilityService.subscribeToAvailability(
          selectedDate,
          endDate,
          (rooms, error) => {
            if (error) {
              setError(error.message);
              setAvailableRooms([]);
            } else {
              setAvailableRooms(rooms);
              setError(null);
            }
            setLoading(false);
          }
        );

        setUnsubscribe(() => unsubscribeFn);
      } catch (error) {
        const handledError = ErrorHandler.handleFirebaseError(error);
        setError(handledError.message);
        setAvailableRooms([]);
        setLoading(false);
      }
    };

    loadAvailability();

    // Cleanup subscription on unmount
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [selectedDate, endDate]);

  // Custom rendering of calendar days
  const renderDay = (day, _value, DayComponentProps) => {
    const date = day.getDate();
    const isAvailable = availableRooms.some(room => isSameDay(new Date(room.availableDate), day));

    return (
      <Box
        sx={{
          position: 'relative',
          '& .MuiPickersDay-root': {
            backgroundColor: isAvailable ? '#e8f5e9' : '#ffebee',
          },
        }}
      >
        <DayComponentProps.day {...DayComponentProps} />
        {isAvailable && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 2,
              left: '50%',
              transform: 'translateX(-50%)',
              width: '4px',
              height: '4px',
              borderRadius: '50%',
              backgroundColor: 'green',
            }}
          />
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              value={selectedDate}
              onChange={newValue => setSelectedDate(newValue)}
              renderDay={renderDay}
              renderInput={params => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
            <Typography variant="h5" gutterBottom>
              Available Rooms for {selectedDate.toLocaleDateString()}
            </Typography>

            {loading ? (
              <Box display="flex" justifyContent="center" p={3}>
                <CircularProgress />
              </Box>
            ) : error ? (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            ) : availableRooms.length === 0 ? (
              <Alert severity="info" sx={{ mb: 2 }}>
                No rooms available for the selected date
              </Alert>
            ) : (
              availableRooms.map(room => (
                <Box
                  key={room.id}
                  sx={{
                    mb: 2,
                    p: 2,
                    border: '1px solid #e0e0e0',
                    borderRadius: 1,
                  }}
                >
                  <Typography variant="h6">{room.type}</Typography>
                  <Typography variant="body1" color="text.secondary">
                    Price: ${room.price} per night
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: 'green',
                      fontWeight: 'bold',
                    }}
                  >
                    Available
                  </Typography>
                </Box>
              ))
            )}

            <Box sx={{ mt: 4 }}>
              <Typography variant="body2" color="text.secondary">
                <Box
                  component="span"
                  sx={{
                    display: 'inline-block',
                    width: 12,
                    height: 12,
                    bgcolor: '#e8f5e9',
                    borderRadius: '50%',
                    mr: 1,
                  }}
                />
                Available dates
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <Box
                  component="span"
                  sx={{
                    display: 'inline-block',
                    width: 12,
                    height: 12,
                    bgcolor: '#ffebee',
                    borderRadius: '50%',
                    mr: 1,
                  }}
                />
                Fully booked dates
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BookingCalendar;
