/**
 * Firebase Configuration
 * Configurable for both production and emulator environments
 */

import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  connectFirestoreEmulator,
  enableIndexedDbPersistence,
} from 'firebase/firestore';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getAnalytics, isSupported } from 'firebase/analytics';

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "webemo2025",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Check if using emulators
const useEmulators = process.env.REACT_APP_USE_FIREBASE_EMULATOR === 'true';

console.log(
  `Initializing Firebase with ${useEmulators ? 'emulators' : 'production'} config:`,
  process.env.NODE_ENV === 'development'
    ? { ...firebaseConfig, projectId: firebaseConfig.projectId, apiKey: '(hidden in logs)' }
    : '(hidden in production)'
);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
console.log('Firebase app initialized successfully');

// Initialize Firestore
const db = getFirestore(app);

// Initialize auth
const auth = getAuth(app);

// Initialize storage
const storage = getStorage(app);

// Initialize functions
const functions = getFunctions(app);

// Connect to emulators if in development mode
if (useEmulators) {
  console.log('Connecting to Firebase emulators...');
  // Connect to the emulators
  const firestorePort = process.env.REACT_APP_FIRESTORE_EMULATOR_PORT || 8082;
  const authPort = process.env.REACT_APP_AUTH_EMULATOR_PORT || 9099;
  const storagePort = process.env.REACT_APP_STORAGE_EMULATOR_PORT || 9199;
  const functionsPort = process.env.REACT_APP_FUNCTIONS_EMULATOR_PORT || 5001;
  
  // Connect to Firestore emulator
  connectFirestoreEmulator(db, 'localhost', parseInt(firestorePort));
  console.log(`Connected to Firestore emulator on port ${firestorePort}`);
  
  // Connect to Auth emulator
  connectAuthEmulator(auth, `http://localhost:${authPort}`, { disableWarnings: true });
  console.log(`Connected to Auth emulator on port ${authPort}`);
  
  // Connect to Storage emulator
  connectStorageEmulator(storage, 'localhost', parseInt(storagePort));
  console.log(`Connected to Storage emulator on port ${storagePort}`);
  
  // Connect to Functions emulator
  connectFunctionsEmulator(functions, 'localhost', parseInt(functionsPort));
  console.log(`Connected to Functions emulator on port ${functionsPort}`);
}

// Enable offline persistence if not in an emulator
if (!useEmulators && process.env.NODE_ENV !== 'test') {
  enableIndexedDbPersistence(db).catch((err) => {
    console.warn('Failed to enable offline persistence:', err.message);
  });
}

// Initialize analytics in production only
let analytics = null;
if (process.env.NODE_ENV === 'production') {
  isSupported().then((supported) => {
    if (supported) {
      analytics = getAnalytics(app);
      console.log('Firebase analytics initialized');
    }
  });
}

// Export initialized services
export { app, db, auth, storage, functions, analytics };
