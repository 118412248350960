import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Fade, Typography } from '@mui/material';

const LoadingSpinner = ({ message = 'Loading...' }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 500); // Show after 500ms delay to avoid flashing for quick loads

    return () => clearTimeout(timer);
  }, []);

  return (
    <Fade in={isVisible} timeout={300}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        p={3}
        sx={{
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 0.3s ease-in-out',
          height: '100%',
          minHeight: '200px',
        }}
      >
        <CircularProgress size={48} />
        {message && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            {message}
          </Typography>
        )}
      </Box>
    </Fade>
  );
};

export default LoadingSpinner;
