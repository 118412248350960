import { serviceRegistry } from './serviceRegistry.js';
import { db } from '../config/firebase.js';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  orderBy,
  limit,
} from 'firebase/firestore';

/**
 * ReservationService
 * Service for managing guest reservations
 */
class ReservationService {
  constructor() {
    console.log('ReservationService initialized');
    this.collectionName = 'reservations';
    this.initialized = false;

    // Try to get services we depend on
    try {
      this.roomService = serviceRegistry.getService('roomService');
      this.guestService = serviceRegistry.getService('guestService');
    } catch (error) {
      console.log('Some dependent services not available yet, will lazy load if needed');
    }
  }

  /**
   * Initialize the service
   */
  initialize() {
    this.initialized = true;

    // Get any dependencies that weren't available in constructor
    if (!this.roomService) {
      try {
        this.roomService = serviceRegistry.getService('roomService');
      } catch (error) {
        console.warn('RoomService not available, some reservation features may be limited');
      }
    }

    if (!this.guestService) {
      try {
        this.guestService = serviceRegistry.getService('guestService');
      } catch (error) {
        console.warn('GuestService not available, some reservation features may be limited');
      }
    }

    return this;
  }

  /**
   * Create a new reservation
   * @param {Object} reservationData - Reservation data
   * @returns {Promise<Object>} - Created reservation object with ID
   */
  async createReservation(reservationData) {
    try {
      // Validate required fields
      const requiredFields = ['guestId', 'roomId', 'checkInDate', 'checkOutDate'];
      for (const field of requiredFields) {
        if (!reservationData[field]) {
          throw new Error(`Missing required field: ${field}`);
        }
      }

      // Check room availability
      if (this.roomService) {
        const isAvailable = await this.roomService.checkAvailability(
          reservationData.roomId,
          reservationData.checkInDate,
          reservationData.checkOutDate
        );

        if (!isAvailable) {
          throw new Error('Room is not available for the selected dates');
        }
      }

      // Add timestamps and status
      const newReservationData = {
        ...reservationData,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        status: reservationData.status || 'confirmed',
        confirmationCode: this._generateConfirmationCode(),
      };

      // Add to database
      const reservationRef = await addDoc(collection(db, this.collectionName), newReservationData);

      // If we have notification service, send confirmation
      try {
        const notificationService = serviceRegistry.getService('notificationService');
        if (notificationService && this.guestService) {
          const guest = await this.guestService.getGuest(reservationData.guestId);
          await notificationService.sendReservationConfirmation(guest, {
            id: reservationRef.id,
            ...newReservationData,
          });
        }
      } catch (error) {
        console.error('Failed to send reservation confirmation:', error);
        // Continue anyway, notification is not critical
      }

      return {
        id: reservationRef.id,
        ...newReservationData,
      };
    } catch (error) {
      console.error('Error creating reservation:', error);
      throw new Error(`Failed to create reservation: ${error.message}`);
    }
  }

  /**
   * Get a reservation by ID
   * @param {string} reservationId - The reservation ID
   * @returns {Promise<Object>} - Reservation object
   */
  async getReservation(reservationId) {
    try {
      const reservationDoc = await getDoc(doc(db, this.collectionName, reservationId));

      if (!reservationDoc.exists()) {
        throw new Error(`Reservation with ID ${reservationId} not found`);
      }

      return {
        id: reservationDoc.id,
        ...reservationDoc.data(),
      };
    } catch (error) {
      console.error(`Error fetching reservation ${reservationId}:`, error);
      throw new Error(`Failed to fetch reservation: ${error.message}`);
    }
  }

  /**
   * Update a reservation
   * @param {string} reservationId - The reservation ID
   * @param {Object} reservationData - Updated reservation data
   * @returns {Promise<Object>} - Updated reservation object
   */
  async updateReservation(reservationId, reservationData) {
    try {
      const reservationRef = doc(db, this.collectionName, reservationId);
      const reservationDoc = await getDoc(reservationRef);

      if (!reservationDoc.exists()) {
        throw new Error(`Reservation with ID ${reservationId} not found`);
      }

      // Check for date changes and room availability
      const oldData = reservationDoc.data();
      if (
        (reservationData.checkInDate && reservationData.checkInDate !== oldData.checkInDate) ||
        (reservationData.checkOutDate && reservationData.checkOutDate !== oldData.checkOutDate) ||
        (reservationData.roomId && reservationData.roomId !== oldData.roomId)
      ) {
        // Dates or room changed, check availability
        if (this.roomService) {
          const checkInDate = reservationData.checkInDate || oldData.checkInDate;
          const checkOutDate = reservationData.checkOutDate || oldData.checkOutDate;
          const roomId = reservationData.roomId || oldData.roomId;

          const isAvailable = await this.roomService.checkAvailability(
            roomId,
            checkInDate,
            checkOutDate,
            reservationId // Exclude current reservation from availability check
          );

          if (!isAvailable) {
            throw new Error('Room is not available for the updated dates');
          }
        }
      }

      // Add updated timestamp
      const updateData = {
        ...reservationData,
        updatedAt: serverTimestamp(),
      };

      await updateDoc(reservationRef, updateData);

      // Get the updated document
      const updatedDoc = await getDoc(reservationRef);

      return {
        id: updatedDoc.id,
        ...updatedDoc.data(),
      };
    } catch (error) {
      console.error(`Error updating reservation ${reservationId}:`, error);
      throw new Error(`Failed to update reservation: ${error.message}`);
    }
  }

  /**
   * Cancel a reservation
   * @param {string} reservationId - The reservation ID to cancel
   * @param {string} reason - Cancellation reason
   * @returns {Promise<Object>} - Updated reservation
   */
  async cancelReservation(reservationId, reason = '') {
    try {
      const reservationRef = doc(db, this.collectionName, reservationId);
      const reservationDoc = await getDoc(reservationRef);

      if (!reservationDoc.exists()) {
        throw new Error(`Reservation with ID ${reservationId} not found`);
      }

      const updateData = {
        status: 'cancelled',
        cancelledAt: serverTimestamp(),
        cancellationReason: reason,
        updatedAt: serverTimestamp(),
      };

      await updateDoc(reservationRef, updateData);

      // Get the updated document
      const updatedDoc = await getDoc(reservationRef);
      const updatedReservation = {
        id: updatedDoc.id,
        ...updatedDoc.data(),
      };

      // Notify about cancellation if possible
      try {
        const notificationService = serviceRegistry.getService('notificationService');
        if (notificationService && this.guestService) {
          const reservation = updatedReservation;
          const guest = await this.guestService.getGuest(reservation.guestId);
          await notificationService.sendReservationCancellation(guest, reservation);
        }
      } catch (error) {
        console.error('Failed to send cancellation notification:', error);
        // Continue anyway, notification is not critical
      }

      return updatedReservation;
    } catch (error) {
      console.error(`Error cancelling reservation ${reservationId}:`, error);
      throw new Error(`Failed to cancel reservation: ${error.message}`);
    }
  }

  /**
   * Search for reservations
   * @param {Object} filters - Search filters
   * @returns {Promise<Array>} - Array of matching reservations
   */
  async searchReservations(filters = {}) {
    try {
      let reservationsQuery = collection(db, this.collectionName);

      // Apply filters
      if (filters.status) {
        reservationsQuery = query(reservationsQuery, where('status', '==', filters.status));
      }

      if (filters.guestId) {
        reservationsQuery = query(reservationsQuery, where('guestId', '==', filters.guestId));
      }

      if (filters.roomId) {
        reservationsQuery = query(reservationsQuery, where('roomId', '==', filters.roomId));
      }

      // Date range filtering is more complex due to Firestore limitations
      // We'll fetch and filter in memory for simplicity

      // Add sorting
      if (filters.orderBy) {
        reservationsQuery = query(
          reservationsQuery,
          orderBy(filters.orderBy, filters.orderDir || 'asc')
        );
      } else {
        // Default sort by checkInDate
        reservationsQuery = query(reservationsQuery, orderBy('checkInDate', 'desc'));
      }

      // Add limit if specified
      if (filters.limit) {
        reservationsQuery = query(reservationsQuery, limit(parseInt(filters.limit)));
      }

      const snapshot = await getDocs(reservationsQuery);
      let reservations = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Apply date filters in memory if needed
      if (filters.checkInDate) {
        reservations = reservations.filter(res => res.checkInDate >= filters.checkInDate);
      }

      if (filters.checkOutDate) {
        reservations = reservations.filter(res => res.checkOutDate <= filters.checkOutDate);
      }

      return reservations;
    } catch (error) {
      console.error('Error searching reservations:', error);
      throw new Error(`Failed to search reservations: ${error.message}`);
    }
  }

  /**
   * Generate a confirmation code for the reservation
   * @returns {string} - Confirmation code
   * @private
   */
  _generateConfirmationCode() {
    const chars = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789'; // Omitting easily confused characters
    let code = '';

    // Generate 8 character code
    for (let i = 0; i < 8; i++) {
      code += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return code;
  }
}

// Create and export a singleton instance
const reservationService = new ReservationService();
serviceRegistry.register('reservationService', reservationService);

export default reservationService;
