/**
 * Service Initialization Manager
 * Handles the creation, registration, and initialization of all application services
 * in the correct dependency order
 */

import { serviceRegistry } from './serviceRegistry.js';
import serviceFactory from './serviceFactory.js';

// Core services - making authService a higher priority
const CORE_SERVICES = [
  'loggingService',
  'databaseService',
  'apiService',
  'storageService',
  'authService', // Ensure this is loaded before depending services
  'functionsService',
  'preferencesService',
];

// Domain services
const DOMAIN_SERVICES = [
  'roomTypeService',
  'roomRatesService',
  'roomAvailabilityService',
  'bookingService',
  'reservationService',
  'guestService',
  'paymentService',
  'notificationService',
  'pmsService',
  'roomSettingsService',
  'smartLockService',
  'doorService',
  'touchscreenService',
];

/**
 * Create and register all services with the registry
 * @returns {Promise<boolean>}
 */
async function registerAllServices() {
  console.log('Registering all services...');

  try {
    // Register core services first - with additional error handling
    for (const serviceName of CORE_SERVICES) {
      try {
        console.log(`Registering core service: ${serviceName}`);
        const service = serviceFactory.createAndRegisterService(serviceName);
        console.log(`Successfully registered ${serviceName}`);

        // For critical services like authService, verify registration
        if (serviceName === 'authService') {
          if (!serviceRegistry.hasService('authService')) {
            console.error(`Critical service 'authService' failed registration verification`);
            // Re-register if needed
            serviceRegistry.register(
              'authService',
              service,
              serviceFactory._getDependenciesForService('authService')
            );
          }
        }
      } catch (err) {
        console.error(`Failed to register core service: ${serviceName}`, err);
        // For critical services, attempt a retry with alternative method
        if (serviceName === 'authService') {
          try {
            // Direct import fallback for auth service
            const { authService } = require('./AuthService.js');
            if (authService) {
              console.log('Using singleton authService instance as fallback');
              serviceRegistry.register('authService', authService, []);
            }
          } catch (fallbackErr) {
            console.error('Fallback registration for authService also failed', fallbackErr);
          }
        }
      }
    }

    // Then register domain services
    for (const serviceName of DOMAIN_SERVICES) {
      try {
        console.log(`Registering domain service: ${serviceName}`);
        serviceFactory.createAndRegisterService(serviceName);
      } catch (err) {
        console.error(`Failed to register domain service: ${serviceName}`, err);
        // Continue with other services
      }
    }

    console.log('All services registered successfully');
    return true;
  } catch (error) {
    console.error('Error registering services:', error);
    return false;
  }
}

/**
 * Initialize all services in dependency order
 * @returns {Promise<boolean>}
 */
async function initializeAllServices() {
  console.log('Initializing all services...');

  try {
    // Calculate initialization order based on dependencies
    const initOrder = serviceRegistry.calculateInitializationOrder();
    console.log('Service initialization order:', initOrder);

    // Initialize all services
    const result = await serviceRegistry.initializeServices();
    return result;
  } catch (error) {
    console.error('Error initializing services:', error);
    return false;
  }
}

/**
 * Main function to bootstrap all services
 * @returns {Promise<boolean>}
 */
export async function bootstrapServices() {
  console.log('Bootstrapping all services...');

  try {
    // Register all services
    const registrationResult = await registerAllServices();
    if (!registrationResult) {
      console.warn('Some services failed to register, but continuing with initialization');
    }

    // Verify critical services like authService
    if (!serviceRegistry.hasService('authService')) {
      console.error("Critical 'authService' is missing - attempting emergency registration");
      try {
        const { authService } = require('./AuthService.js');
        if (authService) {
          serviceRegistry.register('authService', authService, []);
        }
      } catch (e) {
        console.error('Emergency authService registration failed', e);
      }
    }

    // Initialize all services in dependency order
    const initResult = await initializeAllServices();

    if (initResult) {
      console.log('All services bootstrapped successfully');
      serviceRegistry.setInitialized();
      return true;
    } else {
      console.error('Service bootstrap failed');
      return false;
    }
  } catch (error) {
    console.error('Error bootstrapping services:', error);
    return false;
  }
}

/**
 * Check if services are initialized
 * @returns {boolean}
 */
export function areServicesInitialized() {
  return serviceRegistry.isInitialized();
}

// Export the main functions
export { registerAllServices, initializeAllServices };
export default bootstrapServices;
