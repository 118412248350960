/**
 * Enhanced Service Registry
 * Centralized registry for all application services with dependency tracking and ordered initialization
 * Implemented as a singleton to avoid circular dependencies
 */

class ServiceRegistry {
  constructor() {
    if (ServiceRegistry.instance) {
      return ServiceRegistry.instance;
    }

    this.services = new Map();
    this.dependencies = new Map();
    this.initialized = false;
    this.initializationOrder = [];
    ServiceRegistry.instance = this;
    console.log('Enhanced ServiceRegistry initialized');
  }

  /**
   * Register a service in the registry
   * @param {string} name - Service name
   * @param {Object} instance - Service instance
   * @param {Array<string>} dependencies - Optional array of dependency service names
   * @returns {ServiceRegistry} - For chaining
   */
  register(name, instance, dependencies = []) {
    if (!name || typeof name !== 'string') {
      throw new Error('Service name must be a valid string');
    }

    if (!instance) {
      throw new Error('Service instance must be provided');
    }

    // Store the service instance
    this.services.set(name, instance);

    // Store its dependencies
    this.dependencies.set(name, dependencies);

    return this;
  }

  /**
   * Get a service from the registry
   * @param {string} name - Service name
   * @returns {Object} - Service instance
   */
  getService(name) {
    if (!this.services.has(name)) {
      throw new Error(`Service "${name}" not registered`);
    }

    return this.services.get(name);
  }

  /**
   * Check if a service exists in the registry
   * @param {string} name - Service name
   * @returns {boolean} - Whether the service exists
   */
  hasService(name) {
    return this.services.has(name);
  }

  /**
   * Get all registered service names
   * @returns {Array<string>} - Array of service names
   */
  getServiceNames() {
    return Array.from(this.services.keys());
  }

  /**
   * Get dependencies for a service
   * @param {string} name - Service name
   * @returns {Array<string>} - Array of dependency service names
   */
  getDependencies(name) {
    return this.dependencies.get(name) || [];
  }

  /**
   * Calculate initialization order based on dependencies
   * @returns {Array<string>} - Ordered array of service names
   */
  calculateInitializationOrder() {
    const visited = new Set();
    const temporaryMark = new Set();
    const order = [];

    // Depth-first topological sort
    const visit = name => {
      if (temporaryMark.has(name)) {
        throw new Error(`Circular dependency detected involving service: ${name}`);
      }

      if (!visited.has(name)) {
        temporaryMark.add(name);

        // Visit all dependencies first
        const dependencies = this.getDependencies(name);
        for (const dependency of dependencies) {
          if (!this.hasService(dependency)) {
            console.warn(`Service ${name} depends on ${dependency}, but it's not registered`);
            continue;
          }
          visit(dependency);
        }

        // Remove temporary mark and add to visited
        temporaryMark.delete(name);
        visited.add(name);
        order.push(name);
      }
    };

    // Visit all services
    this.getServiceNames().forEach(name => {
      if (!visited.has(name)) {
        visit(name);
      }
    });

    this.initializationOrder = order;
    return order;
  }

  /**
   * Initialize all registered services in the correct order
   * @returns {Promise<boolean>} - Whether initialization was successful
   */
  async initializeServices() {
    console.log('Initializing services in dependency order');

    try {
      // Calculate initialization order if not already done
      if (this.initializationOrder.length === 0) {
        this.calculateInitializationOrder();
      }

      // Initialize services in order
      for (const serviceName of this.initializationOrder) {
        const service = this.getService(serviceName);

        if (service.initialize && typeof service.initialize === 'function') {
          console.log(`Initializing service: ${serviceName}`);
          await service.initialize();
          console.log(`Service ${serviceName} initialized successfully`);
        }
      }

      this.initialized = true;
      console.log('All services initialized successfully');
      return true;
    } catch (error) {
      console.error('Service initialization failed:', error);
      return false;
    }
  }

  /**
   * Mark the registry as initialized
   */
  setInitialized() {
    this.initialized = true;
    return this;
  }

  /**
   * Check if the registry is initialized
   * @returns {boolean} - Whether the registry is initialized
   */
  isInitialized() {
    return this.initialized;
  }

  /**
   * Remove a service from the registry
   * @param {string} name - Service name
   * @returns {boolean} - Whether the service was removed
   */
  unregister(name) {
    this.dependencies.delete(name);
    return this.services.delete(name);
  }
}

// Export a singleton instance
export const serviceRegistry = new ServiceRegistry();
export default serviceRegistry;
