import React, { useState } from 'react';
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  Grid,
  Paper,
  Divider,
  Alert,
  Stack,
  Avatar,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import EmailIcon from '@mui/icons-material/Email';
import HotelIcon from '@mui/icons-material/Hotel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import serviceRegistry from '../../../services/serviceRegistry.js';

const CheckIn = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [reservation, setReservation] = useState(null);
  const [error, setError] = useState('');
  const [completed, setCompleted] = useState(false);

  const steps = ['Find Reservation', 'Verify Details', 'Room Assignment', 'Complete'];

  const handleSearch = async () => {
    setError('');

    if (!searchTerm) {
      setError('Please enter a confirmation number or email address');
      return;
    }

    try {
      // Use PMS service to search for the reservation
      const pmsService = serviceRegistry.get('pms');
      const result = await pmsService.findReservation(searchTerm);

      if (result) {
        setReservation(result);
        setActiveStep(1);
      } else {
        setError(
          'No reservation found with that confirmation number. Please check your email or contact the front desk.'
        );
      }
    } catch (err) {
      setError('Error searching for reservation: ' + err.message);
      console.error(err);
    }
  };

  const handleNext = async () => {
    try {
      if (activeStep === 2) {
        // When completing check-in
        const pmsService = serviceRegistry.get('pms');
        await pmsService.checkInGuest(reservation.id);
        setCompleted(true);
      }

      setActiveStep(prevStep => prevStep + 1);
    } catch (err) {
      setError('Error during check-in process: ' + err.message);
    }
  };

  const handleBack = () => {
    setActiveStep(prevStep => prevStep - 1);
  };

  return (
    <Box>
      <Typography variant="h2" gutterBottom>
        Self Check-In
      </Typography>
      <Typography variant="body1" paragraph>
        Welcome to West Bethel Motel! Please follow the steps below to complete your check-in
        process.
      </Typography>

      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        {activeStep === 0 && (
          <Box>
            <Typography variant="h5" gutterBottom>
              Find Your Reservation
            </Typography>
            <Typography variant="body1" paragraph>
              Enter your confirmation number or the email address used for your reservation.
            </Typography>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={8}>
                <TextField
                  fullWidth
                  label="Confirmation # or Email"
                  variant="outlined"
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  placeholder="e.g., WBM12345 or your@email.com"
                  InputProps={{
                    startAdornment: <SearchIcon sx={{ color: 'action.active', mr: 1 }} />,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  onClick={handleSearch}
                  disabled={!searchTerm}
                >
                  Find Reservation
                </Button>
              </Grid>
            </Grid>

            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}

            <Box sx={{ mt: 4 }}>
              <Typography variant="body2" color="text.secondary">
                If you need assistance, please contact our front desk by dialing 0 from any hotel
                phone.
              </Typography>
            </Box>
          </Box>
        )}

        {activeStep === 1 && reservation && (
          <Box>
            <Typography variant="h5" gutterBottom>
              Verify Your Reservation Details
            </Typography>
            <Typography variant="body1" paragraph>
              Please verify that the following information is correct:
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Name
                    </Typography>
                    <Typography variant="body1">{reservation.name}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Email
                    </Typography>
                    <Typography variant="body1">{reservation.email}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Phone
                    </Typography>
                    <Typography variant="body1">{reservation.phone}</Typography>
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Check-in Date
                    </Typography>
                    <Typography variant="body1">
                      {new Date(reservation.checkIn).toLocaleDateString()}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Check-out Date
                    </Typography>
                    <Typography variant="body1">
                      {new Date(reservation.checkOut).toLocaleDateString()}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Room Type
                    </Typography>
                    <Typography variant="body1">{reservation.roomType}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Guests
                    </Typography>
                    <Typography variant="body1">
                      {reservation.adults} {reservation.adults === 1 ? 'Adult' : 'Adults'},
                      {reservation.children > 0
                        ? ` ${reservation.children} ${reservation.children === 1 ? 'Child' : 'Children'}`
                        : ' No Children'}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
            </Grid>

            <Divider sx={{ my: 3 }} />

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={handleBack}>Back</Button>
              <Button variant="contained" onClick={handleNext}>
                Confirm Details
              </Button>
            </Box>
          </Box>
        )}

        {activeStep === 2 && (
          <Box>
            <Typography variant="h5" gutterBottom>
              Room Assignment
            </Typography>
            <Typography variant="body1" paragraph>
              Your room has been assigned based on your preferences and availability.
            </Typography>

            <Paper
              elevation={2}
              sx={{ p: 3, mb: 3, bgcolor: '#f9f9f9', border: '1px solid #e0e0e0' }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <Avatar sx={{ bgcolor: 'primary.main', width: 60, height: 60 }}>
                    <HotelIcon sx={{ fontSize: 40 }} />
                  </Avatar>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Typography variant="h4" gutterBottom>
                    Room 205
                  </Typography>
                  <Typography variant="body1">
                    <strong>Deluxe King Room</strong> • 2nd Floor • Mountain View
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body2">
                      Your room is located on the second floor, accessible via stairs or elevator.
                      The room features a king-sized bed, work area, and a private balcony with
                      mountain views.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>

            <Alert severity="info" sx={{ mb: 3 }}>
              <Typography variant="body1">
                Your room key will be generated in the next step. Please collect it at the end of
                the check-in process.
              </Typography>
            </Alert>

            <Divider sx={{ my: 3 }} />

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={handleBack}>Back</Button>
              <Button variant="contained" onClick={handleNext}>
                Generate Room Key
              </Button>
            </Box>
          </Box>
        )}

        {activeStep === 3 && (
          <Box sx={{ textAlign: 'center' }}>
            <CheckCircleIcon sx={{ fontSize: 80, color: 'success.main', mb: 2 }} />
            <Typography variant="h4" gutterBottom>
              Check-In Successful!
            </Typography>
            <Typography variant="body1" paragraph>
              Welcome to West Bethel Motel! Your room key has been generated successfully.
            </Typography>

            <Paper
              elevation={2}
              sx={{ p: 3, mb: 4, maxWidth: 400, mx: 'auto', bgcolor: '#f5f5f5' }}
            >
              <Typography variant="h5" gutterBottom>
                Room 205
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>Check-in:</strong> {new Date(reservation.checkIn).toLocaleDateString()}
                <br />
                <strong>Check-out:</strong> {new Date(reservation.checkOut).toLocaleDateString()}
                <br />
                <strong>Room Type:</strong> {reservation.roomType}
                <br />
                <strong>Key Card:</strong> Ready for pickup
              </Typography>
            </Paper>

            <Box sx={{ mt: 2 }}>
              <Typography variant="body1" paragraph>
                Please collect your room key from the dispenser below. If you need any assistance
                during your stay, dial 0 from your room phone.
              </Typography>
              <Button variant="contained" color="primary" size="large">
                Print Receipt
              </Button>
            </Box>
          </Box>
        )}
      </Paper>

      <Box sx={{ mt: 6, bgcolor: '#f5f5f5', p: 3, borderRadius: 2 }}>
        <Typography variant="h5" gutterBottom>
          Need Assistance?
        </Typography>
        <Typography variant="body1">
          Our front desk staff is available 24/7 to assist you with your check-in process. Please
          dial 0 from any hotel phone or visit the front desk.
        </Typography>
      </Box>
    </Box>
  );
};

export default CheckIn;
