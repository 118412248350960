/**
 * Service Interface Definitions
 * 
 * This file defines the symbols that represent service interfaces.
 * Using symbols helps prevent naming collisions and provides a unique identifier for each service.
 */

// Core service interfaces
export const ILoggingService = Symbol('ILoggingService');
export const IDatabaseService = Symbol('IDatabaseService');
export const IApiService = Symbol('IApiService');
export const IStorageService = Symbol('IStorageService');

// Domain service interfaces
export const IRoomTypeService = Symbol('IRoomTypeService');
export const IRoomAvailabilityService = Symbol('IRoomAvailabilityService');
export const IRoomRatesService = Symbol('IRoomRatesService');
export const IBookingService = Symbol('IBookingService');
export const IReservationService = Symbol('IReservationService');
export const IGuestService = Symbol('IGuestService');
export const IPaymentService = Symbol('IPaymentService');
export const INotificationService = Symbol('INotificationService');
export const ISmartLockService = Symbol('ISmartLockService');
export const IDoorService = Symbol('IDoorService');
export const ITouchscreenService = Symbol('ITouchscreenService');

/**
 * Helper function to check if a service implements an interface
 * @param {Object} service - Service instance
 * @param {Symbol} interfaceSymbol - Interface symbol
 * @returns {boolean} - Whether the service implements the interface
 */
export function implementsInterface(service, interfaceSymbol) {
  return service && (
    // Either the service explicitly marks itself as implementing the interface
    (service[interfaceSymbol] === true) || 
    // Or the service has the same name as the interface (without the 'I' prefix)
    (service.constructor && service.constructor.name === interfaceSymbol.description.substring(1))
  );
}

/**
 * Decorator to mark a service class as implementing an interface
 * @param {Symbol} interfaceSymbol - Interface symbol
 * @returns {Function} - Class decorator
 */
export function markAsImplements(interfaceSymbol) {
  return function(target) {
    target.prototype[interfaceSymbol] = true;
    return target;
  };
} 