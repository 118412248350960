import { db } from '../config/firebase';
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import ErrorHandler from '../utils/ErrorHandler';

class SmartLockService {
  constructor() {
    this.collection = 'smartLocks';
    this.accessCodesCollection = 'accessCodes';
  }

  /**
   * Generate a secure access code for a room
   * @param {string} roomId - The ID of the room
   * @returns {Promise<string>} The generated access code
   */
  async generateAccessCode(roomId) {
    try {
      // Generate a 6-digit code
      const code = Math.floor(100000 + Math.random() * 900000).toString();

      // Store the code with metadata
      const docRef = doc(db, this.accessCodesCollection);
      await setDoc(docRef, {
        roomId,
        code,
        createdAt: new Date().toISOString(),
        createdBy: 'system',
        status: 'active',
        lastUsed: null,
      });

      return code;
    } catch (error) {
      throw ErrorHandler.handleError(error, 'Failed to generate access code');
    }
  }

  /**
   * Update room's smart lock with new access code
   * @param {string} roomId - The ID of the room
   * @param {string} accessCode - The new access code
   * @param {string} updatedBy - The ID of the user updating the code
   * @returns {Promise<void>}
   */
  async updateRoomAccessCode(roomId, accessCode, updatedBy) {
    try {
      const docRef = doc(db, this.collection, roomId);
      await updateDoc(docRef, {
        accessCode,
        lastUpdated: new Date().toISOString(),
        updatedBy,
        status: 'ready',
      });

      // Log the code update
      await this.logAccessCodeUpdate(roomId, accessCode, updatedBy);
    } catch (error) {
      throw ErrorHandler.handleError(error, 'Failed to update room access code');
    }
  }

  /**
   * Get current access code for a room
   * @param {string} roomId - The ID of the room
   * @returns {Promise<string>} The current access code
   */
  async getRoomAccessCode(roomId) {
    try {
      const docRef = doc(db, this.collection, roomId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        throw new Error('Room not found');
      }

      return docSnap.data().accessCode;
    } catch (error) {
      throw ErrorHandler.handleError(error, 'Failed to get room access code');
    }
  }

  /**
   * Mark a room as ready for guest
   * @param {string} roomId - The ID of the room
   * @param {string} updatedBy - The ID of the user marking the room ready
   * @returns {Promise<void>}
   */
  async markRoomReady(roomId, updatedBy) {
    try {
      const docRef = doc(db, this.collection, roomId);
      await updateDoc(docRef, {
        status: 'ready',
        lastUpdated: new Date().toISOString(),
        updatedBy,
      });
    } catch (error) {
      throw ErrorHandler.handleError(error, 'Failed to mark room as ready');
    }
  }

  /**
   * Get rooms that need access code updates
   * @returns {Promise<Array>} List of rooms needing updates
   */
  async getRoomsNeedingUpdates() {
    try {
      const q = query(collection(db, this.collection), where('status', '==', 'needs_update'));

      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      throw ErrorHandler.handleError(error, 'Failed to get rooms needing updates');
    }
  }

  /**
   * Log access code update
   * @param {string} roomId - The ID of the room
   * @param {string} accessCode - The new access code
   * @param {string} updatedBy - The ID of the user updating the code
   * @returns {Promise<void>}
   */
  async logAccessCodeUpdate(roomId, accessCode, updatedBy) {
    try {
      const docRef = doc(db, this.accessCodesCollection);
      await setDoc(docRef, {
        roomId,
        code: accessCode,
        updatedAt: new Date().toISOString(),
        updatedBy,
        status: 'active',
      });
    } catch (error) {
      throw ErrorHandler.handleError(error, 'Failed to log access code update');
    }
  }

  /**
   * Validate access code for a room
   * @param {string} roomId - The ID of the room
   * @param {string} code - The code to validate
   * @returns {Promise<boolean>} True if code is valid
   */
  async validateAccessCode(roomId, code) {
    try {
      const currentCode = await this.getRoomAccessCode(roomId);
      return currentCode === code;
    } catch (error) {
      throw ErrorHandler.handleError(error, 'Failed to validate access code');
    }
  }

  /**
   * Initialize smart lock for a new room
   * @param {string} roomId - The ID of the room
   * @returns {Promise<void>}
   */
  async initializeSmartLock(roomId) {
    try {
      const accessCode = await this.generateAccessCode(roomId);
      const docRef = doc(db, this.collection, roomId);
      await setDoc(docRef, {
        roomId,
        accessCode,
        status: 'initialized',
        createdAt: new Date().toISOString(),
        lastUpdated: new Date().toISOString(),
      });
    } catch (error) {
      throw ErrorHandler.handleError(error, 'Failed to initialize smart lock');
    }
  }
}

export default new SmartLockService();
