import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
  Tabs,
  Tab,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  Alert,
  Divider,
  CircularProgress,
} from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentIcon from '@mui/icons-material/Payment';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import serviceRegistry from '../../../services/serviceRegistry.js';

const Payments = () => {
  const [tabValue, setTabValue] = useState(0);
  const [roomNumber, setRoomNumber] = useState('');
  const [lastName, setLastName] = useState('');
  const [reservation, setReservation] = useState(null);
  const [amount, setAmount] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [nameOnCard, setNameOnCard] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [searchError, setSearchError] = useState('');

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setSuccess(false);
    setError('');
  };

  const handleSearch = async () => {
    setSearchError('');
    if (!roomNumber || !lastName) {
      setSearchError('Please enter both room number and last name');
      return;
    }

    setLoading(true);

    try {
      const pmsService = serviceRegistry.get('pms');
      const result = await pmsService.findReservationByRoomAndName(roomNumber, lastName);

      if (result) {
        setReservation(result);
      } else {
        setSearchError('No reservation found with the provided information');
      }
    } catch (err) {
      setSearchError('Error retrieving reservation: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePayment = async () => {
    setError('');

    if (tabValue === 0 && (!cardNumber || !expiryDate || !cvv || !nameOnCard)) {
      setError('Please fill in all credit card details');
      return;
    }

    if (!amount || parseFloat(amount) <= 0) {
      setError('Please enter a valid payment amount');
      return;
    }

    if (reservation && parseFloat(amount) > reservation.balance) {
      setError('Payment amount cannot exceed the current balance');
      return;
    }

    setLoading(true);

    try {
      const pmsService = serviceRegistry.get('pms');

      let paymentResult;
      if (tabValue === 0) {
        paymentResult = await pmsService.processCardPayment({
          reservationId: reservation.id,
          amount: parseFloat(amount),
          cardDetails: {
            cardNumber,
            expiryDate,
            cvv,
            nameOnCard,
          },
        });
      } else if (tabValue === 1) {
        paymentResult = await pmsService.recordBankTransfer({
          reservationId: reservation.id,
          amount: parseFloat(amount),
          notes: 'Bank transfer recorded via kiosk',
        });
      }

      setSuccess(true);

      if (reservation) {
        setReservation({
          ...reservation,
          balance: (reservation.balance - parseFloat(amount)).toFixed(2),
        });
      }

      setAmount('');
      setCardNumber('');
      setExpiryDate('');
      setCvv('');
      setNameOnCard('');
    } catch (err) {
      setError('Payment failed: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h2" gutterBottom>
        Payments
      </Typography>
      <Typography variant="body1" paragraph>
        Make payments for your stay or add funds to your account.
      </Typography>

      {!reservation ? (
        <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Find Your Reservation
          </Typography>
          <Typography variant="body1" paragraph>
            Enter your room number and last name to access your account.
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Room Number"
                value={roomNumber}
                onChange={e => setRoomNumber(e.target.value)}
                placeholder="e.g. 205"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                placeholder="e.g. Smith"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={handleSearch}
                disabled={loading}
                sx={{ mt: 2 }}
              >
                {loading ? <CircularProgress size={24} /> : 'Find Reservation'}
              </Button>
            </Grid>
          </Grid>

          {searchError && (
            <Alert severity="error" sx={{ mt: 3 }}>
              {searchError}
            </Alert>
          )}
        </Paper>
      ) : (
        <Box>
          <Paper sx={{ p: 3, mb: 4, backgroundColor: '#f5f5f5' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={7}>
                <Typography variant="h6">
                  {reservation.name} - Room {reservation.roomNumber}
                </Typography>
                <Typography variant="body1">
                  Check-in: {new Date(reservation.checkIn).toLocaleDateString()} | Check-out:{' '}
                  {new Date(reservation.checkOut).toLocaleDateString()}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: { xs: 'flex-start', sm: 'flex-end' },
                }}
              >
                <Typography variant="h6" color={reservation.balance > 0 ? 'error' : 'success'}>
                  Current Balance: ${parseFloat(reservation.balance).toFixed(2)}
                </Typography>
                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    setReservation(null);
                    setRoomNumber('');
                    setLastName('');
                  }}
                  sx={{ mt: 1 }}
                >
                  Change Reservation
                </Button>
              </Grid>
            </Grid>
          </Paper>

          <Paper elevation={3} sx={{ p: 4 }}>
            <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth" sx={{ mb: 4 }}>
              <Tab icon={<CreditCardIcon />} label="Credit Card" />
              <Tab icon={<AccountBalanceIcon />} label="Bank Transfer" />
              <Tab icon={<AttachMoneyIcon />} label="Cash" />
            </Tabs>

            {success ? (
              <Box sx={{ textAlign: 'center', py: 4 }}>
                <CheckCircleOutlineIcon sx={{ fontSize: 60, color: 'success.main', mb: 2 }} />
                <Typography variant="h5" gutterBottom>
                  Payment Successful!
                </Typography>
                <Typography variant="body1">
                  Your payment of ${parseFloat(amount).toFixed(2)} has been processed.
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  New balance: ${reservation.balance}
                </Typography>
                <Button variant="contained" onClick={() => setSuccess(false)} sx={{ mt: 3 }}>
                  Make Another Payment
                </Button>
              </Box>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    {tabValue === 0 && 'Credit Card Details'}
                    {tabValue === 1 && 'Bank Transfer Information'}
                    {tabValue === 2 && 'Cash Payment'}
                  </Typography>

                  {tabValue === 0 && (
                    <Box>
                      <TextField
                        fullWidth
                        label="Card Number"
                        value={cardNumber}
                        onChange={e => setCardNumber(e.target.value)}
                        margin="normal"
                        placeholder="1234 5678 9012 3456"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CreditCardIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label="Expiry Date"
                            value={expiryDate}
                            onChange={e => setExpiryDate(e.target.value)}
                            margin="normal"
                            placeholder="MM/YY"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label="CVV"
                            value={cvv}
                            onChange={e => setCvv(e.target.value)}
                            margin="normal"
                            placeholder="123"
                          />
                        </Grid>
                      </Grid>
                      <TextField
                        fullWidth
                        label="Name on Card"
                        value={nameOnCard}
                        onChange={e => setNameOnCard(e.target.value)}
                        margin="normal"
                      />
                    </Box>
                  )}

                  {tabValue === 1 && (
                    <Box>
                      <Alert severity="info" sx={{ mb: 3 }}>
                        Please use the following information to make a bank transfer:
                      </Alert>
                      <Typography variant="body1" gutterBottom>
                        <strong>Bank Name:</strong> First National Bank
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <strong>Account Name:</strong> West Bethel Motel
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <strong>Account Number:</strong> 1234567890
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <strong>Routing Number:</strong> 987654321
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <strong>Reference:</strong> Room {reservation.roomNumber} -{' '}
                        {reservation.name}
                      </Typography>
                    </Box>
                  )}

                  {tabValue === 2 && (
                    <Box>
                      <Alert severity="info" sx={{ mb: 3 }}>
                        Cash payments can be made at the front desk. Please visit us during
                        operating hours.
                      </Alert>
                      <Typography variant="body1" gutterBottom>
                        <strong>Front Desk Hours:</strong> 24/7
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <strong>Location:</strong> Main Lobby
                      </Typography>
                      <Typography variant="body1" paragraph>
                        Please have your room number ready when making a payment.
                      </Typography>
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    Payment Amount
                  </Typography>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="payment-amount">Amount</InputLabel>
                    <OutlinedInput
                      id="payment-amount"
                      value={amount}
                      onChange={e => setAmount(e.target.value)}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      label="Amount"
                      type="number"
                    />
                    <FormHelperText>
                      {reservation.balance > 0
                        ? `Current balance: $${parseFloat(reservation.balance).toFixed(2)}`
                        : 'Your account has no outstanding balance'}
                    </FormHelperText>
                  </FormControl>

                  <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    onClick={handlePayment}
                    disabled={loading || tabValue === 2} // Disable for cash
                    sx={{ mt: 3 }}
                  >
                    {loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <>
                        {tabValue === 0 && 'Process Card Payment'}
                        {tabValue === 1 && 'Confirm Bank Transfer'}
                        {tabValue === 2 && 'Pay at Front Desk'}
                      </>
                    )}
                  </Button>

                  {error && (
                    <Alert severity="error" sx={{ mt: 3 }}>
                      {error}
                    </Alert>
                  )}
                </Grid>
              </Grid>
            )}
          </Paper>
        </Box>
      )}
    </Box>
  );
};

export default Payments;
