import React from 'react';
import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Header from './Header.jsx';
import Footer from '../components/Footer.jsx';

const MainLayout = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}
  >
    <Header />
    <Box
      component="main"
      sx={{
        flex: 1,
        bgcolor: 'background.default',
        pt: 0, // Remove padding since Header is sticky
      }}
    >
      <Container maxWidth="lg" disableGutters>
        <Outlet />
      </Container>
    </Box>

    <Footer />
  </Box>
);

export default MainLayout;
