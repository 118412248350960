import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Divider,
  Alert,
  Stack,
  Rating,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import HotelIcon from '@mui/icons-material/Hotel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EmailIcon from '@mui/icons-material/Email';
import serviceRegistry from '../../../services/serviceRegistry.js';

const CheckOut = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [roomNumber, setRoomNumber] = useState('');
  const [lastName, setLastName] = useState('');
  const [reservation, setReservation] = useState(null);
  const [error, setError] = useState('');
  const [rating, setRating] = useState(5);
  const [receiptOption, setReceiptOption] = useState('email');
  const [feedback, setFeedback] = useState('');
  const [receiptEmail, setReceiptEmail] = useState('');

  const steps = ['Find Reservation', 'Review Charges', 'Feedback', 'Complete Checkout'];

  const handleSearch = async () => {
    setError('');
    if (!roomNumber || !lastName) {
      setError('Please enter both room number and last name');
      return;
    }

    try {
      // Get the PMS service from the registry
      const pmsService = serviceRegistry.get('pms');
      const result = await pmsService.findReservationByRoomAndName(roomNumber, lastName);

      if (result) {
        setReservation(result);
        setReceiptEmail(result.email || '');
        setActiveStep(1);
      } else {
        setError('No reservation found with the provided information');
      }
    } catch (err) {
      setError('Error retrieving reservation: ' + err.message);
      console.error(err);
    }
  };

  const handleNext = () => {
    setActiveStep(prevStep => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevStep => prevStep - 1);
  };

  const handleComplete = async () => {
    try {
      // Get the PMS service from the registry
      const pmsService = serviceRegistry.get('pms');

      // Complete check-out
      await pmsService.checkOutGuest(reservation.id, {
        feedback: {
          rating,
          comments: feedback,
        },
        receiptOption,
        receiptEmail: receiptOption === 'email' ? receiptEmail : null,
      });

      setActiveStep(3); // Move to completion step
    } catch (err) {
      setError('Error during checkout: ' + err.message);
    }
  };

  return (
    <Box>
      <Typography variant="h2" gutterBottom>
        Express Check-Out
      </Typography>
      <Typography variant="body1" paragraph>
        Complete your check-out quickly and easily with our self-service system.
      </Typography>

      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        {activeStep === 0 && (
          <Box>
            <Typography variant="h5" gutterBottom>
              Find Your Reservation
            </Typography>
            <Typography variant="body1" paragraph>
              Please enter your room number and last name to begin the check-out process.
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Room Number"
                  variant="outlined"
                  value={roomNumber}
                  onChange={e => setRoomNumber(e.target.value)}
                  placeholder="e.g., 205"
                  InputProps={{
                    startAdornment: <HotelIcon sx={{ color: 'action.active', mr: 1 }} />,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Last Name"
                  variant="outlined"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  placeholder="e.g., Smith"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleSearch}
                  disabled={!roomNumber || !lastName}
                  sx={{ mt: 2 }}
                >
                  Find Reservation
                </Button>
              </Grid>
            </Grid>

            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
          </Box>
        )}

        {activeStep === 1 && reservation && (
          <Box>
            <Typography variant="h5" gutterBottom>
              Review Your Charges
            </Typography>
            <Typography variant="body1" paragraph>
              Please review all charges from your stay before completing check-out.
            </Typography>

            <Paper variant="outlined" sx={{ p: 3, mb: 3, bgcolor: '#f9f9f9' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Stack spacing={2}>
                    <Box>
                      <Typography variant="subtitle2" color="text.secondary">
                        Room
                      </Typography>
                      <Typography variant="body1">#{reservation.roomNumber}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2" color="text.secondary">
                        Guest Name
                      </Typography>
                      <Typography variant="body1">{reservation.name}</Typography>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack spacing={2}>
                    <Box>
                      <Typography variant="subtitle2" color="text.secondary">
                        Check-in
                      </Typography>
                      <Typography variant="body1">
                        {new Date(reservation.checkIn).toLocaleDateString()}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2" color="text.secondary">
                        Check-out
                      </Typography>
                      <Typography variant="body1">
                        {new Date(reservation.checkOut).toLocaleDateString()}
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Paper>

            <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
              Itemized Charges
            </Typography>
            {reservation.charges.map((charge, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  py: 1,
                  borderBottom:
                    index < reservation.charges.length - 1 ? '1px solid #e0e0e0' : 'none',
                }}
              >
                <Typography variant="body1">{charge.description}</Typography>
                <Typography variant="body1">${charge.amount.toFixed(2)}</Typography>
              </Box>
            ))}

            <Divider sx={{ my: 2 }} />

            <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
              <Typography variant="body1">Subtotal</Typography>
              <Typography variant="body1">${reservation.subtotal.toFixed(2)}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
              <Typography variant="body1">Tax</Typography>
              <Typography variant="body1">${reservation.tax.toFixed(2)}</Typography>
            </Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', py: 1, fontWeight: 'bold' }}
            >
              <Typography variant="body1" fontWeight="bold">
                Total
              </Typography>
              <Typography variant="body1" fontWeight="bold">
                ${reservation.total.toFixed(2)}
              </Typography>
            </Box>

            <Box sx={{ mt: 3, p: 2, bgcolor: '#e3f2fd', borderRadius: 1 }}>
              <Typography variant="body1">
                <CreditCardIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                Charged to: {reservation.paymentMethod}
              </Typography>
            </Box>

            <Divider sx={{ my: 3 }} />

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={handleBack}>Back</Button>
              <Button variant="contained" onClick={handleNext}>
                Continue
              </Button>
            </Box>
          </Box>
        )}

        {activeStep === 2 && (
          <Box>
            <Typography variant="h5" gutterBottom>
              Share Your Experience
            </Typography>
            <Typography variant="body1" paragraph>
              We would love to hear about your stay! Your feedback helps us improve our service.
            </Typography>

            <Box sx={{ my: 3 }}>
              <Typography variant="h6" gutterBottom>
                How would you rate your overall stay?
              </Typography>
              <Rating
                value={rating}
                onChange={(event, newValue) => {
                  setRating(newValue);
                }}
                size="large"
                sx={{ mb: 2 }}
              />
            </Box>

            <Box sx={{ my: 3 }}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Additional Comments (Optional)"
                variant="outlined"
                value={feedback}
                onChange={e => setFeedback(e.target.value)}
                placeholder="Tell us about your experience..."
              />
            </Box>

            <Box sx={{ my: 3 }}>
              <Typography variant="h6" gutterBottom>
                Receipt Options
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup value={receiptOption} onChange={e => setReceiptOption(e.target.value)}>
                  <FormControlLabel value="email" control={<Radio />} label="Email receipt" />
                  {receiptOption === 'email' && (
                    <TextField
                      fullWidth
                      label="Email Address"
                      variant="outlined"
                      value={receiptEmail}
                      onChange={e => setReceiptEmail(e.target.value)}
                      placeholder="your@email.com"
                      InputProps={{
                        startAdornment: <EmailIcon sx={{ color: 'action.active', mr: 1 }} />,
                      }}
                      sx={{ ml: 4, mb: 2, width: 'calc(100% - 32px)' }}
                    />
                  )}
                  <FormControlLabel value="print" control={<Radio />} label="Print receipt" />
                  <FormControlLabel value="none" control={<Radio />} label="No receipt" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Divider sx={{ my: 3 }} />

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={handleBack}>Back</Button>
              <Button variant="contained" color="primary" onClick={handleComplete}>
                Complete Check-Out
              </Button>
            </Box>
          </Box>
        )}

        {activeStep === 3 && (
          <Box sx={{ textAlign: 'center' }}>
            <CheckCircleIcon sx={{ fontSize: 80, color: 'success.main', mb: 2 }} />
            <Typography variant="h4" gutterBottom>
              Check-Out Successful!
            </Typography>
            <Typography variant="body1" paragraph>
              Thank you for staying at West Bethel Motel. We hope to see you again soon!
            </Typography>

            {receiptOption === 'email' && (
              <Alert severity="success" sx={{ mb: 3 }}>
                A receipt has been sent to {receiptEmail}
              </Alert>
            )}

            {receiptOption === 'print' && (
              <Alert severity="info" sx={{ mb: 3 }}>
                Your receipt is now printing. Please collect it from the printer below.
              </Alert>
            )}

            <Button variant="contained" color="primary" size="large" href="/" sx={{ mt: 2 }}>
              Return to Home
            </Button>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default CheckOut;
