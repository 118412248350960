import axios from 'axios';

/**
 * Base API service for handling API requests
 */
class ApiService {
  /**
   * Create a new ApiService with dependencies
   * @param {Object} loggingService - The logging service
   * @param {Object} databaseService - The database service
   */
  constructor(loggingService, databaseService) {
    this.loggingService = loggingService;
    this.databaseService = databaseService;
    this.baseURL = process.env.REACT_APP_API_URL || '/api';
    this.client = axios.create({
      baseURL: this.baseURL,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    this.initialized = false;

    console.log('ApiService constructed');
  }

  /**
   * Initialize the API service
   */
  async initialize() {
    this.loggingService.logInfo('ApiService', 'Initializing API service');

    // Set up request interceptor for authentication
    this.client.interceptors.request.use(
      config => {
        // Get token from local storage if available
        const token = localStorage.getItem('auth_token');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      error => {
        this.loggingService.logError('ApiService', 'Request interceptor error', error);
        return Promise.reject(error);
      }
    );

    // Set up response interceptor for error handling
    this.client.interceptors.response.use(
      response => response,
      error => {
        // Log errors but don't handle them here - let calling code handle them
        this.loggingService.logError('ApiService', 'API request failed', {
          message: error.message || 'Unknown error',
          url: error.config?.url,
          method: error.config?.method,
          status: error.response?.status,
        });

        // Format error response consistently
        const formattedError = {
          status: error.response?.status || 500,
          message: error.response?.data?.message || error.message || 'Unknown error',
          data: error.response?.data || null,
        };

        return Promise.reject(formattedError);
      }
    );

    this.initialized = true;
    this.loggingService.logInfo('ApiService', 'API service initialized successfully');
    return true;
  }

  /**
   * Send a GET request
   * @param {string} endpoint - API endpoint
   * @param {Object} params - Request query parameters
   * @param {Object} options - Additional options
   * @returns {Promise<Object>} - API response
   */
  async get(endpoint, params = {}, options = {}) {
    try {
      const response = await this.client.get(endpoint, { params, ...options });
      return response.data;
    } catch (error) {
      throw error; // Let the caller handle the error
    }
  }

  /**
   * Send a POST request
   * @param {string} endpoint - API endpoint
   * @param {Object} data - Request body
   * @param {Object} options - Additional options
   * @returns {Promise<Object>} - API response
   */
  async post(endpoint, data = {}, options = {}) {
    try {
      const response = await this.client.post(endpoint, data, options);
      return response.data;
    } catch (error) {
      throw error; // Let the caller handle the error
    }
  }

  /**
   * Send a PUT request
   * @param {string} endpoint - API endpoint
   * @param {Object} data - Request body
   * @param {Object} options - Additional options
   * @returns {Promise<Object>} - API response
   */
  async put(endpoint, data = {}, options = {}) {
    try {
      const response = await this.client.put(endpoint, data, options);
      return response.data;
    } catch (error) {
      throw error; // Let the caller handle the error
    }
  }

  /**
   * Send a PATCH request
   * @param {string} endpoint - API endpoint
   * @param {Object} data - Request body
   * @param {Object} options - Additional options
   * @returns {Promise<Object>} - API response
   */
  async patch(endpoint, data = {}, options = {}) {
    try {
      const response = await this.client.patch(endpoint, data, options);
      return response.data;
    } catch (error) {
      throw error; // Let the caller handle the error
    }
  }

  /**
   * Send a DELETE request
   * @param {string} endpoint - API endpoint
   * @param {Object} options - Additional options
   * @returns {Promise<Object>} - API response
   */
  async delete(endpoint, options = {}) {
    try {
      const response = await this.client.delete(endpoint, options);
      return response.data;
    } catch (error) {
      throw error; // Let the caller handle the error
    }
  }
}

// Export the class directly
export default ApiService;
