/**
 * RoomAvailabilityService
 * Service for checking room availability
 *
 * This is an adapter file that follows the new service architecture
 * It imports the legacy RoomAvailabilityService from the booking subdirectory
 * and adapts it to work with the new dependency injection pattern
 */

import LegacyRoomAvailabilityService from './booking/RoomAvailabilityService.js';
import { collection, getDocs, query, where, Timestamp } from 'firebase/firestore';

/**
 * Enhanced RoomAvailabilityService with proper dependency injection
 */
class RoomAvailabilityService {
  /**
   * Create a new RoomAvailabilityService with dependencies
   * @param {Object} loggingService - Logging service instance
   * @param {Object} databaseService - Database service instance
   * @param {Object} apiService - API service instance
   */
  constructor(loggingService, databaseService, apiService) {
    this.loggingService = loggingService;
    this.databaseService = databaseService;
    this.apiService = apiService;
    this.legacyService = LegacyRoomAvailabilityService;
    this.initialized = false;
    this.isUsingEmulator = process.env.REACT_APP_USE_FIREBASE_EMULATOR === 'true';

    console.log('RoomAvailabilityService adapter constructed');
  }

  /**
   * Initialize the room availability service
   * @returns {Promise<RoomAvailabilityService>} The initialized service instance
   */
  async initialize() {
    try {
      this.loggingService.debug('Initializing RoomAvailabilityService...');
      
      // Initialize the database service first
      try {
        await this.databaseService.initialize();
      } catch (error) {
        // In development with emulators, we can continue even if DB init fails
        if (this.isUsingEmulator && process.env.NODE_ENV === 'development') {
          this.loggingService.warn('Continuing with emulator despite database initialization error');
        } else {
          throw error;
        }
      }
      
      // Mark as initialized even if we can't fully test it in development
      this.initialized = true;
      
      // Only test data access in production or when not using emulators
      if (!this.isUsingEmulator && process.env.NODE_ENV === 'production') {
        this.loggingService.debug('Testing room availability data access...');
        const db = this.databaseService.getDb();
        // Make a simple query to verify permissions
        const roomsRef = collection(db, 'rooms');
        await getDocs(roomsRef);
      }
      
      this.loggingService.info('RoomAvailabilityService initialized successfully');
      return this;
    } catch (error) {
      this.loggingService.error('Failed to initialize room availability service', error);
      // In development with emulators, we'll allow continuing with errors
      if (this.isUsingEmulator && process.env.NODE_ENV === 'development') {
        this.initialized = true;
        return this;
      } else {
        this.initialized = false;
        throw new Error(`Failed to initialize room availability service: ${error.message}`);
      }
    }
  }

  /**
   * Check if a room is available for the specified dates
   * @param {string} roomId - Room ID
   * @param {Date} checkInDate - Check-in date
   * @param {Date} checkOutDate - Check-out date
   * @param {string} excludeBookingId - Optional booking ID to exclude
   * @returns {Promise<boolean>} Whether the room is available
   */
  async checkAvailability(roomId, checkInDate, checkOutDate, excludeBookingId = null) {
    if (!this.initialized && !this.isUsingEmulator) {
      throw new Error('RoomAvailabilityService not initialized');
    }
    
    try {
      const db = this.databaseService.getDb();
      
      // In emulator mode with development, return mock data
      if (this.isUsingEmulator && process.env.NODE_ENV === 'development') {
        this.loggingService.debug('Using mock data for room availability in emulator mode');
        return Promise.resolve(true);
      }
      
      // Implementation for production
      const bookingsRef = collection(db, 'bookings');
      const startTimestamp = Timestamp.fromDate(checkInDate);
      const endTimestamp = Timestamp.fromDate(checkOutDate);
      
      // Find bookings that overlap with the requested date range
      const q = query(
        bookingsRef,
        where('checkInDate', '<=', endTimestamp),
        where('checkOutDate', '>=', startTimestamp)
      );
      
      const querySnapshot = await getDocs(q);
      const bookedRoomCount = querySnapshot.size;
      
      // Calculate total room count (in real app, get from database)
      const totalRoomCount = 10; // Example value
      
      // Check if enough rooms are available
      const available = totalRoomCount - bookedRoomCount >= 1;
      return available;
    } catch (error) {
      this.loggingService.error('Error checking room availability', error);
      // In development with emulators, return true despite errors
      if (this.isUsingEmulator && process.env.NODE_ENV === 'development') {
        return true;
      }
      throw error;
    }
  }

  /**
   * Get available rooms for the specified dates
   * @param {Date} checkInDate - Check-in date
   * @param {Date} checkOutDate - Check-out date
   * @param {Object} filters - Optional filters
   * @returns {Promise<Array>} List of available rooms
   */
  async getAvailableRooms(checkInDate, checkOutDate, filters = {}) {
    return this.legacyService.getAvailableRooms(checkInDate, checkOutDate, filters);
  }
}

// Export the class directly for the new architecture
export default RoomAvailabilityService;
