import { functions } from '../config/firebase';
import { httpsCallable } from 'firebase/functions';
import { serviceRegistry } from './serviceRegistry';

/**
 * Service wrapper for Firebase Cloud Functions
 */
class FunctionsService {
  /**
   * Create a new FunctionsService with dependencies
   * @param {Object} loggingService - Logging service instance
   */
  constructor(loggingService) {
    this.functions = functions;
    this.loggingService = loggingService;
    this.initialized = false;
  }

  /**
   * Initialize the service
   */
  initialize() {
    this.initialized = true;
    if (this.loggingService) {
      this.loggingService.logInfo('FunctionsService', 'FunctionsService initialized');
    } else {
      console.log('FunctionsService initialized');
    }
    return this;
  }

  /**
   * Call a Cloud Function
   * @param {string} functionName - Name of the function to call
   * @returns {Function} - Function that can be called with arguments
   */
  httpsCallable(functionName) {
    return httpsCallable(this.functions, functionName);
  }
}

// Export the class directly for the service factory to instantiate
export default FunctionsService;
