/**
 * Error Handler Utility
 * Provides centralized error handling functionality
 */

class ErrorHandler {
  /**
   * Handle Firebase errors and return standardized error objects
   * @param {Error} error - Original error
   * @returns {Error} Standardized error
   */
  static handleFirebaseError(error) {
    // Firebase error codes documentation: https://firebase.google.com/docs/reference/js/firebase.auth.Error
    if (!error) {
      return new Error('Unknown error occurred');
    }

    console.error('Firebase error:', error);

    // Return a standardized error object
    switch (error.code) {
      case 'permission-denied':
        return new Error('You do not have permission to perform this action');
      case 'not-found':
        return new Error('The requested resource was not found');
      case 'already-exists':
        return new Error('This resource already exists');
      case 'invalid-argument':
        return new Error('Invalid data provided');
      case 'resource-exhausted':
        return new Error('Too many requests, please try again later');
      case 'unauthenticated':
        return new Error('You must be logged in to perform this action');
      default:
        return error;
    }
  }

  /**
   * Log an error and return a user-friendly message
   * @param {Error} error - Original error
   * @param {string} context - Context where the error occurred
   * @returns {string} User-friendly error message
   */
  static logAndGetMessage(error, context = '') {
    const contextPrefix = context ? `[${context}] ` : '';
    console.error(`${contextPrefix}Error:`, error);

    return this.getUserFriendlyMessage(error);
  }

  /**
   * Get a user-friendly error message
   * @param {Error} error - Original error
   * @returns {string} User-friendly error message
   */
  static getUserFriendlyMessage(error) {
    if (!error) {
      return 'An unknown error occurred. Please try again.';
    }

    if (error.message) {
      return error.message;
    }

    return 'An error occurred. Please try again.';
  }
}

export default ErrorHandler;
