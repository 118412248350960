/**
 * @class ServiceLocator
 * @description Service locator pattern implementation for managing application services.
 * This pattern provides a central registry of services with lazy initialization.
 *
 * IMPORTANT: Do not modify the core service registration logic without explicit review.
 *
 * @version 1.1.0
 * @author WebEmo Team
 * @since 2024-01-15
 * @lastModified 2024-06-01
 */
class ServiceLocator {
  /**
   * Creates a new ServiceLocator instance
   */
  constructor() {
    this.services = {};
    this.factories = {};
    this.aliases = {}; // Add service name aliases
  }

  /**
   * Registers a service factory function
   *
   * @param {string} name - Name of the service
   * @param {Function} factory - Factory function to create the service
   * @returns {ServiceLocator} The service locator instance for chaining
   */
  register(name, factory) {
    this.factories[name] = factory;
    return this;
  }

  /**
   * Registers an alias for a service name
   *
   * @param {string} alias - Alias name
   * @param {string} serviceName - Original service name
   * @returns {ServiceLocator} The service locator instance for chaining
   */
  registerAlias(alias, serviceName) {
    this.aliases[alias] = serviceName;
    return this;
  }

  /**
   * Gets a service instance, creating it if necessary
   *
   * @param {string} name - Name of the service to get
   * @returns {Object} The service instance
   * @throws {Error} If the service is not registered
   */
  getService(name) {
    // Check if this is an alias
    const serviceName = this.aliases[name] || name;

    if (!this.factories[serviceName]) {
      console.warn(`Service '${serviceName}' is not registered`);
      return null;
    }

    if (!this.services[serviceName]) {
      this.services[serviceName] = this.factories[serviceName]();
    }

    return this.services[serviceName];
  }

  /**
   * Checks if a service is registered
   *
   * @param {string} name - Name of the service to check
   * @returns {boolean} Whether the service is registered
   */
  hasService(name) {
    // Check if this is an alias
    const serviceName = this.aliases[name] || name;
    return !!this.factories[serviceName];
  }

  /**
   * Removes a service from the registry
   *
   * @param {string} name - Name of the service to remove
   * @returns {boolean} Whether the service was removed
   */
  removeService(name) {
    // Get actual service name if alias
    const serviceName = this.aliases[name] || name;

    // Remove the alias if it exists
    if (this.aliases[name]) {
      delete this.aliases[name];
    }

    if (this.services[serviceName]) {
      delete this.services[serviceName];
    }

    if (this.factories[serviceName]) {
      delete this.factories[serviceName];
      return true;
    }

    return false;
  }
}

// Create singleton instance
const serviceLocator = new ServiceLocator();

// Use dynamic imports to avoid circular dependencies
/**
 * @preserveFeature
 * Register core application services
 * NOTE: These service registrations must be maintained for application functionality.
 * Do not remove or modify without explicit approval.
 */

// Register booking service
serviceLocator.register('booking', () => {
  const BookingService = require('./bookingService').default;
  return new BookingService();
});

// Register notification service
serviceLocator.register('notification', () => {
  const NotificationService = require('./NotificationService').default;
  return new NotificationService();
});

// Register payment service
serviceLocator.register('payment', () => {
  const PaymentService = require('./paymentService').default;
  return new PaymentService();
});

// Register email service
serviceLocator.register('email', () => {
  const EmailService = require('./emailService').default;
  return new EmailService();
});

/**
 * @preserveFeature
 * Register authentication and community services
 * These services are critical for user registration, login, and community features.
 */
serviceLocator.register('auth', () => {
  const AuthService = require('./AuthService').default;
  return new AuthService();
});

// Register aliases for services
serviceLocator.registerAlias('authService', 'auth');
serviceLocator.registerAlias('bookingService', 'booking');
serviceLocator.registerAlias('notificationService', 'notification');
serviceLocator.registerAlias('paymentService', 'payment');
serviceLocator.registerAlias('emailService', 'email');

export default serviceLocator;
