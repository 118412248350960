import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  where,
} from 'firebase/firestore';
import { db } from '../../config';
import { serviceRegistry } from '../serviceRegistry';
import DatabaseService from '../core/DatabaseService';
import LoggingService from '../core/LoggingService';

/**
 * RoomTypeService
 * Service for room type management
 */
class RoomTypeService {
  /**
   * Create a new RoomTypeService with dependencies
   * @param {Object} loggingService - Logging service instance
   * @param {Object} databaseService - Database service instance
   */
  constructor(loggingService, databaseService) {
    this.loggingService = loggingService;
    this.databaseService = databaseService;
    
    this.collectionName = 'roomTypes';
    this.initialized = false;
    this.initializationPromise = null;

    // Default room types if none exist in the database
    this.defaultRoomTypes = [
      {
        id: 'adventure',
        name: 'Adventure Room',
        description: 'Perfect for outdoor enthusiasts with two double beds',
        basePrice: 119.99,
        occupancy: { standard: 2, maximum: 4 },
        beds: { double: 2 },
        amenities: ['wifi', 'tv', 'coffee', 'fridge'],
        photos: [],
      },
      {
        id: 'getaway',
        name: 'Getaway Room',
        description: 'Mountain escape with a king bed and extra space',
        basePrice: 149.99,
        occupancy: { standard: 2, maximum: 2 },
        beds: { king: 1 },
        amenities: ['wifi', 'tv', 'coffee', 'fridge', 'desk'],
        photos: [],
      },
      {
        id: 'postup',
        name: 'PostUp Room',
        description: 'Spacious accommodation with multiple beds for groups',
        basePrice: 179.99,
        occupancy: { standard: 4, maximum: 6 },
        beds: { queen: 2, twin: 1 },
        amenities: ['wifi', 'tv', 'coffee', 'fridge', 'microwave'],
        photos: [],
      },
    ];
  }

  /**
   * Initialize the service
   */
  async initialize() {
    if (this.initialized) {
      return this;
    }

    if (this.initializationPromise) {
      return this.initializationPromise;
    }

    this.initializationPromise = (async () => {
      try {
        if (this.databaseService) {
          await this.databaseService.initialize();
        }
        this.initialized = true;
        this.loggingService.logInfo('RoomTypeService', 'RoomTypeService initialized successfully');
        return this;
      } catch (error) {
        this.loggingService.logError('RoomTypeService', 'Failed to initialize RoomTypeService', error);
        this.initialized = false;
        throw new Error(`Failed to initialize room type service: ${error.message}`);
      } finally {
        this.initializationPromise = null;
      }
    })();

    return this.initializationPromise;
  }

  /**
   * Make sure default room types exist
   * @returns {Promise<void>}
   */
  async ensureDefaultRoomTypes() {
    try {
      const existingTypes = await this.getAllRoomTypes();

      if (existingTypes.length === 0) {
        console.log('No room types found, creating defaults');

        // Create default room types
        for (const roomType of this.defaultRoomTypes) {
          await this.createRoomType(roomType);
        }
      }
    } catch (error) {
      // Check if it's a permission error
      if (error.message && error.message.includes('permission')) {
        console.warn(
          'Insufficient permissions to access room types. This may be normal if not authenticated yet.'
        );
      } else {
        console.error('Error ensuring default room types:', error);
      }
      // Don't throw the error further, just log it
    }
  }

  /**
   * Get all room types
   * @returns {Promise<Array>} Array of room types
   */
  async getAllRoomTypes() {
    if (!this.initialized) {
      throw new Error('RoomTypeService not initialized');
    }

    try {
      const db = this.databaseService ? this.databaseService.getDb() : null;
      if (!db) {
        throw new Error('Database service not available');
      }
      
      const roomTypesRef = collection(db, this.collectionName);
      const querySnapshot = await getDocs(roomTypesRef);

      const roomTypes = [];
      querySnapshot.forEach(doc => {
        roomTypes.push({ id: doc.id, ...doc.data() });
      });

      this.loggingService.logDebug('RoomTypeService', `Retrieved ${roomTypes.length} room types`);
      return roomTypes;
    } catch (error) {
      this.loggingService.logError('RoomTypeService', 'Error getting room types', error);
      throw error;
    }
  }

  /**
   * Get a specific room type by ID
   * @param {string} roomTypeId - Room type ID
   * @returns {Promise<Object>} Room type data
   */
  async getRoomType(roomTypeId) {
    if (!this.initialized) {
      throw new Error('RoomTypeService not initialized');
    }

    try {
      const db = this.databaseService ? this.databaseService.getDb() : null;
      if (!db) {
        throw new Error('Database service not available');
      }
      
      const roomTypeRef = doc(db, this.collectionName, roomTypeId);
      const roomTypeDoc = await getDoc(roomTypeRef);

      if (!roomTypeDoc.exists()) {
        throw new Error(`Room type ${roomTypeId} not found`);
      }

      return { id: roomTypeDoc.id, ...roomTypeDoc.data() };
    } catch (error) {
      this.loggingService.logError('RoomTypeService', `Error getting room type ${roomTypeId}`, error);
      throw error;
    }
  }

  /**
   * Get room types by category
   * @param {string} category - Room category
   * @returns {Promise<Array>} Array of room types in the category
   */
  async getRoomTypesByCategory(category) {
    if (!this.initialized) {
      throw new Error('RoomTypeService not initialized');
    }

    try {
      const db = this.databaseService ? this.databaseService.getDb() : null;
      if (!db) {
        throw new Error('Database service not available');
      }
      
      const roomTypesRef = collection(db, this.collectionName);
      const q = query(roomTypesRef, where('category', '==', category));
      const querySnapshot = await getDocs(q);

      const roomTypes = [];
      querySnapshot.forEach(doc => {
        roomTypes.push({ id: doc.id, ...doc.data() });
      });

      this.loggingService.logDebug('RoomTypeService', `Retrieved ${roomTypes.length} room types in category ${category}`);
      return roomTypes;
    } catch (error) {
      this.loggingService.logError('RoomTypeService', `Error getting room types for category ${category}`, error);
      throw error;
    }
  }

  /**
   * Create a new room type
   * @param {Object} typeData Room type data
   * @returns {Promise<Object>} Created room type
   */
  async createRoomType(typeData) {
    if (!typeData || Object.keys(typeData).length === 0) {
      throw new Error('Room type data is required');
    }

    try {
      const newTypeData = {
        ...typeData,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      const roomTypeRef = await addDoc(collection(db, this.collectionName), newTypeData);

      return {
        id: roomTypeRef.id,
        ...newTypeData,
      };
    } catch (error) {
      console.error('Error creating room type:', error);
      throw new Error(`Failed to create room type: ${error.message}`);
    }
  }

  /**
   * Update a room type
   * @param {string} typeId Room type ID
   * @param {Object} typeData Updated room type data
   * @returns {Promise<Object>} Updated room type
   */
  async updateRoomType(typeId, typeData) {
    if (!typeId) {
      throw new Error('Room type ID is required');
    }

    if (!typeData || Object.keys(typeData).length === 0) {
      throw new Error('Room type data is required');
    }

    try {
      const roomTypeRef = doc(db, this.collectionName, typeId);
      const roomTypeDoc = await getDoc(roomTypeRef);

      if (!roomTypeDoc.exists()) {
        throw new Error(`Room type with ID ${typeId} not found`);
      }

      const updatedData = {
        ...typeData,
        updatedAt: serverTimestamp(),
      };

      await updateDoc(roomTypeRef, updatedData);

      return {
        id: typeId,
        ...roomTypeDoc.data(),
        ...typeData,
      };
    } catch (error) {
      console.error(`Error updating room type ${typeId}:`, error);
      throw new Error(`Failed to update room type: ${error.message}`);
    }
  }

  /**
   * Delete a room type
   * @param {string} typeId Room type ID
   * @returns {Promise<void>}
   */
  async deleteRoomType(typeId) {
    if (!typeId) {
      throw new Error('Room type ID is required');
    }

    try {
      await deleteDoc(doc(db, this.collectionName, typeId));
    } catch (error) {
      console.error(`Error deleting room type ${typeId}:`, error);
      throw new Error(`Failed to delete room type: ${error.message}`);
    }
  }

  async getRoomTypes(filters = {}) {
    if (!this.initialized) {
      throw new Error('RoomTypeService not initialized');
    }

    try {
      const db = this.databaseService ? this.databaseService.getDb() : null;
      if (!db) {
        throw new Error('Database service not available');
      }
      
      let roomTypesQuery = collection(db, this.collectionName);

      // Apply filters if provided
      if (filters.maxOccupancy) {
        roomTypesQuery = query(roomTypesQuery, where('maxOccupancy', '>=', filters.maxOccupancy));
      }

      if (filters.amenities) {
        roomTypesQuery = query(
          roomTypesQuery,
          where('amenities', 'array-contains-any', filters.amenities)
        );
      }

      const snapshot = await getDocs(roomTypesQuery);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      this.loggingService.logError('RoomTypeService', 'Error getting room types', error);
      throw error;
    }
  }

  async getRoomTypeAvailability(roomTypeId, checkInDate, checkOutDate) {
    if (!this.initialized) {
      throw new Error('RoomTypeService not initialized');
    }

    try {
      const db = this.databaseService ? this.databaseService.getDb() : null;
      if (!db) {
        throw new Error('Database service not available');
      }
      
      const roomsRef = collection(db, 'rooms');
      const roomsQuery = query(roomsRef, where('roomTypeId', '==', roomTypeId));
      const snapshot = await getDocs(roomsQuery);
      const rooms = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Check availability for each room of this type
      const availableRooms = await Promise.all(
        rooms.map(async room => {
          const isAvailable = await this.checkRoomAvailability(room.id, checkInDate, checkOutDate);
          return isAvailable ? room : null;
        })
      );

      return availableRooms.filter(room => room !== null);
    } catch (error) {
      this.loggingService.logError('RoomTypeService', `Error getting availability for room type ${roomTypeId}`, error);
      throw error;
    }
  }
}

// Export the class directly for the service factory to instantiate
export default RoomTypeService;
