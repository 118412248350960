import React from 'react';
import { Alert, Snackbar, Typography  } from '@mui/material';

const ErrorNotification = ({ message, severity = "error", onClose, duration = 6000 }) => {
  return (
    <Snackbar
      open={true}
      autoHideDuration={duration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert 
        onClose={onClose} 
        severity={severity} 
        variant="filled" 
        elevation={6}
        sx={{ width: '100%' }}
      >
        <Typography variant="body2">{message}</Typography>
      </Alert>
    </Snackbar>
  );
};

export default ErrorNotification;
