import axios from 'axios';
import { format } from 'date-fns';

/**
 * Email service for sending emails via SendGrid
 */
class EmailService {
  constructor() {
    this.apiBaseUrl = process.env.REACT_APP_API_BASE_URL || '';
    this.endpoints = {
      sendBookingConfirmation: '/api/email/booking-confirmation',
      sendModificationRequest: '/api/email/modification-request',
    };
  }

  /**
   * Sends a booking confirmation email to the customer
   * @param {Object} params - Email parameters
   * @param {string} params.to - Recipient email address
   * @param {Object} params.bookingDetails - Booking details
   * @param {Object} params.priceBreakdown - Price breakdown
   * @returns {Promise} - Response from the API
   */
  async sendBookingConfirmation({ to, bookingDetails, priceBreakdown }) {
    try {
      const checkInDate = format(new Date(bookingDetails.date), 'MMMM dd, yyyy');
      const checkOutDate = format(new Date(bookingDetails.checkoutDate), 'MMMM dd, yyyy');
      const checkInTime = bookingDetails.time;

      const response = await axios.post(
        `${this.apiBaseUrl}${this.endpoints.sendBookingConfirmation}`,
        {
          to,
          templateData: {
            customerName: bookingDetails.customer.name,
            bookingId: bookingDetails.id,
            serviceName: bookingDetails.service.name,
            checkInDate,
            checkInTime,
            checkOutDate,
            numberOfGuests: bookingDetails.numberOfGuests,
            totalAmount: priceBreakdown.total.toFixed(2),
            baseRate: priceBreakdown.baseRate.toFixed(2),
            earlyCheckInFee: priceBreakdown.earlyCheckInFee.toFixed(2),
            taxes: priceBreakdown.taxes.toFixed(2),
            specialRequests: bookingDetails.specialRequests || 'None',
            roomPreferences: bookingDetails.roomPreferences || 'None',
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error('Error sending booking confirmation email:', error);
      throw new Error('Failed to send confirmation email');
    }
  }

  /**
   * Sends a modification request email to the hotel staff
   * @param {Object} params - Email parameters
   * @param {string} params.bookingId - Booking ID
   * @param {string} params.customerName - Customer name
   * @param {string} params.customerEmail - Customer email
   * @param {string} params.requestType - Type of modification request
   * @param {string} params.details - Modification request details
   * @returns {Promise} - Response from the API
   */
  async sendModificationRequest({ bookingId, customerName, customerEmail, requestType, details }) {
    try {
      const requestTypes = {
        date_change: 'Date Change',
        guest_change: 'Number of Guests Change',
        room_change: 'Room Type Change',
        cancellation: 'Cancellation Request',
        other: 'Other Request',
      };

      const response = await axios.post(
        `${this.apiBaseUrl}${this.endpoints.sendModificationRequest}`,
        {
          to: process.env.REACT_APP_HOTEL_STAFF_EMAIL || 'staff@hotel.com',
          templateData: {
            bookingId,
            customerName,
            customerEmail,
            requestType: requestTypes[requestType] || requestType,
            details,
            timestamp: format(new Date(), 'MMMM dd, yyyy HH:mm'),
          },
        }
      );

      // Also send a confirmation to the customer
      await axios.post(`${this.apiBaseUrl}${this.endpoints.sendBookingConfirmation}`, {
        to: customerEmail,
        templateData: {
          customerName,
          bookingId,
          requestType: requestTypes[requestType] || requestType,
          details,
          isModificationRequest: true,
        },
      });

      return response.data;
    } catch (error) {
      console.error('Error sending modification request email:', error);
      throw new Error('Failed to send modification request email');
    }
  }
}

export default EmailService;
